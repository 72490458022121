import { zeroDecimalCurrencies } from '../utils'
import { round } from '../../../util/universal'

export const getStripePaymentRequest = (priceSummary, promotionAppliedDesc, t) => {
	const { currency, totalPrice, howManyComing, originalTotalPrice } = priceSummary

	//stripe requires cents
	const amountMultiplier = zeroDecimalCurrencies.includes(currency) ? 1 : 100

	const displayItems = [
		{
			label: t('loungeAccessFor', { count: howManyComing }),
			amount: round(originalTotalPrice * amountMultiplier)
		}
	]

	// note: need to round() here for `amount` to ensure stripe gets whole numbers
	// e.g. 18.69 * 100 = 1869.0000000000002, so we need to round(18.69 * 100) = 1869
	const discountPrice = totalPrice - originalTotalPrice
	if (discountPrice < 0) {
		displayItems.push({
			label: promotionAppliedDesc,
			amount: round(discountPrice * amountMultiplier)
		})
	}

	return {
		country: 'US',
		currency: currency.toLowerCase(),
		total: {
			label: 'LoungeBuddy',
			amount: round(totalPrice * amountMultiplier),
		},
		displayItems,
		requestPayerName: true,
		requestPayerEmail: true,
	}
}
