import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import AmexCardNumberElement from './AmexCardNumberElement'
import styled from 'styled-components'
import {BodyText} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import AmexCardIcon from '../component/LBIcons/icons/AmexCard'
import {useMediaQuery} from 'react-responsive'
import {maxWidthMobile, maxWidthSmallMobile} from '../constants'
import {StripeInputStyles} from './StripeStyles'
import PropTypes from 'prop-types'

const Wrap = styled.div`
	margin: 16px 0;
	background: #ffffff;
	border: 1px solid ${color.lightGrey};
	border-radius: 5px;
`
const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 48px;
	padding: 0 16px;
	border-bottom: 1px solid ${color.lightGrey};
`
const Content = styled.div`
	padding: 16px;
	background-color: ${color.lightGreyLighter};
`
const Info = styled.div`
	display: flex;
	align-items: center;
`
const Bullet = styled.div`
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 8px;
	background-color: ${color.blondeDark};
	box-shadow: inset 0 0 0 5px ${color.green};
	border-radius: 50%;
`

const elementStyles = {
	base: {
		color: color.espressoDark,
		fontFamily: 'Avenir Next, Avenir Next Local, sans-serif',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '24px',
		letterSpacing: '-0.1px',
		fontSmoothing: 'antialiased',

		'::placeholder': {
			color: color.espressoLight,
		},
	},
	invalid: {
		color: color.espressoDark,
	},
	complete: {
		color: color.espressoDark,
	},
}

const elementClasses = {
	focus: 'focus',
	empty: 'empty',
	invalid: 'invalid',
	complete: 'complete',
}

const CreditCardForm = ({onTracking}) => {
	const {t} = useTranslation('checkout')
	const isMobile = useMediaQuery({maxWidth: maxWidthMobile})
	const isSmallMobile = useMediaQuery({maxWidth: maxWidthSmallMobile})

	if (isSmallMobile) {
		elementStyles['base']['fontSize'] = '13px'
	}

	return (
		<Wrap id="payment">
			<Head>
				<Info>
					<Bullet />
					<BodyText>{t('americanExpressCard')}</BodyText>
				</Info>
				{isMobile ? <AmexCardIcon size={24} /> : <AmexCardIcon size={32} />}
			</Head>
			<Content>
				<StripeInputStyles />
				<AmexCardNumberElement
					onTracking={onTracking}
					classes={elementClasses}
					style={elementStyles}
				/>
			</Content>
		</Wrap>
	)
}

CreditCardForm.propTypes = {
	onTracking: PropTypes.func,
}

export default memo(CreditCardForm)
