import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M18 22a1 1 0 010 2H6a1 1 0 010-2h12zM12 1c2.506 0 5.013.13 7.52.393A5 5 0 0124 6.366v9.268a5 5 0 01-4.48 4.973 72.344 72.344 0 01-15.04 0A5 5 0 010 15.634V6.366a5 5 0 014.48-4.973A72.344 72.344 0 0112 1zM8.032 7.999l-.08.021-.285.104a.3.3 0 00-.151.443l.043.054 2.817 2.773-1.79.651-3.14-1.951a.238.238 0 00-.155-.034l-.053.012-.928.338a.238.238 0 00-.133.327l.035.053L6.1 12.95l-.855.312a.25.25 0 00-.15.32c.043.118.13.212.242.263l.087.03 1.973.466a7.619 7.619 0 004.056-.153l.301-.102 7.543-2.746a.888.888 0 00.188-1.573l-.097-.056-.027-.014a4.76 4.76 0 00-3.499-.292l-.246.083-1.662.603-5.677-2.07a.476.476 0 00-.245-.022z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
