import React from 'react'
import {useHistory} from 'react-router-dom'

import Loading from '../component/Loading'
import {addLBPersistentQueryString, openChat} from '../utils'
import {trackEvent, trackConstants} from '../trackHelper'
import {addQueryParamToUrl} from '../../util/universal'

const updateWindowDimensions = () => {
	const banner = document.getElementById('top-banner')
	const height = window.innerHeight - (banner ? banner.getBoundingClientRect().height : 0)
	const iframe = document.getElementById('iframe')
	iframe.style.maxHeight = iframe.style.height = iframe.style.minHeight = height + 'px'
}

const setupChat = iFrameResizer => {
	if (!iFrameResizer) return

	if (window.Kustomer.startFinished) {
		// kustomer.start won't run again if it's already initialized
		iFrameResizer.sendMessage('chatStatus:' + window.Kustomer.isChatAvailable().availability)
	} else {
		window.Kustomer.start({brandId: '60624fe1ee8abdeb8cb7bdd5', hideChatIcon: true}, () => {
			if (process.env.NODE_ENV === 'development') {
				console.log('[dev] Kustomer initialized, availability is: ', window.Kustomer.isChatAvailable().availability)
			}

			iFrameResizer.sendMessage('chatStatus:' + window.Kustomer.isChatAvailable().availability)
		})
	}
}

function useIframeResizer({title, location, iframeUrl, onMessage, onReady, qeId, currency = 'USD'}) {
	const history = useHistory()

	React.useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions)

		return () => {
			window.removeEventListener('resize', updateWindowDimensions)
		}
	}, [])

	React.useEffect(() => {
		// limit iframe height to window innerHeight
		// vh doesn't work on mobile with browser toolbar, but innerHeight does.
		const height = window.innerHeight

		const iframes = window.iFrameResize(
			{
				log: false,
				checkOrigin: false,
				heightCalculationMethod: 'taggedElement',
				autoResize: false,
				height,
				minHeight: height,
				maxHeight: height,
				scrolling: true,
				onResized: ({iframe}) => {
					iframe.style.opacity = 1
					document.getElementById('loading')?.style.display = 'none'
					updateWindowDimensions()

					const iFrameResizer = iframes[0]?.iFrameResizer
					if (!iFrameResizer) {
						console.error('iframeResizer not ready, but should be')
						return
					}

					setupChat(iFrameResizer)

					if (typeof onReady === 'function') {
						onReady(iFrameResizer)
					}
				},
				onMessage: async ({iframe, message}) => {
					// message with `event` is an object with other values, otherwise message is a string
					const {event, ...rest} = message
					const [action, context] = event ? event.split(':') : message.split(':')
					const params = event ? rest : null // set params to null if message is a string

					if (process.env.NODE_ENV === 'development') {
						console.log('[dev] got iframe msg', {action, params, context})
					}

					if (action === 'openModal') {
						iframe.scrollIntoView(true)
						document.body.parentElement.style.overflow = 'hidden'
					} else if (action === 'closeModal') {
						document.body.parentElement.style.overflow = ''
					} else if (action === 'url_replace') {
						// added for locale switch
						const path = addLBPersistentQueryString(location, decodeURIComponent(context))
						if (path.startsWith('http')) {
							window.location.replace(path)
						} else {
							console.error('not an absolute url')
						}
					} else if (action === 'url') {
						// context has the url
						const path = addLBPersistentQueryString(location, context)
						history.push(path, params?.state)
					} else if (action === 'url_tab') {
						const path = addLBPersistentQueryString(location, context)
						open(path)
					} else if (action === 'liveChat') {
						openChat()
					} else if (action === 'setCookiePreferences') {
						if (!window.UserConsentManagementConsentChecking) return
						window.UserConsentManagementConsentChecking.showPreferencesFunction()
					} else if (action === 'currency') {
						const code = context
						const activeCurrency = window.Cookies.get('currencyCode')

						if (code === activeCurrency) return

						window.Cookies.set('currencyCode', code)

						// track here because of async
						await trackEvent(trackConstants.EVENT.changeCurrency, {
							gtmParams: {
								category: 'Currency',
							},
							currency: code,
							previousCurrency: activeCurrency,
						})

						window.location.reload()
					} else if (action === 'track') {
						await trackEvent(context, params?.values)
					}

					if (typeof onMessage === 'function') {
						onMessage({iframe, message, action, context, params})
					}
				},
			},
			'#iframe'
		)
	}, [history, location, onMessage, onReady])

	const url = addQueryParamToUrl(iframeUrl, 'currency', currency)

	if (process.env.NODE_ENV === 'development') {
		console.log('[dev] iframe settings', {url})
	}

	const iframe = (
		<>
			<Loading id="loading" />
			<iframe
				id="iframe"
				data-qe-id={'iframe--' + qeId}
				sandbox="allow-same-origin allow-modals allow-scripts allow-popups allow-forms"
				loading="eager"
				title={title}
				src={url}
				style={{width: '1px', minWidth: '100%', opacity: 0}}
			/>
		</>
	)

	return iframe
}

export default useIframeResizer
