const colors = {
	//Colors: Brand
	greenLight: '#0DC069',
	green: '#047626',
	blueLight: '#1098E5',
	blue: '#0074D9',
	mustardLight: '#F7BD1C',
	mustard: '#F39C0E',
	mustardTextLight: '#C59207',
	mustardText: '#A66000',
	redLight: '#FF526C',
	red: '#BA2F38',
	black: '#0D1014',
	white: '#ffffff',

	greenBg: '#E5F1E9',
	blueBg: '#E5F1FB',
	mustardBg: '#FDF5E6',
	redBg: '#F8EAEB',

	greenLightBg: '#172E2E',
	blueLightBg: '#162C3D',
	mustardLightBg: '#302F26',
	redLightBg: '#30232E',

	//Colors: Gradient
	gradientGreen: 'linear-gradient(-180deg, #0DC069 0%, #047626 100%)',
	gradientBlue: 'linear-gradient(180deg, #1098E5 0%, #0074D9 100%)',
	gradientSilver: 'linear-gradient(180deg, #FFFFFF 0%, #F3F4F4 98%)',
	gradientMustard: 'linear-gradient(-180deg, #F7BD1C 0%, #F39C0E 100%)',
	gradientRed: 'linear-gradient(-180deg, #FF526C 0%, #BA2F38 100%)',
	gradientGrey: 'linear-gradient(-180deg, #C5C7C9 0%, #5E6268 100%)',

	//Colors: Dark Roasts
	espressoDark: '#191F27',
	espressoMedium: '#252B33',
	espressoLight: '#5E6268',
	espressoLightest: '#C5C7C9',
	lightGrey: '#E8E8E9',
	lightGreyLighter: '#F3F4F4',

	//Colors: Light Roasts
	blondeDark: '#FFFFFF',
	blondeMedium: '#F3F3F3',
	blondeLight: '#BABCBE',
	blondeLightest: '#52575D',
	highlight: '#30353D',
	highlightLighter: '#242A32',

	//Colors: Categories
	terminal: '#C2C3C4',
	basic: '#344067',
	solid: '#2D5441',
	fresh: '#5F868F',
	luxe: '#513663',

	// EXTRA
	inputErrorBg: 'rgba(186, 47, 56, 0.05)',
	inputErrorBgHex: '#fbf4f5',
	buttonDisabled: '#e3e3e5',
}

export default colors