import 'regenerator-runtime/runtime'

import {AmplitudeRun, trackConstants} from './trackHelper'

import 'core-js/features/string/match-all'

require('core-js/features/set')
require('core-js/features/string/includes')
require('core-js/features/object/assign')
require('es6-promise').polyfill()
require('isomorphic-fetch')

import 'iframe-resizer/js/iframeResizer'

import {datadogRum} from '@datadog/browser-rum'
import outdatedBrowser from 'outdated-browser-rework'
import React from 'react'
import {hydrate} from 'react-dom'
import {useSSR} from 'react-i18next'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {createStore} from 'redux'
import lbApp from '../reducers'
import {dd, data} from './env'
import './global-styles'
import {OneXpContext} from './hooks/useOneXp'
import './i18n' // initialized i18next instance
import App from './index'
import {getLBGlobalConstants, onNewSession} from './utils'

window.txtToValidateSecretsAreUpdating = data.txtToValidateSecretsAreUpdating

outdatedBrowser({
	isUnknownBrowserOK: true,
	browserSupport: {
		Safari: 9,
		'Mobile Safari': 9,
	},
	messages: {
		en: {
			outOfDate: 'Your browser is out of date!',
			unsupported: 'Our website no longer supports this browser',
			update: {
				web: 'Please change your browser in order to have the best experience on our website',
				googlePlay: 'Please install Chrome from Google Play',
				appStore: 'Please update iOS from the Settings App',
			},
			// You can set the URL to null if you do not want a clickable link or provide
			// your own markup in the `update.web` message.
			url: 'http://outdatedbrowser.com/',
			callToAction: 'Update my browser now',
			close: 'Close',
		},
	},
})

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__

// Create Redux store with initial state
const store = createStore(lbApp, preloadedState)

// shared global constants
getLBGlobalConstants().locale = preloadedState.initialLanguage

onNewSession({
	callback: () => {
		AmplitudeRun(trackConstants.AMPLITUDE_ACTION.add, trackConstants.USER_PROPS.session, 1)
	},
})

// LBHuntsman is defined earlier in the stack
window.LBHuntsman.addVendor(window.LBHuntsman.VENDORS.performance, function() {
	if (window.LBHuntsman.scriptInitialized.ddRum) return
	datadogRum.init({
		applicationId: dd.applicationId,
		clientToken: dd.clientToken,
		site: 'datadoghq.com',
		service: 'lbhuntsman',
		env: process.env.NODE_ENV,
		version: dd.version,
		sampleRate: 100,
		trackInteractions: true,
		allowedTracingOrigins: [/https?:\/\/.*\.loungebuddy\.com/],
	})
	window.LBHuntsman.scriptInitialized.ddRum = true
})

if (document.referrer) {
	AmplitudeRun(trackConstants.AMPLITUDE_ACTION.set, trackConstants.USER_PROPS.referrer, document.referrer)
}

const BaseApp = () => {
	useSSR(preloadedState.initialI18nStore, preloadedState.initialLanguage)
	React.useEffect(() => {
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles)
		}

		//for debugging prod
		window.__lb = {}
	}, [])

	return (
		<Provider store={store}>
			<BrowserRouter>
				<OneXpContext.Provider value={preloadedState.oneXp}>
					<App />
				</OneXpContext.Provider>
			</BrowserRouter>
		</Provider>
	)

	// return (
	// 	<Suspense fallback={<div>Loading...</div>}>
	// 		<Provider store={store}>
	// 			<BrowserRouter>
	// 				<ScrollToTop>
	// 					<ErrorBoundary>
	// 						<OneXpContext.Provider value={preloadedState.oneXp}>
	// 							<App />
	// 						</OneXpContext.Provider>
	// 					</ErrorBoundary>
	// 				</ScrollToTop>
	// 			</BrowserRouter>
	// 		</Provider>
	// 	</Suspense>
	// )
}

hydrate(<BaseApp />, document.getElementById('root'))
