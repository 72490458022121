import React from 'react'
import {Avatar} from '@material-ui/core'
import styled from 'styled-components'

// More sizes we will define once we will have them
export default styled(({...props}) => <Avatar classes={{root: 'root'}} {...props} />)`
  &.root {
    width: 48px;
    height: 48px;
  }
`
