import React from 'react'
import IconBase from '../IconBase'
import { color } from '../../Global/Colors'
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const CloseFillIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 16 16" {...props}>
			<defs>
				<rect id={id + 'a'} x="0" y="0" width="16" height="16" rx="8"></rect>
			</defs>
			<g id={id + 'd'} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<mask id={id + 'b'} fill="white">
					<use xlinkHref={"#" + id + "a"} />
				</mask>
				<g id={id + 'e'}></g>
				<g id={id + 'c'} mask={`url(#${id + 'b'})`} fill={props.color || 'currentColor'}>
					<rect x="0" y="0" width="17" height="17"></rect>
				</g>
				<path d="M6.93933983,8 L4.46966991,5.53033009 C4.1767767,5.23743687 4.1767767,4.76256313 4.46966991,4.46966991 C4.76256313,4.1767767 5.23743687,4.1767767 5.53033009,4.46966991 L8,6.93933983 L10.4696699,4.46966991 C10.7625631,4.1767767 11.2374369,4.1767767 11.5303301,4.46966991 C11.8232233,4.76256313 11.8232233,5.23743687 11.5303301,5.53033009 L9.06066017,8 L11.5303301,10.4696699 C11.8232233,10.7625631 11.8232233,11.2374369 11.5303301,11.5303301 C11.2374369,11.8232233 10.7625631,11.8232233 10.4696699,11.5303301 L8,9.06066017 L5.53033009,11.5303301 C5.23743687,11.8232233 4.76256313,11.8232233 4.46966991,11.5303301 C4.1767767,11.2374369 4.1767767,10.7625631 4.46966991,10.4696699 L6.93933983,8 Z" id={id + 'e'} fill={props.fill || "#FFFFFF"} fillRule="nonzero" mask={`url(#${id + 'b'})`}></path>
			</g>
		</IconBase>
	)
}

export default CloseFillIcon
