/* eslint-disable no-unused-vars */
import React from 'react'
import Helmet from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import Cookies from 'universal-cookie'

import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import {fetchJson} from '../../util/universal'
import loadData from '../component/loadData'
import {loungeAccessTypeNameMapForTracking} from '../constants'
import {trackEcommerceEvent} from '../trackHelper'
import {LBPropTypes, getLoungeAccessType} from '../utils'

import useIframeResizer from '../hooks/useIframeResizer'
import usePageview from '../hooks/usePageview'

const LayoutTypes = {
	map: 'map',
	list: 'list',
}

const MAP_EXP = {
	name: 'Airport Page Maps',
	variants: {
		control: 'control', //list
		Maps: 'Maps',
	},
}

const LoungeListing = props => {
	const {
		airport,
		t,
		sourceSpecificBodyClassName,
		location,
		nextjsUrl,
		purchasableLounges,
		regularLounges,
		initialLanguage,
		activeCurrency,
	} = props

	React.useEffect(() => {
		//  eCommerce tracking - measuring impressions
		const impressions = purchasableLounges.map(lounge => ({
			name: lounge.productName,
			id: lounge.id,
		}))

		// TODO: Track origin (where did the customer come from, e.g. Homepage, Lounge Page, open the link directly)
		trackEcommerceEvent('impressions', impressions, undefined, {
			airport: airport.displayIATA,
		})
	}, [airport.displayIATA, purchasableLounges])

	// GA tracking
	const handleLoungeClick = React.useCallback(
		lounge => {
			const loungeAccessType = getLoungeAccessType(lounge)
			const products = [
				{
					name: lounge.productName,
					id: lounge.id,
					position: lounge.purchasable ? purchasableLounges.findIndex(a => a.id === lounge.id) + 1 : undefined,
				},
			]

			trackEcommerceEvent('click', {products}, undefined, {
				product: lounge.name,
				airport: lounge.displayIATA,
				terminal: lounge.location.terminal,
				loungeAccessType: loungeAccessTypeNameMapForTracking[loungeAccessType],
				purchasableLoungesNr: lounge.purchasable ? purchasableLounges.length : undefined,
			})
		},
		[purchasableLounges]
	)

	const [layout, setLayout] = React.useState(LayoutTypes.list)

	// React.useEffect(() => {
	// 	setTimeout(() => {
	// 		setLayout(LayoutTypes.map)
	// 	}, 2000)
	// })

	const history = useHistory()

	const {displayIATA} = airport
	const locationString = airport.location.description || airport.location.city
	const locationSplit = locationString.split(', ')
	const displayAirportCountry = locationSplit[locationSplit.length - 1]
	const displayAirportDescription = airport.name

	const otherQuerystrings = location.search ? `&${location.search.substring(1)}` : ''
	const iframeUrl = new URL(`/${initialLanguage}/${airport.IATA}?layout=${layout}${otherQuerystrings}`, nextjsUrl)

	usePageview(props)

	const [iFrameResizer, setIframeResizer] = React.useState(null)
	const iframeElement = useIframeResizer({
		title: 'Lounge listings',
		location,
		iframeUrl,
		qeId: 'airport',
		currency: activeCurrency.code,
		onReady: iFrameResizer => {
			setIframeResizer(iFrameResizer)
		},
		onMessage: ({action, params, context}) => {
			if (action === 'applyFilter') {
				const searchParams = new URLSearchParams(window.location.search)
				searchParams.set('filters', params.values)
				window.history.replaceState(null, '', window.location.pathname + '?' + searchParams)
			} else if (action === 'loungeClick') {
				const lounge =
					purchasableLounges.find(({id}) => id === context) || regularLounges.find(({id}) => id === context)
				if (lounge) handleLoungeClick(lounge)
			}
		},
	})

	/* 
	[
    {
        "id": "9f1dec73-9c3b-4b27-8c25-f1e0a91f4429",
        "name": "Airport Page Maps",
        "dimensions": [
            {
                "name": "Airport Page",
                "variant": "Maps",
                "impressed": false
            }
        ],
        "objectives": [
            {
                "name": "Clicks",
                "description": "The Click through rate for lounges, i.e. the number of conversions from airport page to lounge pages",
                "identifier": "AirportPage_Lounge_Clicks",
                "success": "",
                "objectiveKeys": []
            }
        ],
        "type": "AB",
        "tags": [],
        "locator": "(http|https):\\/\\/www.loungebuddy.[com|com.au|co.uk|fr|de|mx]+\\/[A-Z]{3}[\\?|\\/]*"
    }
]
	*/

	// React.useEffect(() => {
	// 	// ;(async () => {
	// 	// 	const exps = await getExperiments()
	// 	// 	const mapExp = exps.find(exp => exp.name === MAP_EXP.name)

	// 	// 	console.log('exps', exps)
	// 	// })()

	// }, [getExperiments, handleLoungeClick, history, location, purchasableLounges, regularLounges])

	return (
		<div>
			<Helmet
				title={t('pageTitle', {
					displayIATA,
					displayAirportDescription,
					displayAirportCountry,
				})}
				meta={[
					{name: 'description', content: airport.description},
					{name: 'iata', content: displayIATA},
				]}
				bodyAttributes={{
					class: `page-spa page-spa--airport ${sourceSpecificBodyClassName}`,
				}}
			/>

			{iframeElement}
		</div>
	)
}

LoungeListing.propTypes = {
	airport: LBPropTypes.airport,
	location: LBPropTypes.location,
	history: PropTypes.shape({
		replace: PropTypes.func,
	}),
	purchasableLounges: PropTypes.arrayOf(LBPropTypes.lounge),
	regularLounges: PropTypes.arrayOf(LBPropTypes.lounge),
	sourceSpecificBodyClassName: PropTypes.string,
	source: PropTypes.string,
	t: PropTypes.func,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	//todo: update redux
	const {
		purchasableLounges,
		regularLounges,
		airport,
		hostUrl,
		initialI18nStore,
		initialLanguage,
		error,
		nextjsUrl,
		activeCurrency,
	} = state
	return {
		purchasableLounges,
		regularLounges,
		airport,
		hostUrl,
		initialI18nStore,
		initialLanguage,
		error,
		nextjsUrl,
		activeCurrency,
	}
}

export default compose(
	withTranslation('loungeListing'),
	connect(mapStateToProps),
	loadData({
		dataLoaded: ({airport, match}) => airport && match.url.endsWith(airport.IATA),
		updateData: location => {
			const path = location.pathname

			const cookies = new Cookies()
			const currency = cookies.get('currencyCode') || 'USD'
			const otherQuerystrings = location.search ? `&${location.search.substring(1)}` : ''

			return fetchJson(`/api/airports${path}?currency=${currency + otherQuerystrings}`, {ttl: 60 * 60000})
		},
	})
)(LoungeListing)
