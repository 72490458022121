import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

const Error = ({error}) => {
	const {t} = useTranslation('common')

	console.error('Error', error)

	return (
		<div style={{textAlign: 'center', marginTop: 30, marginBottom: 30}}>
			<h3>{t('errorOccuredTryAgainLater')}</h3>
		</div>
	)
}

Error.propTypes = {
	error: PropTypes.string,
}

export default Error
