import React from 'react'

export default ({ lounge, showDirection = true }) => (
	<div className="name">
		<p style={{marginBottom: 0}}><strong>{lounge.name}</strong></p>
		<p style={{marginBottom: 0}}>{`${lounge.airport.name} (${lounge.airport.IATA})`}</p>
		<p style={{marginBottom: 0}}>
			{
				`${lounge.location.terminal} ${lounge.airsideDesc}${
					showDirection
						? ', ' + lounge.location.terminalDirections
						: ''
					}`
			}
		</p>
	</div>
)
