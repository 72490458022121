import React, {useState, useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Elements, StripeProvider, injectStripe} from 'react-stripe-elements'
import {useTranslation} from 'react-i18next'

// https://github.com/stripe/react-stripe-elements#loading-stripejs-asynchronously
export default Component => {
	const [stripe, setStripe] = useState(null)
	const stripePublishableKey = useSelector(state => state.stripePublishableKey)
	const {i18n} = useTranslation()

	useEffect(() => {
		if (window.Stripe) {
			setStripe(
				window.Stripe(stripePublishableKey, {
					apiVersion: '2022-11-15',
				})
			)
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				setStripe(
					window.Stripe(stripePublishableKey, {
						apiVersion: '2022-11-15',
					})
				)
			})
		}
	}, [])

	// note: injectStripe can only be called once per mount
	const Injected = useMemo(() => injectStripe(Component), [])

	const StripeComponent = props => (
		<StripeProvider stripe={stripe}>
			<Elements locale={i18n.language}>
				<Injected {...props} />
			</Elements>
		</StripeProvider>
	)

	return StripeComponent
}
