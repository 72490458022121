import React from 'react'
import IconBase from '../IconBase'
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const AmexCardIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M24 14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h20c1.1 0 2 .9 2 2v12z"
					id={id + 'a'}
				/>
			</defs>
			<g transform="translate(0 4)" fill="none" fillRule="evenodd">
				<mask id={id + 'b'} fill="#fff">
					<use xlinkHref={`#${id + 'a'}`} />
				</mask>
				<use fill="#016FD0" xlinkHref={`#${id + 'a'}`} />
				<g mask={`url(#${id + 'b'})`} fill="#016FD0">
					<path d="M24 14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h20c1.1 0 2 .9 2 2v12z" />
				</g>
				<path
					fill="#FFF"
					mask={`url(#${id + 'b'})`}
					d="M10.6 2h13v12h-13z"
				/>
				<path
					d="M7.6 0v16h16v-2.535h-1.93l-.994-1.1-.999 1.1h-6.364V8.343h-2.054l2.548-5.766h2.457l.877 1.976V2.577h3.042l.528 1.489.531-1.489H23.6V0h-16zm14.12 3.218l-1.005 2.79-.998-2.79h-1.912v4.478h1.208V4.562l1.152 3.134h1.075l1.151-3.14v3.14H23.6V3.218h-1.88zm-7.466 0l-1.976 4.478h1.343l.371-.895h2.06l.377.895h1.376l-1.977-4.478h-1.574zm.768 1.113l.608 1.465h-1.216l.608-1.465zm6.998 4.015l-1.31 1.433-1.3-1.433h-1.605l2.11 2.239-2.11 2.239h1.56l1.319-1.446 1.31 1.446H23.6l-2.123-2.252L23.6 8.346h-1.58zm-8.027 0v4.478h3.812v-1.037h-2.59v-.697h2.526v-1.01h-2.527v-.698h2.59V8.346h-3.811zm8.377 2.228l1.23 1.308V9.277l-1.23 1.297z"
					fill="#006FCF"
					mask={`url(#${id + 'b'})`}
				/>
				<path
					d="M22 .4c.882 0 1.6.718 1.6 1.6v12c0 .882-.718 1.6-1.6 1.6H2c-.882 0-1.6-.718-1.6-1.6V2C.4 1.118 1.118.4 2 .4h20zm0-.4H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
					fillOpacity={0.25}
					fill="#191F27"
					mask={`url(#${id + 'b'})`}
				/>
			</g>
		</IconBase>
	)
}

export default AmexCardIcon
