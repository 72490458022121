export const InventoryTypes = { Room: 'room', Lounge: 'lounge' } // Lounge is a placeholder
export function getInventoryTypes(inventories) {
	if (!Array.isArray(inventories) || inventories.length === 0) {
		return {};
	}

	const types = {};

	inventories.forEach(inventory => {
		if(inventory.pricing) {
			Object.keys(inventory.pricing).forEach(type => {
				types[type] = 1;
			});
		}
	});

	return types;
}
