import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM4.256 5.672A9.959 9.959 0 002 12c0 5.523 4.477 10 10 10 2.4 0 4.604-.846 6.328-2.256L4.256 5.672zM12 2c-2.4 0-4.604.846-6.328 2.256l14.072 14.072A9.959 9.959 0 0022 12c0-5.523-4.477-10-10-10zm-3.829 9l3.001 3H5.255c-.413 0-.58-.046-.743-.134a.909.909 0 01-.378-.378c-.088-.163-.134-.33-.134-.743v-.49c0-.413.046-.58.134-.743a.909.909 0 01.378-.378c.163-.088.33-.134.743-.134H8.17zM18 11a1 1 0 011 1v1a1 1 0 01-1 1h-1v-3h1zm-1.5 0v2.672L13.828 11H16.5zm0-4.5c.245 0 .45.142.492.328L17 6.9v.8c0 .175.049.262.255.437l.099.08c.414.332.606.566.64.95L18 9.3v.8c0 .22-.224.4-.5.4-.245 0-.45-.142-.492-.328L17 10.1v-.8c0-.175-.049-.262-.255-.437l-.099-.08c-.414-.332-.606-.566-.64-.95L16 7.7v-.8c0-.22.224-.4.5-.4zM18 6c.245 0 .45.142.492.328l.008.072v.8c0 .175.049.262.255.437l.099.08c.414.332.606.566.64.95l.006.133v.8c0 .22-.224.4-.5.4-.245 0-.45-.142-.492-.328L18.5 9.6v-.8c0-.175-.049-.262-.255-.437l-.099-.08c-.414-.332-.606-.566-.64-.95L17.5 7.2v-.8c0-.22.224-.4.5-.4z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
