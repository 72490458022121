import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const ClothesPress = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M23.477 18.458l.09.003a.5.5 0 01.434.558 3.662 3.662 0 01-.573 1.49c-.259.399-.59.738-1.001.995-.995.622-2.333.698-4.035.128l-.288-.102c-2.613 2.179-5.411 2.138-8.001.757a8.85 8.85 0 01-1.038-.648l-.15-.113c-1.846.683-3.281.635-4.331-.022a3.238 3.238 0 01-1.002-.995 3.662 3.662 0 01-.573-1.49.5.5 0 11.992-.124l.016.092a2.92 2.92 0 00.403.978c.183.281.412.516.694.691.805.503 2.002.518 3.705-.163l.018-.007.017-.006-.035.013a.502.502 0 01.106-.03l.033-.004a.462.462 0 01.048-.002l.043.002a.469.469 0 01.091.017l.03.01a.455.455 0 01.083.037l.031.02c.015.009.03.02.043.031l.01.01.088.073a7.856 7.856 0 001.149.748c2.331 1.243 4.776 1.243 7.099-.822l.005-.005.005-.004.004-.003.03-.023.039-.025a.55.55 0 01.166-.06.498.498 0 01.164 0l.052.012.017.005.02.007.016.006c1.703.68 2.9.666 3.705.163.282-.175.51-.41.694-.692a2.92 2.92 0 00.388-.913l.015-.064.016-.092a.5.5 0 01.381-.425l.087-.012zm-9.972-1.61c.245 0 .375.22.5.5s.5 1.723.5 2c0 .275 0 1-1 1s-1-.72-1-1 .375-1.752.5-2c.125-.249.224-.5.5-.5zm-5.816-.86c.226.096.259.35.265.656.005.306-.214 1.782-.322 2.036-.107.254-.39.92-1.31.53-.921-.39-.64-1.054-.53-1.311.109-.258 1.03-1.466 1.241-1.646.212-.18.402-.373.656-.265zm11.896.265c.212.18 1.132 1.388 1.241 1.646.11.257.391.92-.53 1.31-.92.392-1.203-.275-1.31-.529-.108-.254-.327-1.73-.322-2.036.006-.307.04-.56.265-.656.254-.108.444.085.656.265zm2.42-3.296a1 1 0 110 2h-17a1 1 0 010-2h17zM14.265.577l2.554 1.057a1 1 0 01.615.853l.092 1.296a11.403 11.403 0 013.213 4.692l1.266 3.482h-17c-.167-1-.667-1.667-1.5-2-.557-.223-1.263-.297-2.118-.222A1 1 0 01.324 8.522l1.159-5.216A3 3 0 014.41.956h5.594c.898 0 1.778.106 2.626.306l.596-.517a1 1 0 011.038-.169zm-4.26 2.38H4.682a1 1 0 00-.928.629l-.925 2.312A1 1 0 004.093 7.21l6.262-2.236a1.04 1.04 0 00-.35-2.018z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default ClothesPress
