/* eslint-disable @typescript-eslint/no-var-requires */
import React, {useEffect} from 'react'
import {Route, Switch, useHistory, useLocation} from 'react-router'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
import Helmet from 'react-helmet'
import Header from './component/Header'
import PageNotFound from './PageNotFound'
import {appendQueryParamsToUrl, LBPropTypes, openChat} from './utils'
import PropTypes from 'prop-types'
import {LoadingContext} from './component/loadData/loadingContext'
import ScrollMemory from 'react-router-scroll-memory'
import {encodeStr} from '../util/universal'
import {RouteRegex} from '../util/constants'
import TopBanner from './component/TopBanner'

const ssrRoutes = require('./routes/serverRoutes')

Helmet.defaultProps.encodeSpecialCharacters = false

const App = ({hreflangList, initialLanguage, hostUrl, locale, showInfoBanner, bannerConfig, apiEnv, ...rest}) => {
	const location = useLocation()
	const history = useHistory()

	// This is for appending user inputs as query params
	const lastLocation = React.useRef(null)
	React.useEffect(() => {
		// if there is "params" in state, update the url to include the params
		const last = lastLocation.current
		if (last && last.pathname !== location.pathname && last.state && last.state.params) {
			const params = last.state.params
			history.replace({
				search: appendQueryParamsToUrl(location.search, params),
				state: {...location.state, params},
			})
		}

		return () => {
			lastLocation.current = location // this will save the location before "Back" button
		}
	}, [location])

	const [isLoading, setIsLoading] = React.useState(false)

	useEffect(() => {
		$('#mega-menu-1').css('display', 'block')

		$('#mobileNavToggler').click(function() {
			$('#mobileNav').slideToggle('fast')
			$(this).toggleClass('active')
		})

		$('.lb-footer .row--collapsable').click(function() {
			$(this).toggleClass('open')
		})

		const bannerKey = 'disableInfoBannerAnimation'
		const $bannerEl = $('#info-banner')

		if (!window.Cookies.get(bannerKey)) {
			$bannerEl.delay(1000).slideDown(700, function() {
				$(this)
					.delay(200)
					.queue(function(next) {
						$(this).addClass('opened')
						window.Cookies.set(bannerKey, true)
						next()
					})
			})
		}

		window.openChat = openChat
	}, [])

	// Set global meta tags here
	const helmetProps = {
		htmlAttributes: {lang: initialLanguage, amp: undefined},
		titleTemplate: apiEnv + '%s | LoungeBuddy',
		titleAttributes: {itemprop: 'name', lang: initialLanguage},
		meta: [
			{charset: 'utf-8'},
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, user-scalable=1',
			},
			{'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1'},
		],
		link: [
			{rel: 'shortcut icon', type: 'image/x-icon', href: '/favicon-64.ico'},
			{rel: 'apple-touch-icon', href: '/images/apple-touch-icon.png'},
			{
				rel: 'apple-touch-icon',
				href: '/images/apple-touch-icon-76x76.png',
				sizes: '76x76',
			},
			{
				rel: 'apple-touch-icon',
				href: '/images/apple-touch-icon-120x120.png',
				sizes: '120x120',
			},
			{
				rel: 'apple-touch-icon',
				href: '/images/apple-touch-icon-152x152.png',
				sizes: '152x152',
			},
			{
				rel: 'apple-touch-icon',
				href: '/images/apple-touch-icon-180x180.png',
				sizes: '180x180',
			},
			{rel: 'icon', href: '/images/icon-hires.png', sizes: '192x192'},
			{rel: 'icon', href: '/images/icon-normal.png', sizes: '128x128'},
			{
				rel: 'stylesheet',
				href: "https://fonts.googleapis.com/css?family=Oswald:400,700,300' type='text/css",
			},
			{
				rel: 'preload',
				href: '/fonts/fontawesome-webfont.woff2?v=4.3.0',
				type: 'font/woff2',
				as: 'font',
				crossOrigin: 'anonymous',
			},
			{rel: 'canonical', href: hostUrl + encodeStr(location.pathname)},
		].concat(
			hreflangList.map(({href, hreflang}) => ({
				rel: 'alternate',
				href,
				hreflang,
			}))
		),
	}

	const pathname = location.pathname
	const isBookingPage = /^\/bookings\//.test(pathname)
	const checkoutViewPaths = ['/checkout']
	const isCheckoutView = checkoutViewPaths.includes(pathname) || isBookingPage
	const isAirportPage = RouteRegex.airportListing.test(pathname)
	const isHomepage = pathname === '/'
	const isLocationsPage = pathname === '/select/locations'
	const isLoungeDetailPage = RouteRegex.loungeDetailPage.test(pathname)

	const hideHeaderAndFooter = !isCheckoutView

	const headerProps = {
		...rest,
		isCheckoutView,
		hideSearchBar: isLoungeDetailPage || isAirportPage || isHomepage || isLocationsPage,
	}

	const now = new Date()
	const showBanner =
		showInfoBanner && bannerConfig && new Date(bannerConfig.startDate) < now && now < new Date(bannerConfig.endDate)

	//todo: https://reactjs.org/docs/concurrent-mode-suspense.html
	return (
		<LoadingContext.Provider value={{isLoading, setIsLoading}}>
			<Helmet {...helmetProps} />
			{showBanner && <TopBanner {...bannerConfig} locale={locale} />}
			{!hideHeaderAndFooter && <Header {...headerProps} hostUrl={hostUrl} locale={locale} />}
			<ScrollMemory />
			<Switch>
				{ssrRoutes.map(({key, ...route}) => (
					<Route key={key} {...route} />
				))}
				<Route component={PageNotFound} />
			</Switch>
		</LoadingContext.Provider>
	)
}

App.propTypes = {
	hreflangList: PropTypes.arrayOf(
		PropTypes.shape({
			href: PropTypes.string,
			hreflang: PropTypes.string,
		})
	),
	location: LBPropTypes.location,
	initialLanguage: PropTypes.string,
	hostUrl: PropTypes.string,
	locale: PropTypes.string,
	isWebview: PropTypes.bool,
	apiEnv: PropTypes.string,
	showInfoBanner: PropTypes.bool,
	bannerConfig: PropTypes.object,
}

function mapStateToProps(state) {
	const {
		initialI18nStore,
		initialLanguage,
		hostUrl,
		error,
		hreflangList,
		activeCurrency,
		embed,
		locales,
		locale,
		currencies,
		supportedLocales,
		headerWrapperClasses = [],
		purchasableLoungeCount,
		purchasableLoungeCountPerAirport,
		currentHost,
		showInfoBanner,
		bannerConfig,
		isWebview,
		apiEnv,
	} = state

	return {
		initialI18nStore,
		initialLanguage,
		hostUrl,
		error,
		hreflangList,
		activeCurrency,
		embed,
		locales,
		locale,
		currencies,
		supportedLocales,
		headerWrapperClasses,
		purchasableLoungeCount,
		purchasableLoungeCountPerAirport,
		currentHost,
		showInfoBanner,
		bannerConfig,
		isWebview,
		apiEnv,
	}
}

export default withRouter(compose(connect(mapStateToProps))(App))
