import TicketIcon from '../components/global/LBIcons/icons/Ticket'
import colors from '../components/global/Colors'

import QuestionMarkIcon from '../components/global/LBIcons/icons/QuestionMark'
import MailIcon from '../components/global/LBIcons/icons/Mail'
import FeedbackIcon from '../components/global/LBIcons/icons/Feedback'
import React from 'react'
import styled from 'styled-components'
import {fontWeight, MinorText} from '../components/global/Typography'

import icons from '../components/global/MenuBase64Icons'

import {
  SupportIcon,
  SupportIconColored,
} from '../components/global/MenuBase64Icons/support'
import {
  CurrencyIcon,
  CurrencyIconColored,
} from '../components/global/MenuBase64Icons/currency'
import {
  LanguageIcon,
  LanguageIconColored,
} from '../components/global/MenuBase64Icons/language'
import {ListItem} from '../components/ListItem'
import {ListHOC} from './util'

const Immutable = require('seamless-immutable').static

export const supportLinks = [
  {
    id: '1',
    text: {
      title: 'Manage Reservation',
      description: 'View or cancel your lounge reservation.',
    },
    icon: <TicketIcon size={16} color={colors.espressoMedium} />,
  },
  {
    id: '2',
    text: {
      title: 'Common Questions',
      description: 'Find answers to the most frequently asked questions.',
    },
    icon: <QuestionMarkIcon size={16} color={colors.espressoMedium} />,
  },
  {
    id: '3',
    text: {
      title: 'Leave a Message',
      description: 'We can usually respond to your request within 24 hours.',
    },
    icon: <MailIcon size={16} color={colors.espressoMedium} />,
  },
  {
    id: '4',
    text: {
      title: 'Live Chat',
      description: 'Still have questions? Ask our support team right away.',
    },
    icon: <FeedbackIcon size={16} color={colors.espressoMedium} />,
  },
]

export const CurrencyListIcon = styled(MinorText)`
  display: inline-block;
  width: 55px;
  color: ${colors.espressoMedium};
  font-weight: ${fontWeight.medium};
`

export const currencyListItems = [
  {
    id: '1',
    icon: <CurrencyListIcon>$USD</CurrencyListIcon>,
    text: {
      title: 'U.S. Dollar',
    },
    isSelected: false,
  },
  {
    id: '2',
    icon: <CurrencyListIcon>KMBAM</CurrencyListIcon>,
    text: {
      title: 'Convertible Mark',
    },
    isSelected: true,
  },
  {
    id: '3',
    icon: <CurrencyListIcon>դր.AMD</CurrencyListIcon>,
    text: {
      title: 'Armenian Dram',
    },
    isSelected: false,
  },
  {
    id: '4',
    icon: <CurrencyListIcon>Rs.</CurrencyListIcon>,
    text: {
      title: 'Indian Rupee',
    },
    isSelected: false,
  },
  {
    id: '5',
    icon: <CurrencyListIcon>CHF</CurrencyListIcon>,
    text: {
      title: 'Swiss Francs',
    },
    isSelected: false,
  },
  {
    id: '6',
    icon: <CurrencyListIcon>€EUR</CurrencyListIcon>,
    text: {
      title: 'Euro',
    },
    isSelected: false,
  },
  {
    id: '7',
    icon: <CurrencyListIcon>CAD</CurrencyListIcon>,
    text: {
      title: 'Canadian Dollar',
    },
    isSelected: false,
  },
  {
    id: '8',
    icon: <CurrencyListIcon>CZK</CurrencyListIcon>,
    text: {
      title: 'Česká Koruna',
    },
    isSelected: false,
  },
  {
    id: '9',
    icon: <CurrencyListIcon>Rs.</CurrencyListIcon>,
    text: {
      title: 'Indian Rupee',
    },
    isSelected: false,
  },
  {
    id: '10',
    icon: <CurrencyListIcon>CHF</CurrencyListIcon>,
    text: {
      title: 'Swiss Francs',
    },
    isSelected: false,
  },
  {
    id: '11',
    icon: <CurrencyListIcon>€</CurrencyListIcon>,
    text: {
      title: 'Euro',
    },
    isSelected: false,
  },
  {
    id: '12',
    icon: <CurrencyListIcon>CZK</CurrencyListIcon>,
    text: {
      title: 'Česká Koruna',
    },
    isSelected: false,
  },
  {
    id: '13',
    icon: <CurrencyListIcon>CAD</CurrencyListIcon>,
    text: {
      title: 'Canadian Dollar',
    },
    isSelected: false,
  },
]

export const languageListItems = [
  {
    id: '1',
    code: 'en',
    icon: icons.us,
    text: {
      title: 'English (US)',
    },
    isSelected: true,
  },
  {
    id: '2',
    code: 'en-GB',
    icon: icons.gb,
    text: {
      title: 'English (UK)',
    },
    isSelected: false,
  },
  {
    id: '3',
    code: 'en-AU',
    icon: icons.au,
    text: {
      title: 'English (AU)',
    },
    isSelected: false,
  },
  {
    id: '4',
    code: 'de-DE',
    icon: icons.de,
    text: {
      title: 'Deutsch (DE)',
    },
    isSelected: false,
  },
  {
    id: '5',
    code: 'fr-FR',
    icon: icons.fr,
    text: {
      title: 'Français (FR)',
    },
    isSelected: false,
  },
  {
    id: '6',
    code: 'es-MX',
    icon: icons.mx,
    text: {
      title: 'Español (MX)',
    },
    isSelected: false,
  },
]

export const supportBtn = {
  label: 'Support',
  ariaLabel: 'Show support menu',
  icon: <SupportIcon />,
  iconActive: <SupportIconColored />,
  bgActive: colors.lightGreyLighter,
  isActive: false,
}

export const currencyBtn = {
  label: '$USD',
  ariaLabel: 'Show currency menu',
  icon: <CurrencyIcon />,
  iconActive: <CurrencyIconColored />,
  bgActive: colors.lightGreyLighter,
  isActive: false,
}

export const languageBtn = {
  label: 'English (US)',
  ariaLabel: 'Show locale menu',
  icon: <LanguageIcon />,
  iconActive: <LanguageIconColored />,
  bgActive: colors.lightGreyLighter,
  isActive: false,
}

export const supportDropdownTitle = 'Get Support'
export const currencyDropdownTitle = 'Change Currency'
export const languageDropdownTitle = 'Select Language'

export const menuItemsData = Immutable([
  {
    button: supportBtn,
    dropdownTitle: supportDropdownTitle,
    children: ListHOC(ListItem)(supportLinks),
  },
  {
    button: currencyBtn,
    dropdownTitle: currencyDropdownTitle,
    children: ListHOC(ListItem)(currencyListItems),
  },
  {
    button: languageBtn,
    dropdownTitle: languageDropdownTitle,
    children: ListHOC(ListItem)(languageListItems),
  },
])

const _menuDefaultData = () => ([
  {
    button: supportBtn,
    dropdownTitle: supportDropdownTitle,
    listItems: supportLinks,
  },
  {
    button: currencyBtn,
    dropdownTitle: currencyDropdownTitle,
    listItems: currencyListItems,
  },
  {
    button: languageBtn,
    dropdownTitle: languageDropdownTitle,
    listItems: languageListItems,
  },
])

export function buildMenuItems(items = []) {
  const data = _menuDefaultData()

  items.forEach((item, i) => {
    // get text data from props
    if (item.label) data[i].button.label = item.label
    if (item.title) data[i].dropdownTitle = item.title

    if (Array.isArray(item.listItems)) {
      item.listItems.forEach((listItem, j) => {
        data[i].listItems[j] = {
          ...data[i].listItems[j],
          ...listItem,
        }
        if (listItem.symbol) {
          data[i].listItems[j].icon = (
            <CurrencyListIcon>{listItem.symbol}</CurrencyListIcon>
          )
        }
      })
      data[i].children = ListHOC(ListItem)(data[i].listItems)
    }
  })

  return data
}
