import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M19.577.001h1.796c.904.01 1.258.087 1.62.282.315.168.556.409.724.723.163.305.244.603.272 1.225l.006.197.005.418v18.308l-.005.418c-.019.76-.098 1.087-.278 1.422a1.72 1.72 0 01-.572.633l-.151.09-.084.043c-.278.135-.576.203-1.14.229l-.198.006-.418.005H5.235a2.988 2.988 0 00.756-1.771L6 22V2.846l.005-.418c.019-.76.098-1.087.278-1.422.168-.314.409-.555.723-.723C7.37.088 7.723.01 8.627 0L8.846 0l10.73.001zM4.995 5V11.829l.001.478.001 2.541v2.219l.001.58v1.804l.001.622L5 22l-.005.15a2 2 0 01-1.838 1.844L3 24H2l-.15-.005a2 2 0 01-1.844-1.838L0 22V7l.005-.15a2 2 0 011.838-1.844L2 5h2.995zM16.5 21h-8a.5.5 0 100 1h8a.5.5 0 100-1zm4-2h-1a.5.5 0 100 1h1a.5.5 0 100-1zm-3 0h-9a.5.5 0 100 1h9a.5.5 0 100-1zm4-2h-2a.5.5 0 100 1h2a.5.5 0 100-1zm-4 0h-9a.5.5 0 100 1h9a.5.5 0 100-1zm0-2h-9a.5.5 0 100 1h9a.5.5 0 100-1zm4 0h-2a.5.5 0 100 1h2a.5.5 0 100-1zM19.436 2l-9.068.002c-.745.013-1.044.104-1.345.265-.326.174-.582.43-.756.756-.174.326-.267.65-.267 1.54l.002 6.069c.013.745.104 1.044.265 1.345.174.326.43.582.756.756.326.174.65.267 1.54.267h8.873c.892 0 1.215-.093 1.54-.267.327-.174.583-.43.757-.756.174-.326.267-.65.267-1.54V4.563c0-.892-.093-1.215-.267-1.54a1.817 1.817 0 00-.756-.757c-.326-.174-.65-.267-1.54-.267zm.17 1.001c.529.007.712.048.9.148a.818.818 0 01.345.346c.11.205.149.407.149 1.069l-.001 6.042v.023l-3.472-3.436a.941.941 0 00-1.178-.115l-.763.503-1.326-1.31a.94.94 0 00-1.354.035L9 10.597l.001-6.203c.007-.529.048-.712.148-.9a.818.818 0 01.346-.345C9.7 3.039 9.902 3 10.564 3zM19 4a1 1 0 100 2 1 1 0 000-2z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
