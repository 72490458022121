import React from 'react'
import colors from './global/Colors'
import {makeStyles} from '@material-ui/core'
import styled from 'styled-components'
import {Autocomplete} from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    position: 'relative',
    width: '100%',
  },
  popper: {
    marginTop: 6,
    border: '1px solid ' + colors.lightGrey,
    borderRadius: 8,
    backgroundColor: colors.blondeDark,
    overflow: 'hidden',
    top: '3em',
    textAlign: 'left',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    '& > ul': {
      maxHeight: '100%',
    },
  },
  listbox: {
    padding: 8,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 16,
    borderRadius: 5,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: colors.lightGreyLighter,
    },
  },
}))

export default styled(({...props}) => (
  <Autocomplete classes={useStyles()} {...props} />
))`
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
    .MuiAutocomplete-input:first-child,
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input {
    padding: 0;
    width: 100%;
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
  }
`
