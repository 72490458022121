import React from 'react'
import PropTypes from 'prop-types'
import styled, {css, keyframes} from 'styled-components'
import {BodyTextStyles, MinorTextStyles, fontWeight, MinorText, ColoredLinks} from './Global/Typography'
import {color} from './Global/Colors'
import CloseFillIcon from './LBIcons/icons/CloseFill'
import CircleCheckmarkIcon from './LBIcons/icons/CircleCheckmark'

const Wrap = styled.div`
	width: 100%;
`
export const BoxShadow = css`
	box-shadow: inset 0 0 0 1px ${color.lightGrey}, inset 0 1px 4px 0 ${color.lightGrey};
`
const Placeholder = css`
	color: ${color.espressoLight};
	line-height: 24px;
`
const IconPadding = css`
	padding-right: 48px;
`
export const InputBase = css`
	width: 100%;
	height: ${p => (p.height ? p.height : '50')}px;
	border: 0;
	background: ${color.blondeDark};
	${BoxShadow};
	border-radius: 5px;
	box-sizing: border-box;
	outline: none;
	transition: box-shadow 0.16s linear, background-color 0.16s linear;
	-webkit-appearance: none;
`
export const InputFocus = css`
	box-shadow: inset 0 0 0 2px ${color.blue}, inset 0 1px 4px 0 ${color.lightGrey};
	background: ${color.blondeDark};
`
export const InputErrorShadow = css`
	box-shadow: inset 0 0 0 2px ${color.red}, inset 0 1px 4px 0 ${color.lightGrey};
`

const StyledInput = styled.input`
	display: block;
 	${BodyTextStyles};
	color: ${color.espressoDark};
	line-height: 24px;
	padding: ${p => (p.icon ? '0 16px 0 34px' : '0 16px')};
	${InputBase};

	${p =>
		p.dropdownActive &&
		css`
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		`}

	${p =>
		p.readOnly &&
		css`
			cursor: pointer;
			user-select: none;
		`}

	&:focus {
		${InputFocus};

		& ~ svg {
			opacity: 0;
			transform: scale(0.8);
		}
	}

	${p =>
		p.success &&
		css`
			${BoxShadow};
			${IconPadding};
		`}

	${p =>
		p.error &&
		css`
			background: ${color.inputErrorBg};
			${InputErrorShadow};
			${IconPadding};
		`}

	${p =>
		p.isLoading &&
		css`
			${BoxShadow};
			${IconPadding};
		`}

	${p =>
		p.disabledWithOpacity &&
		css`
			&:disabled {
				opacity: 0.4;
			}
		`}

	&::-webkit-input-placeholder {
		${Placeholder};
	}
	&::-moz-placeholder {
		${Placeholder};
	}
	&:-ms-input-placeholder {
		${Placeholder};
	}
	&:-moz-placeholder {
		${Placeholder};
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active  {
		box-shadow: inset 0 0 0 1px ${color.lightGrey}, inset 0 1px 4px 0 ${color.lightGrey} !important;
	}
`
export const Label = styled.label`
	display: inline-block;
	margin-bottom: 8px;
	${MinorTextStyles};
	font-weight: ${fontWeight.medium};
`
const InputWrap = styled.div`
	position: relative;
`
const Icn = styled.span`
	display: block;
	position: absolute;
	width: 16px;
	height: 16px;
	top: 50%;
	margin-top: -8px;
	right: 16px;
	transition: opacity 0.16s linear, transform 0.16s linear;
	opacity: 1;

	svg {
		position: absolute;
		margin: 0;
	}
`
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const LoadingIcon = styled(Icn)`
	width: 16px;
	height: 16px;
	background: url(/images/checkout/loading@2x.png);
	background-size: 100% auto;
	animation: ${rotate} 1.1s infinite linear;
`
export const Text = styled(MinorText)`
	display: block;
	margin-top: 8px;
	color: ${color.espressoLight};
	${ColoredLinks};

	${p =>
		p.color &&
		css`
			color: ${p.color};
		`}
`

const Input = ({
	className,
	name,
	title,
	loading,
	note,
	errors = {},
	formState,
	formRef,
	showErrorMsg = true,
	...other
}) => {
	const success = formState && formState.touched[name] && formState.dirty && !errors[name]

	return (
		<Wrap className={className}>
			{title && <Label htmlFor={name}>{title}</Label>}
			<InputWrap>
				<StyledInput
					name={name}
					id={name}
					success={success}
					error={errors[name]}
					isLoading={loading}
					ref={formRef}
					aria-invalid={errors[name] ? 'true' : 'false'}
					{...other}
				/>
				{loading ? (
					<LoadingIcon focusable="false" role="img" aria-hidden="true" />
				) : errors[name] ? (
					<Icn focusable="false" role="img" aria-hidden="true">
						<CloseFillIcon size={16} color={color.red} />
					</Icn>
				) : success ? (
					<Icn focusable="false" role="img" aria-hidden="true">
						<CircleCheckmarkIcon size={16} color={color.blue} />
					</Icn>
				) : null}
			</InputWrap>
			<Text role="alert" color={color.red}>
				{showErrorMsg && errors[name] && <>{errors[name].message}</>}
			</Text>
			{note && <Text>{note}</Text>}
		</Wrap>
	)
}

Input.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	title: PropTypes.string,
	loading: PropTypes.bool,
	note: PropTypes.string,
	formRef: PropTypes.func, // for react-hook-form
	errors: PropTypes.shape({
		message: PropTypes.string,
	}),
	formState: PropTypes.shape({
		touched: PropTypes.object,
		dirty: PropTypes.bool,
	}),
	type: PropTypes.string,
	showErrorMsg: PropTypes.bool, //todo: change to inputState when split out error text message
}

export default Input
