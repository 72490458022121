import React from 'react'
import {format, differenceInHours} from 'date-fns'
import {compose, withState, withHandlers} from 'recompose'
import {CALENDAR_FORMAT} from '../../util/constants'

const enhance = compose(
	withState('isOpen', 'updateIsOpen', false),
	withHandlers({
		toggle: ({isOpen, updateIsOpen, track}) => () => {
			updateIsOpen(!isOpen)
			if (typeof track === 'function' && !isOpen) track()
		},
	})
)

export default enhance(({btnText = 'Add to calendar', t, isOpen, toggle, startDate, endDate, ...data}) => {
	// Reset to utc time, e.g. 20171207T141216Z
	data.startDate = format(startDate, CALENDAR_FORMAT)
	data.endDate = format(endDate, CALENDAR_FORMAT)
	data.description = encodeURIComponent(data.description)

	return (
		<div className="button button--light" onClick={toggle} style={{overflow: 'visible'}}>
			<span>{btnText}</span>
			{isOpen && (
				<ul className="dropdown-menu" style={{display: 'block'}}>
					<li>
						<a href={getICalendarUrl(data)} rel="noreferrer noopener" target="_blank">
							{t('appleCalendar')}
						</a>
					</li>
					<li>
						<a href={getGoogleCalendarUrl(data)} rel="noreferrer noopener" target="_blank">
							{t('googleCalendar')}
						</a>
					</li>
					<li>
						<a href={getICalendarUrl(data)} rel="noreferrer noopener" target="_blank">
							{t('outlook')}
						</a>
					</li>
					<li>
						<a href={getYahooCalendarUrl(data)} rel="noreferrer noopener" target="_blank">
							{t('yahooCalendar')}
						</a>
					</li>
					<li>
						<a href={getMicrosoftCalendarUrl(data)} rel="noreferrer noopener" target="_blank">
							{t('microsoftCalendar')}
						</a>
					</li>
				</ul>
			)}
		</div>
	)
})

function getGoogleCalendarUrl({title, startDate, endDate, description, location}) {
	const googleCalendarUrl = 'https://www.google.com/calendar/render?action=TEMPLATE'
	return `${googleCalendarUrl}&text=${title}&dates=${startDate}/${endDate}&details=${description}&location=${location}`
}

function getMicrosoftCalendarUrl({title, startDate, endDate, description, location}) {
	const microsoftCalendarUrl = 'https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent'
	return `${microsoftCalendarUrl}&subject=${title}&startdt=${startDate}&enddt=${endDate}&body=${description}&location=${location}`
}

function getYahooCalendarUrl({startDate, endDate, title, description, location}) {
	const yahooCalendarUrl = 'http://calendar.yahoo.com/?v=60&view=d&type=20'
	const duration = getMilitaryHours(differenceInHours(endDate, startDate))
	return `${yahooCalendarUrl}&TITLE=${title}&ST=${startDate}&DUR=${duration}&DESC=${description}&in_loc=${location}`
}

//http://loungebuddy.com/apiService/ical&text=British%20Airways%20First%20Lounge&start=2017-12-07T14%3A12%3A16Z&end=2017-12-07T17%3A12%3A16Z&description=Total%20Entries%3A%201%0ALength%20of%20Visit%3A%20Lounge%20Access%203%20hrs%0ADirections%3A%20Pier%20A%20-%20Level%203%20-%20near%20Gate%20A4%20Departures%20Concourse%0ALocated%20in%20International%20Terminal%20A&location=San%20Francisco%20International%20Airport%20(SFO)
function getICalendarUrl({title, startDate, endDate, description, location}) {
	const iCalendarUrl = '/apiService/ical'
	return `${iCalendarUrl}?title=${title}&start=${startDate}&end=${endDate}&description=${description}&location=${location}`
}

function getMilitaryHours(hours) {
	if (hours < 10) {
		hours = '0' + hours
	}
	return hours + '00'
}
