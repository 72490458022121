import React from 'react'
import {compose, withState, withHandlers, mapProps} from 'recompose'
import {withTranslation} from 'react-i18next'
import TerminalDirections from './TerminalDirections'
import {Collapse} from 'react-collapse'
import styled from 'styled-components'
import {_} from '../../../util/universal'

export const Button = styled.button`
	border: none;
	background: none;
`

const enhance = compose(
	withTranslation('terminalDetailToggle'),
	withState('showAccess', 'updateShowAccess', false),
	withHandlers({
		toggleAccess: ({showAccess, updateShowAccess}) => () => {
			updateShowAccess(!showAccess)
		},
	}),
	mapProps(({forceOpen, showAccess, ...others}) => {
		return {
			...others,
			showAccess: forceOpen || showAccess,
		}
	})
)

// accessibility reference: https://github.com/nkbt/react-collapse/blob/HEAD/example/App/Accessible.js
const accessibilityId = _.uniqueId('terminal-toggle')

export default enhance(({showAccess, toggleAccess, location, t, textForShowDetails, airsideDesc = ''}) => (
	<div className="direction-toggle">
		<div className="access-points" style={{height: 'auto'}}>
			<Collapse isOpened={showAccess}>
				{showAccess && (
					<TerminalDirections id={accessibilityId} loc={location} allTerminals airsideDesc={airsideDesc} t={t} />
				)}
			</Collapse>
		</div>

		<span className="toggle-button" onClick={toggleAccess}>
			<Button aria-controls={accessibilityId} aria-expanded={showAccess}>
				{showAccess ? (
					<>
						{t('hideTerminalDetails')}
						<i className="fa fa-angle-up" aria-hidden="true" />
					</>
				) : (
					<>
						{textForShowDetails}
						<i className="fa fa-angle-down" aria-hidden="true" />
					</>
				)}
			</Button>
		</span>
	</div>
))
