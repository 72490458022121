import React, {memo, useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import Input, {Text} from '../component/Input'
import styled, {css} from 'styled-components'
import {BodyTextStyles} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import PlusCircleIcon from '../component/LBIcons/icons/PlusCircle'
import TagIcon from '../component/LBIcons/icons/Tag'
import {useForm} from 'react-hook-form'
import {fetchJson} from '../../util/universal'
import {trackConstants} from '../trackHelper'
import Button from '../component/Button'

const PromoLabelStyles = css`
	display: flex;
	align-items: center;
	${BodyTextStyles}
	color: ${color.blue};
	cursor: pointer;
	user-select: none;

	&:hover,
	&:active {
		text-decoration: underline;
	}
`
const RemoveBtnStyles = css`
	border: 0;
	padding: 0;
	background: none;
	appearance: none;
`
const CodeApplied = styled.span`
	${PromoLabelStyles};
	color: ${color.green};
	cursor: default;

	&:hover,
	&:active {
		text-decoration: none;
	}
`
const Label = styled.label`
	${PromoLabelStyles};
	display: inline-flex;
	${p =>
		p.isOpen &&
		css`
			color: ${color.espressoMedium};
		`}
`
const Close = styled.button`
	display: inline-flex;
	margin-left: 8px;
	color: ${color.espressoMedium};
	${RemoveBtnStyles};

	&:hover {
		color: ${color.espressoDark};
	}
`
const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 4px;
`
const InputWrap = styled.div`
	flex: 1;
	margin-right: 8px;
`
const Icon = styled(TagIcon)`
	margin-right: 8px;
`

const STATUS = {
	loading: 'loading',
	retry: 'retry',
	initial: 'initial',
	applied: 'applied',
}

const PromoCode = ({
	promotionAppliedDesc,
	onTrackEvent,
	setPromotion,
	currencyCode,
	loungeId,
	promoCode,
	totalPrice,
	getFormattedPrice,
}) => {
	const {t} = useTranslation('checkout')
	const [isOpen, setIsOpen] = useState(false)
	const [status, setStatus] = useState(STATUS.initial)
	const [error, setError] = useState(null)
	const {handleSubmit, register, errors, getValues, ...formProps} = useForm()
	const icon = <Icon size={16} />

	React.useEffect(() => {
		if (promoCode) {
			applyPromoCode({promoCode})
		}
	}, [promoCode])

	const clearPromoCode = () => {
		setPromotion(undefined)
		setStatus(STATUS.initial)
	}

	const applyPromoCode = async ({promoCode}) => {
		if (!promoCode || promoCode.trim() === '') {
			return
		}

		setStatus(STATUS.loading)
		setError('')

		const promo = await fetchJson(`/apiService/promotions/${promoCode}?currency=${currencyCode}&loungeId=${loungeId}`)

		if (promo && promo.code) {
			if (promo.minimumSpend > 0 && totalPrice < promo.minimumSpend) {
				setStatus(STATUS.retry)
				setError(t('promoCodeMinSpendError', {value: getFormattedPrice(promo.minimumSpend)}))
			} else {
				setPromotion(promo)
				setStatus(STATUS.applied)

				onTrackEvent(trackConstants.EVENT.applyPromo, {
					value: promo.description,
				})
			}
		} else {
			setStatus(STATUS.retry)
			setError(t('promoCodeError'))
		}
	}

	const _handleSubmit = useCallback(e => {
		// this fixes the submission of the parent form, but doesn't solve nested form issue
		e.stopPropagation()
		handleSubmit(applyPromoCode)(e)
	}, [])

	const inputName = 'promoCode'
	const value = getValues()[inputName]
	const promoError = value ? (error ? {[inputName]: {message: error}} : errors) : {}

	return status === STATUS.applied ? (
		<CodeApplied>
			{icon}
			{promotionAppliedDesc}
			<Close aria-label={t('removePromo')} onClick={clearPromoCode}>
				<PlusCircleIcon size={16} style={{transform: 'rotate(45deg)'}} />
			</Close>
		</CodeApplied>
	) : (
		<>
			<Label
				tabIndex={0}
				htmlFor="promo-code"
				role="button"
				isOpen={isOpen}
				onKeyPress={e => {
					if (e.key === 'Enter') {
						setIsOpen(!isOpen)
					}
				}}
				onClick={() => setIsOpen(!isOpen)}
				aria-expanded={isOpen}
				aria-label={t('enterPromo')}>
				{icon}
				{t('enterPromo')}
			</Label>

			{isOpen && (
				<form onSubmit={_handleSubmit}>
					<Row>
						<InputWrap>
							<Input
								autoFocus
								formRef={register({
									required: t('promoCodeError'),
								})}
								id="promo-code"
								type="text"
								name={inputName}
								loading={status === STATUS.loading}
								disabled={status === STATUS.loading}
								errors={promoError}
								showErrorMsg={false}
								{...formProps}
							/>
						</InputWrap>
						<Button type="submit">
							<>{status === STATUS.retry ? t('retry') : t('apply')}</>
						</Button>
					</Row>
					<Row role="alert">
						{promoError[inputName] && (
							<Text className="true" color={color.red}>
								{promoError[inputName].message}
							</Text>
						)}
					</Row>
				</form>
			)}
		</>
	)
}

PromoCode.propTypes = {
	onTrackEvent: PropTypes.func,
	promotionAppliedDesc: PropTypes.string,
	currencyCode: PropTypes.string,
	loungeId: PropTypes.string,
	setPromotion: PropTypes.func.isRequired,
	promoCode: PropTypes.string,
	totalPrice: PropTypes.number,
	getFormattedPrice: PropTypes.func,
}

export default memo(PromoCode)
