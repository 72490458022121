import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M12 20.041c2.674 0 5.347.282 8.02.845l.73.16v1.189c0 .58-.224 1.14-.625 1.56a.658.658 0 01-.997-.051l-.058-.09-.224-.414a.699.699 0 00-1.221-.016.72.72 0 01-1.19.088l-.06-.088-.105-.183a.686.686 0 00-1.145-.068.78.78 0 01-1.181.08l-.069-.08-.083-.112a.765.765 0 00-1.196-.042.864.864 0 01-1.221-.029.765.765 0 00-1.092-.015l-.075.086-.083.112a.78.78 0 01-1.25 0 .686.686 0 00-1.088-.016l-.057.084-.105.183a.72.72 0 01-1.25 0 .699.699 0 00-1.166-.071l-.055.087-.224.414a.658.658 0 01-1.055.141 2.262 2.262 0 01-.617-1.368l-.008-.192v-1.189A38.964 38.964 0 0112 20.041zm8.75-16.083C21.583 6.043 22 8.723 22 12l-.004.54c-.044 3.032-.459 5.533-1.246 7.501A38.964 38.964 0 0012 19.036c-2.917 0-5.833.335-8.75 1.005-.787-1.968-1.202-4.469-1.246-7.5L2 12c0-3.277.417-5.957 1.25-8.042A38.964 38.964 0 0012 4.964c2.917 0 5.833-.335 8.75-1.006zM13.5 8c-.89 0-1.67.464-2.112 1.162A2.5 2.5 0 1011 13.949l-.001.05a2 2 0 103.569-1.239A2.5 2.5 0 0013.5 8zM19.961.08c.061.032.116.074.164.124.401.42.625.98.625 1.56v1.19A38.964 38.964 0 0112 3.957c-2.917 0-5.833-.335-8.75-1.005V1.765c0-.581.224-1.14.625-1.561A.658.658 0 014.93.346l.224.414a.699.699 0 001.221.015.72.72 0 011.25 0l.105.183a.686.686 0 001.145.069.78.78 0 011.25 0l.083.112a.765.765 0 001.167.07.864.864 0 011.25 0 .765.765 0 001.167-.07l.083-.112a.78.78 0 011.25 0A.686.686 0 0016.27.958l.105-.183a.72.72 0 011.25 0A.699.699 0 0018.846.76l.224-.414a.658.658 0 01.891-.267z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
