import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M11 19.121c.047 0 .094.007.14.02l.084.033 3.895 1.947H22.5a.5.5 0 01.09.992l-.09.008H15l-.076-.007-.03-.004-.034-.008-.039-.014-.045-.02-3.894-1.947H8v1a1 1 0 01-1 1H2a1 1 0 01-1-1v-2h10zm-.283-8.536c1.761 1.104 3.522 1.95 5.283 2.536.516.172 1.328.463 2.172.77l.461.168c1.23.45 2.425.897 2.769 1.026l.098.036a2.5 2.5 0 010 5h-6.381l-3.895-1.947-.084-.033-.07-.015-.07-.005H1c-.667-.666-1-1.666-1-3 0-1 .376-2.19 1.127-3.566l.167-.298a1 1 0 011.518-.261c.875.75 1.938 1.125 3.188 1.125 1.299 0 2.396-.405 3.29-1.215l.188-.18a1 1 0 011.24-.14zm5.783-6c0 1.18.295 2.063.884 2.652.59.59 1.473.884 2.652.884-1.179 0-2.063.295-2.652.884-.59.59-.884 1.473-.884 2.652 0-1.179-.295-2.063-.884-2.652-.59-.59-1.473-.884-2.652-.884 1.179 0 2.063-.294 2.652-.884.59-.589.884-1.473.884-2.651zM20.5 2c0 .707.177 1.237.53 1.591.354.354.884.53 1.591.53-.707 0-1.237.177-1.59.53-.354.354-.531.885-.531 1.592 0-.707-.177-1.238-.53-1.591-.354-.354-.884-.53-1.591-.53.707 0 1.237-.177 1.59-.531.354-.354.531-.884.531-1.591z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
