import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const FeedbackIcon = props => (
	<IconBase viewBox="0 0 16 16" {...props}>
		<path
			d="M.875 11.642a8 8 0 1 1 3.483 3.483L.9 15.671a.5.5 0 0 1-.572-.572l.546-3.457zM5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
			fill={props.color || "#22282B"}
			fillRule="evenodd"
		/>
	</IconBase>
)

export default FeedbackIcon


