import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {color} from './Global/Colors'
import {Title2} from './Global/Typography'
import {maxWidthMobile, POPUP_MAX_CONTENT_WIDTH} from '../constants'
import PlusCircleIcon from './LBIcons/icons/PlusCircle'
import Dialog from '@material-ui/core/Dialog'
import {useMediaQuery} from 'react-responsive'
import {duration} from '@material-ui/core/styles/transitions'
import {useTranslation} from 'react-i18next'

const Title = styled(Title2)`
	padding-right: 24px;
	&:focus {
		outline: 1px solid ${color.blue};
	}
`
const Header = styled.div`
	flex: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 10px 12px 24px;
	background-color: ${color.white};
	box-shadow: 0 1px 0 0 ${color.lightGreyLighter}, 0 4px 8px 0 ${color.lightGreyLighter};
`
const Content = styled.div`
	flex: 1;
	padding: 24px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	&:focus {
		outline: none;
	}
	${p =>
		p.contentStyles &&
		css`
			${p.contentStyles};
		`}
`
const Close = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	padding: 0;
	color: ${color.espressoMedium};
	background: transparent;
	cursor: pointer;
	border: none;
	-webkit-appearance: none;

	&:hover {
		color: ${color.espressoDark};
	}
`

const LBDialog = styled(Dialog)`
	.MuiDialog-paper {
		height: 100vh;
		max-height: 100%;
		border-radius: 0;
		width: 100%;
		max-width: ${POPUP_MAX_CONTENT_WIDTH}px;
		margin: auto;
		background: ${color.white};
		${p =>
			p.popupStyles &&
			css`
				${p.popupStyles};
			`}
	}
`

const Popup = ({title, onClose, children, contentStyles, fixedComponent, open, button, popupStyles}) => {
	const isMobile = useMediaQuery({maxWidth: maxWidthMobile})
	const {t} = useTranslation('common')

	React.useEffect(() => {
		const {top} = document.body.getBoundingClientRect()

		if (open) {
			document.body.style.position = 'fixed'
			document.body.style.top = `${top}px`
		}

		return () => {
			if (document.body.style.position === 'fixed') {
				document.body.style.position = ''
				document.body.style.top = ''
				window.scrollTo(0, parseInt(top || '0') * -1)
			}
		}
	}, [open])

	if (!open) return null

	return (
		<LBDialog
			aria-labelledby="modal-title"
			aria-modal="true"
			transitionDuration={{enter: 0, exit: duration.leavingScreen}}
			fullScreen={isMobile}
			open={open}
			onClose={() => {
				onClose()

				if (button?.current) {
					button.current?.focus()
				} else {
					button?.focus() //for accessibility: set focus on the button when modal closes
				}
			}}
			popupStyles={popupStyles}>
			<Header>
				<Title
					tabIndex={-1}
					ref={el => {
						el && el.focus()
					}}
					id="modal-title">
					{title}
				</Title>
				<Close aria-label={t('closePopup')} onClick={onClose}>
					<PlusCircleIcon role="presentation" size={24} style={{transform: 'rotate(45deg)'}} />
				</Close>
			</Header>
			{fixedComponent}
			<Content tabIndex="0" contentStyles={contentStyles}>
				{children}
			</Content>
		</LBDialog>
	)
}

Popup.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	fixedComponent: PropTypes.element,
	children: PropTypes.element,
	button: PropTypes.element,
	contentStyles: PropTypes.array,
	popupStyles: PropTypes.array,
}

export default React.memo(Popup)
