import {EXTERNAL_DATE_FORMAT, EXTERNAL_TIME_FORMAT, PROMOTION} from '../../util/constants'
import queryString from 'query-string'
import {_, formatPrice} from '../../util/universal'
import {format} from 'date-fns'

export function getPromoAppliedDesc(promotion, currencyCode, locale, t) {
	if (promotion && promotion.code) {
		const {type, scope, code, value, maximumSave} = promotion

		if (maximumSave > 0) {
			// assume only for total
			return t('codeTotalWithMaxSaving', {
				value: type === PROMOTION.types.discount ? value + '%' : formatPrice(value, locale, currencyCode), // default is 'credit'
				maxSave: formatPrice(maximumSave, locale, currencyCode),
			})
		}

		return t(scope === PROMOTION.scopes.person ? 'codePerson' : 'codeTotal', {
			code,
			value: type === PROMOTION.types.discount ? value + '%' : formatPrice(value, locale, currencyCode), // default is 'credit'
		})
	}
}

export const zeroDecimalCurrencies = [
	'BIF',
	'CLP',
	'DJF',
	'GNF',
	'JPY',
	'KMF',
	'KRW',
	'MGA',
	'PYG',
	'RWF',
	'VND',
	'VUV',
	'XAF',
	'XOF',
	'XPF',
]

export function getSearchParamsByState(state, props) {
	const {location} = props
	const {bookingDate, howManyComing, promotion, timeSelected, inventoryId} = state

	// update url with the current booking selection
	const parsedSearch = queryString.parse(location.search)

	return _.pickBy(
		{
			...parsedSearch,
			booking_adults: howManyComing,
			booking_children: undefined,
			promotion: promotion && promotion.code,
			booking_date: format(bookingDate, EXTERNAL_DATE_FORMAT),
			booking_time: timeSelected ? format(bookingDate, EXTERNAL_TIME_FORMAT) : undefined,
			inventoryId,
		},
		Boolean
	)
}
