import styled, {css} from 'styled-components'
import {color} from './Colors'

// TODO: Remove all "!important" from links once
// LESS and Bootstrap will be removed/cleaned

const font = {
	avenir: "'Avenir Next', 'Avenir Next Local', sans-serif",
}
export const fontWeight = {
	normal: 400,
	medium: 500,
	bold: 600,
}

export const LinkStyles = css`
	color: ${p => (p.linkColor ? p.linkColor : color.blue)} !important;
	text-decoration: none;
	background: none;
	cursor: pointer;

	&:focus {
		outline: 1px solid ${color.blue};
	}

	&:hover,
	&:active {
		color: ${p => (p.linkColor ? p.linkColor : color.blue)} !important;
		text-decoration: underline;
		background: none;
	}
`
export const LinkWrap = styled.span`
	a {
		${LinkStyles};
	}
`

const BodyFontSize = css`
	font-size: 16px;
	line-height: 24px;
`
const MinorFontSize = css`
	font-size: 13px;
	line-height: 20px;
`
const CaptionFontSize = css`
	font-size: 11px;
	line-height: 20px;
`

const HeadingDefault = css`
	margin: 0;
	font-family: ${font.avenir};
	font-weight: ${fontWeight.bold};
	color: ${color.espressoMedium};
	letter-spacing: -0.2px;
	word-spacing: 0;
	text-transform: none;
`
const BodyStyles = css`
	font-family: ${font.avenir};
	font-weight: ${fontWeight.normal};
	color: ${color.espressoMedium};
	letter-spacing: -0.1px;
	word-spacing: initial;
`
const BodyDefault = styled.span`
	display: inline-block;
	${BodyStyles};

	${p =>
		p.color &&
		css`
			color: ${p.color};
		`}

	${p =>
		p.fontWeight === 'normal' &&
		css`
			font-weight: ${fontWeight.normal};
		`}
	${p =>
		p.fontWeight === 'medium' &&
		css`
			font-weight: ${fontWeight.medium};
		`}
	${p =>
		p.fontWeight === 'bold' &&
		css`
			font-weight: ${fontWeight.bold};
		`}

	a {
		${LinkStyles};
	}
`

export const TitleLargeStyles = css`
	${HeadingDefault};
	font-size: 32px;
	line-height: 42px;
`
export const Title1Styles = css`
	${HeadingDefault};
	font-size: 24px;
	line-height: 30px;
`
export const Title2Styles = css`
	${HeadingDefault};
	font-size: 20px;
	line-height: 30px;
`
export const Title3Styles = css`
	${HeadingDefault};
	${BodyFontSize};
`
export const Title1 = styled.h1`
	${Title1Styles};
`
export const Title2 = styled.h2`
	${Title2Styles};
`
export const Title3 = styled.h3`
	${Title3Styles};
`

export const TitleMobileStyles = css`
	${Title3Styles}
	color: ${color.espressoDark};
	height: 24px;	
	font-family: AvenirNext-DemiBold;	
	letter-spacing: -0.11px;
`

export const BodyTextStyles = css`
	${BodyStyles};
	${BodyFontSize};
`
export const BodyText = styled(BodyDefault)`
	${BodyFontSize};
`
export const MinorTextStyles = css`
	${BodyStyles};
	${MinorFontSize};
`
export const MinorText = styled(BodyDefault)`
	${MinorFontSize};
`
export const CaptionStyles = css`
	${BodyStyles};
	${CaptionFontSize};
`
export const Caption = styled(BodyDefault)`
	${CaptionFontSize};
`

export const ColoredLinks = css`
	a {
		text-decoration: underline;
	}
	a,
	a:hover,
	a:active,
	a:focus {
		color: inherit;
		cursor: pointer;
	}
	a:hover,
	a:active {
		text-decoration: none;
	}
`
