import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const MiniCaretDownIcon = props => (
	<IconBase viewBox="0 0 16 16" {...props}>
		<path
			d="M12 6l-4 4-4-4"
			stroke={props.color || "#52565C"}
			strokeWidth={1.5}
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconBase>
)

export default MiniCaretDownIcon
