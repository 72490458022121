import config from './config'
import {enUS, enAU, enGB, de, fr, es} from 'date-fns/locale'
import {encodeStr} from '../util/universal'
import {GDPRSet} from '../util/constants'

const locales = {
	en: {
		name: 'English (US)',
		host: 'loungebuddy.com',
		countryCode: 'US',
		firstDayOfWeek: 0,
		dateFormat: enUS,
	},
	'en-GB': {
		name: 'English (UK)',
		host: 'loungebuddy.co.uk',
		countryCode: 'GB',
		firstDayOfWeek: 0,
		dateFormat: enGB,
	},
	'en-AU': {
		name: 'English (AU)',
		host: 'loungebuddy.com.au',
		countryCode: 'AU',
		firstDayOfWeek: 0,
		dateFormat: enAU,
	},
	'de-DE': {
		name: 'Deutsch (DE)',
		host: 'loungebuddy.de',
		countryCode: 'DE',
		firstDayOfWeek: 1,
		dateFormat: de,
	},
	'fr-FR': {
		name: 'Français (FR)',
		host: 'loungebuddy.fr',
		countryCode: 'FR',
		firstDayOfWeek: 1,
		dateFormat: fr,
	},
	'es-MX': {
		name: 'Español (MX)',
		host: 'loungebuddy.mx',
		countryCode: 'MX',
		firstDayOfWeek: 1,
		dateFormat: es,
	},
}

const supportedLocales = Object.keys(locales)
const countryToLocaleMapping = (function() {
	const mapping = {}

	// default GDPR countries to UK
	GDPRSet.forEach(countryCode => {
		mapping[countryCode] = 'en-GB'
	})

	supportedLocales.forEach(locale => {
		mapping[locales[locale].countryCode] = locale
	})
	return mapping
})()

function _hostToLocaleLookup(host) {
	for (let i = 0; i < supportedLocales.length; i++) {
		const supportedHost = locales[supportedLocales[i]].host
		const rootDomain = host.slice(-supportedHost.length)
		if (rootDomain === supportedHost) {
			return supportedLocales[i]
		}
	}
	return 'en' //default
}

function _buildHreflangList(path) {
	return supportedLocales.map(loc => ({href: `https://www.${locales[loc].host}${encodeStr(path)}`, hreflang: loc}))
}

module.exports = Object.assign(
	{
		list: supportedLocales,
		countryToLocale: country => countryToLocaleMapping[country] || 'en',
		hostToLocale: host => _hostToLocaleLookup(host) || 'en',
		buildHreflangList: _buildHreflangList,
		initConfig: Object.assign(config, {
			preload: supportedLocales,
			debug: false,
		}),
	},
	locales
)
