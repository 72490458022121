export const phoneScreen = 'only screen and (max-width:768px)'

export const lbLinkComponentTypes = {
	ANCHOR: 'a',
	LINK: 'Link',
}

export const transitionStates = {
	ENTERING: 'entering',
	ENTERED: 'entered',
	EXITING: 'exiting',
	EXITED: 'exited',
}

export const headerMaxWidth = 1032
export const contentMaxWidth = 1016
export const headerPadding = 24
export const headerTabletPadding = 16
export const contentPadding = 24

const defaultPixelSize = 16
export const maxWidthSmallMobile = 374
export const maxWidthMobile = 580
export const maxWidthMobileOld = 768 // Old breakpoint used in CSS
export const minWidthTablet = maxWidthMobile + 1
export const maxWidthTablet = 958
export const minWidthDesktop = maxWidthTablet + 1
export const landscape = 'landscape'

export const breakpoints = {
	mobile_small: `max-width: ${maxWidthSmallMobile / defaultPixelSize}em`,
	mobile: `max-width: ${maxWidthMobile / defaultPixelSize}em`,
	mobile_old: `max-width: ${maxWidthMobileOld / defaultPixelSize}em`,
	min_tablet: `min-width: ${minWidthTablet / defaultPixelSize}em`,
	tablet: `max-width: ${maxWidthTablet / defaultPixelSize}em`,
	desktop: `min-width: ${minWidthDesktop / defaultPixelSize}em`,
}

export const devices = {
	TABLET: 'tablet',
	PHONE: 'phone',
	DESKTOP: 'desktop',
}

export const defaultWidths = {
	[devices.TABLET]: minWidthTablet,
	[devices.PHONE]: maxWidthMobile,
	[devices.DESKTOP]: minWidthDesktop,
}

export const loungeAccessTypes = {
	PURCHASABLE: 1,
	WALKINS_ACCEPTED: 2,
	ACCESS_UNKNOWN: 3,
}

export const loungeAccessTypeNameMapForTracking = {
	[loungeAccessTypes.PURCHASABLE]: 'Purchasable lounge',
	[loungeAccessTypes.WALKINS_ACCEPTED]: 'Walkin lounge',
	[loungeAccessTypes.ACCESS_UNKNOWN]: 'Access unknown lounge',
}

export const loungeAccessTypeIcons = {
	LB_NETWORK: '/images/access-icons/lbnetwork@2x.png',
	WALKINS: '/images/access-icons/walkin@2x.png',
}

export const mapPinIcon = '/images/lounge-detail/map-pin@2x.png'

export const escKeyCode = 27
export const scrollBarWidth = 15

export const ccBrandNameTypes = {
	AMEX: 'American Express',
}

export const ccBrandShortNameTypes = {
	AMEX: 'amex',
	UNKNOWN: 'unknown',
}

export const maximumInventoryNumber = 5

export const PRICING_BREAKDOWN_POPUP_STYLES = {padding: 0}

export const POPUP_MAX_CONTENT_WIDTH = 680

export const loungeTypes = {
	BOOKABLE: 'BOOKABLE',
	TRADITIONAL: 'TRADITIONAL',
}
