import React from 'react'
import colors from './global/Colors'
import ButtonBase from '@material-ui/core/ButtonBase'
import styled, {css, ThemeProvider} from 'styled-components'
import PropTypes from 'prop-types'
import { MinorTextStyles, fontWeight, BodyTextStyles } from './global/Typography';

export const buttonHeight = {
  small: 40,
  medium: 48,
  big: 50,
}

export const buttonTheme = {
  normal: {
    color: colors.espressoMedium,
    bgColor: colors.white,
    bgColorHover: colors.lightGreyLighter,
    bgGradient: colors.gradientSilver,
    bgGradientHover: 'none',
    borderColor: colors.lightGrey,
  },
  action: {
    color: colors.white,
    bgColor: colors.blue,
    bgColorHover: colors.blue,
    bgGradient: colors.gradientBlue,
    bgGradientHover: 'none',
    borderColor: colors.blue,
  },
  airport: {
    color: colors.espresso,
    bgColor: colors.lightGreyLighter,
    bgColorHover: colors.lightGreyLighter,
    bgGradient: 'none',
    bgGradientHover: 'none',
    borderColor: '#f3f4f4',
  },
}

const TypoSmallButtons = css`
	font-size: 13px;
	${MinorTextStyles};
	color: ${p => p.theme.color};
	font-weight: ${fontWeight.medium};
`

const Styles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 0;
  height: ${buttonHeight.big}px;
  ${BodyTextStyles};
  background-color: ${p => p.theme.bgColor};
  background-image: ${p => p.theme.bgGradient};
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid ${p => p.theme.borderColor};
  border-radius: 5px;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  color: ${p => p.theme.color};
  appearance: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  transition: none;

  ${p => p.fontWeight && css`
    font-weight: ${p => p.fontWeight};
  `}

  ${p => p.fullWidth && css`
    width: 100%;
  `}

  ${p => p.small && css`
    height: ${buttonHeight.small}px;
    ${TypoSmallButtons};
  `}

  ${p => p.height && css`
    height: ${p => p.height}px;
  `}

  &:hover {
    background-color: ${p => p.theme.bgColorHover};
    background-image: ${p => p.theme.bgGradientHover};
  }
  &:disabled {
    background: ${colors.buttonDisabled};
    color: ${colors.espressoLight};
    border-color: ${colors.buttonDisabled};
  }
`;

const StyledButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    ${Styles};
  }
  &.Mui-focusVisible {
    box-shadow: 0 0 0 2px ${colors.blue};
    z-index: 2;
  }
`; // z-index: 2, conflict when buttons are next to each other, https://github.com/LoungeBuddy/lbds/pull/54#discussion_r596363852

const StyledSpan = styled.span`
  ${Styles};
  user-select: none;
`;

const Inner = styled.span`
  padding: 0 24px;
  text-decoration: none;
`;

StyledButton.propTypes = {
  children: PropTypes.any.isRequired,
  theme: PropTypes.object.isRequired,
  stylesOnly: PropTypes.bool,
  height: PropTypes.number,
  labelStyle: PropTypes.object,
  fontWeight: PropTypes.number,
}

const LBButton = ({ children, theme = buttonTheme.action, labelStyle, stylesOnly, ...props }, ref) => {
  const btnRef = React.useRef(null)
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      btnRef.current.focusVisible ? btnRef.current.focusVisible(): btnRef.current.focus()
    }
  }));
  return (
    <ThemeProvider theme={theme}>
      {stylesOnly ? (
        <StyledSpan ref={btnRef} tabIndex={0} {...props}>
          <Inner style={labelStyle}>{children}</Inner>
        </StyledSpan>
      ):(
        <StyledButton action={btnRef} {...props} disableRipple>
          <Inner style={labelStyle}>{children}</Inner>
        </StyledButton>
      )}
    </ThemeProvider>
  )
}

export default React.forwardRef(LBButton)