import React from 'react'
import { connect } from 'react-redux'
import LBLink from './component/LBLink'
import {encodeStr, fetchJson} from '../util/universal'
import { compose } from 'recompose'
import Helmet from 'react-helmet'
import loadData from './component/loadData'
import { withTranslation } from 'react-i18next';

class Airports extends React.Component {

	render() {
		const { airports } = this.props

		const showAirportItem = (airport) => (
			<LBLink key={airport.id} className="list-group-item" to={`/${airport.IATA}`}>
				<h3 className="list-group-item-heading">{airport.id}</h3>
				<span className="list-group-item-text">
					{airport.name}
				</span>
			</LBLink>
		)

		return (
			<div>
				<Helmet
					title="Airports"
					meta={[{ name: "description", content: 'LoungeBuddy Airport Listing' }]}
					link={[
						{ rel: "canonical", href: this.props.hostUrl + encodeStr(this.props.location.pathname) }
					]}
				/>
				<div className="page-header">
					<div className="container"><h1>Airports</h1></div>
				</div>
				<div className="container">
					<div>&nbsp;</div>
					<div className="list-group">
						{airports.map(showAirportItem)}
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { airports, initialI18nStore, initialLanguage, hostUrl, error } = state;
	return { airports, initialI18nStore, initialLanguage, hostUrl, error }
}

export default compose(
	connect(mapStateToProps),
	withTranslation('airports'),
	loadData({
		updateData: () => fetchJson('/api/airports'),
		dataLoaded: ({ airports }) => {
			// console.log('data loaded', airports)
			return airports //todo: paging and infinite scroll
		}
	})
)(Airports)
