import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M22.5 19a1.5 1.5 0 010 3h-21a1.5 1.5 0 010-3h21zm-7.285-7.659c3.56.546 3.772 2.215 3.784 4.98l.001.354v.182h-.01A1 1 0 0118 18H6a1 1 0 01-.99-1.143H5v-.537c.013-2.764.225-4.433 3.785-4.979a5.898 5.898 0 003.215.945 5.896 5.896 0 003.064-.851l.15-.094zM2.944 9.185A2.99 2.99 0 005 10c.717 0 1.376-.252 1.892-.671.22.393.488.76.795 1.09-2.284.584-3.153 1.782-3.484 3.582L2 14a1 1 0 01-1-1v-.346c.006-1.88.11-3.043 1.944-3.47zm18.112 0c1.877.436 1.942 1.644 1.944 3.602V13a1 1 0 01-1 1l-2.203.001c-.331-1.8-1.2-2.998-3.484-3.583.307-.33.575-.695.797-1.09A2.98 2.98 0 0019 10c.77 0 1.47-.29 2.002-.766l.054-.05zM12 2c2.577 0 4.667 2.047 4.667 4.571 0 2.525-2.09 4.572-4.667 4.572S7.333 9.096 7.333 6.57C7.333 4.047 9.423 2 12 2zm1.157 5.87a1.77 1.77 0 01-1.153.417 1.79 1.79 0 01-1.152-.418.571.571 0 00-.737.874 2.912 2.912 0 001.89.687 2.934 2.934 0 001.888-.687.571.571 0 00-.736-.874zM19 5a2 2 0 11-1.46 3.366 5.63 5.63 0 00.184-2.904C18.068 5.173 18.513 5 19 5zM5 5c.486 0 .931.173 1.278.462a5.603 5.603 0 00.182 2.904A2 2 0 115 5z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
