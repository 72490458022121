// for datadog, use git commit hash if possible
export const dd = {
	version: process.env.npm_package_version,
	env: process.env.DD_ENV || process.env.NODE_ENV || 'local',
	clientToken: process.env.DD_CLIENT_TOKEN,
	applicationId: process.env.DD_APP_ID,
	serviceName: process.env.DD_SERVICE || 'lbhuntsman',
}

export const data = {
	txtToValidateSecretsAreUpdating: process.env.HEROKU_SLUG_DESCRIPTION,
}
