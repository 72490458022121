import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M14.036 3.95L19.164 11h.99c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v4.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 01-1.135 1.134c-.489.262-.974.401-2.31.401H3.844c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 01.4 21.464C.139 20.976 0 20.491 0 19.155v-4.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 011.536 11.4c.489-.262.974-.401 2.31-.401h.99l5.129-7.05c.197.276.448.51.738.687L6.072 11h11.855l-4.628-6.364c.29-.176.54-.41.737-.685zm6.007 11.84c-.823 0-1.524.48-1.524 1.52v.222c0 1.04.65 1.53 1.565 1.53 1.04 0 1.441-.657 1.45-1.123h-.999c-.009.2-.208.32-.454.32-.25 0-.475-.167-.475-.478v-.137h1.943v-.334c0-.95-.613-1.52-1.506-1.52zm-5.54.017c-1.088 0-1.387.577-1.401 1.072h1.043c.009-.164.132-.284.343-.284.202 0 .333.105.333.296v.24h-.69c-.736 0-1.14.372-1.14.958 0 .606.427.955 1.042.955.466 0 .704-.217.788-.416h.056V19h1.043v-2.068c0-.835-.63-1.125-1.418-1.125zm3.174-.665h-1.075v.773h-.51v.823h.477v1.36c0 .673.396.905 1.228.905.266 0 .486-.026.565-.041v-.782c-.061.006-.17.008-.299.008-.246 0-.386-.093-.386-.328v-1.122h.682v-.823h-.682v-.773zM4.212 15H2.425V19h1.154v-1.125h.618c.99 0 1.468-.65 1.468-1.427 0-.761-.466-1.447-1.453-1.447zm3.573.806a.714.714 0 00-.694.547h-.056v-.503h-1.12V19h1.158v-1.597c0-.422.193-.674.648-.674.158 0 .313.015.383.047v-.937c-.058-.024-.158-.032-.319-.032zm1.686.044H8.355V19h1.116v-3.15zm1.435 0H9.699l.96 3.149h1.307l1.052-3.15h-1.172l-.486 2.183h-.047l-.407-2.182zm3.915 1.84v.29c0 .19-.187.313-.383.313-.182 0-.355-.106-.355-.302 0-.181.15-.302.387-.302h.351zm5.23-1.128c.264 0 .431.196.431.486v.003h-.876v-.003c0-.29.185-.486.446-.486zm-16.14-.7c.354 0 .585.204.585.585 0 .375-.231.586-.586.586h-.334v-1.172h.334zm5-1.401c-.389 0-.638.202-.638.518 0 .314.252.516.639.516.39 0 .638-.202.638-.516 0-.313-.252-.518-.638-.518zM12 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
