import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M18 0c1 2.448 1.5 3.948 1.5 4.5a1.5 1.5 0 01-1 1.414v2.453l.053-.014a21.91 21.91 0 003.315-1.235A1.5 1.5 0 0124 8.478V22a2 2 0 01-2 2h-5a2 2 0 00-2-2l-.164.002.045-.045c1.66-1.66 2.41-3.907 2.257-6.675a.848.848 0 00-.8-.8c-1.632-.09-3.083.133-4.339.672L12 11.056a2 2 0 011.95-2 18.46 18.46 0 003.566-.441.39.39 0 01-.016-.115V5.914a1.5 1.5 0 01-1-1.414c0-.552.5-2.052 1.5-4.5zm-3 23a1 1 0 011 1H0a1 1 0 011-1h14zM4.713 16.194c-.1.486-.15.982-.15 1.489 0 1.542.457 2.985 1.356 4.317l-2.947.001a6.687 6.687 0 01-.746-.644c-1.48-1.48-2.15-3.49-2.009-6.028 1.75-.097 3.249.192 4.496.865zM8.253 12c1.307 1.17 2.163 2.433 2.568 3.791-.413.272-.8.589-1.158.947a7.451 7.451 0 00-1.856 3.075c-.11.345-.197.704-.264 1.075l-.002.009-.007.043-.005.027-.013.08-.01.056-.006.048-.004.02-.003.023-.022.162-.006.052-.005.037-.012.108-.002.015-.004.045-.015.156-.001.018-.001.008-.01.122L7.41 22h-.442c-1.036-1.33-1.555-2.77-1.555-4.317 0-.285.018-.567.053-.845l.014-.1c.245-1.721 1.17-3.3 2.775-4.738zm7.505 3.311a11.485 11.485 0 01.533.018c.14 2.539-.53 4.548-2.01 6.028l-.106.104-.035.032-.018.018-.02.018-.027.024-.003.003-.114.103-.124.107a6.619 6.619 0 01-.094.077l-.146.114-.057.043H8.26c.132-1.904.8-3.458 2.003-4.662a6.566 6.566 0 011.734-1.249h.001l.027-.013.04-.021.15-.07.089-.038a6.849 6.849 0 01.927-.328 7.632 7.632 0 01.976-.206 8.808 8.808 0 011.019-.094c.06-.003.121-.004.183-.006l.133-.002h.216z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
