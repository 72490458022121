import {css} from 'styled-components'

const defaultPixelSize = 16
export const maxWidthSmallMobile = 368
export const maxWidthMobile = 580
export const minWidthTablet = maxWidthMobile + 1
export const maxWidthTablet = 958
export const minWidthDesktop = maxWidthTablet + 1

const mediaWrapper = mediaSizeQuery => (...args) => css`
  @media (${mediaSizeQuery}) {
    ${css(...args)};
  }
`

// iterate through the sizes and create a media template
// use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
export const media = {
  mobile_small: mediaWrapper(
    `max-width: ${maxWidthSmallMobile / defaultPixelSize}em`
  ),
  mobile: mediaWrapper(`max-width: ${maxWidthMobile / defaultPixelSize}em`),
  min_tablet: mediaWrapper(`min-width: ${minWidthTablet / defaultPixelSize}em`),
  tablet: mediaWrapper(`max-width: ${maxWidthTablet / defaultPixelSize}em`),
  desktop: mediaWrapper(`min-width: ${minWidthDesktop / defaultPixelSize}em`),
}
