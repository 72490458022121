import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import useIframeResizer from '../hooks/useIframeResizer'
import {LBPropTypes} from '../utils'
import usePageview from '../hooks/usePageview'

function Locations({location, nextjsUrl, initialLanguage, activeCurrency}) {
	const {t} = useTranslation('translation')
	usePageview()

	const iframeUrl = new URL(`/${initialLanguage}${location.pathname}${location.search}`, nextjsUrl)

	const iframeElement = useIframeResizer({location, iframeUrl, qeId: 'locations', currency: activeCurrency.code})

	return (
		<div>
			<Helmet
				title={t('selectLocations.title')}
				bodyAttributes={{
					class: `page-spa page-spa--locations`,
				}}
			/>

			<div>{iframeElement}</div>
		</div>
	)
}

Locations.propTypes = {
	location: LBPropTypes.location,
	history: PropTypes.shape({
		replace: PropTypes.func,
	}),
	t: PropTypes.func,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	const {
		initialI18nStore,
		initialLanguage,
		hostUrl,
		error,
		locale,
		locations,
		groupedAllAirports,
		purchasableLoungeCountPerAirport,
		nextjsUrl,
		activeCurrency,
	} = state
	return {
		initialI18nStore,
		initialLanguage,
		hostUrl,
		error,
		locale,
		locations,
		groupedAllAirports,
		purchasableLoungeCountPerAirport,
		nextjsUrl,
		activeCurrency,
	}
}

export default compose(connect(mapStateToProps))(Locations)
