import React, {useState} from 'react'
import {CardElement} from 'react-stripe-elements'
import {ccBrandShortNameTypes} from '../constants'
import {Text} from '../component/Input'
import {color} from '../component/Global/Colors'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

const AmexCardNumberElement = ({onTracking, ...rest}) => {
	const [validBrand, setValidBrand] = useState(true)
	const [error, setError] = useState(null)
	const {t} = useTranslation('checkout')

	/*
	arg
	{
		brand: "visa"
		complete: false
		elementType: "card"
		empty: false
		error: undefined
		value: {postalCode: ""}
	}
	 */
	const handleChange = ({brand, complete, value, error}) => {
		// Only when a customer is entering or entered AMEX card number OR is entering a card number which is not determined its brand yet
		const valid = brand === ccBrandShortNameTypes.AMEX || (brand === ccBrandShortNameTypes.UNKNOWN && !complete)

		if (value.postalCode) onTracking({key: 'postalCode'})

		if (valid !== validBrand) setValidBrand(valid)
		setError(error)
		if (error) console.error('stripe client side', error)
	}

	return (
		<>
			<CardElement {...rest} hideIcon onChange={handleChange} className={validBrand ? '' : 'invalid-brand'} />
			{(error || !validBrand) && <Text color={color.red}>{validBrand ? error.message : t('cardAcceptanceNote')}</Text>}
		</>
	)
}

AmexCardNumberElement.propTypes = {
	onTracking: PropTypes.func,
}

export default AmexCardNumberElement
