import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M9.987 2.999L18.872 3c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v10.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C.186 21.302 0 20.655 0 18.872V8.128c0-1.783.186-2.43.534-3.082A3.632 3.632 0 012 3.56L2 18.49c0 .91.103 1.43.385 1.958.268.5.666.9 1.167 1.167.528.282 1.047.385 1.957.385h.982c.91 0 1.429-.103 1.957-.385.5-.268.9-.666 1.167-1.167.282-.528.385-1.047.385-1.957V3.509c0-.185-.004-.354-.013-.51zM17 19a1 1 0 100 2 1 1 0 000-2zM6.49 0c.827 0 1.16.097 1.487.28.326.184.582.452.756.794.174.343.267.693.267 1.56v15.731c0 .868-.093 1.218-.267 1.56-.174.343-.43.611-.756.795-.326.183-.66.28-1.486.28h-.982c-.826 0-1.16-.097-1.486-.28a1.865 1.865 0 01-.756-.794c-.16-.314-.251-.635-.265-1.354L3 2.635c0-.868.093-1.218.267-1.56.174-.343.43-.611.756-.795.299-.167.605-.263 1.29-.278L6.49 0zM14 16a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zm-6-3a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zm-6-3a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zm.5-4h-7a.5.5 0 00-.492.41L13 6.5v2a.5.5 0 00.41.492L13.5 9h7a.5.5 0 00.492-.41L21 8.5v-2a.5.5 0 00-.5-.5z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
