import React, {useState, useEffect, memo} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Title2, BodyText, MinorText} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import ProgressBar from '../component/ProgressBar'
import {useTranslation} from 'react-i18next'
import {safeInterval, noop} from '../../util/universal'
import {useMediaQuery} from 'react-responsive'
import {maxWidthMobile, landscape} from '../constants'
import PlaneAnimation from './PlaneAnimation'
import {format, set} from 'date-fns'
import Dialog from '@material-ui/core/Dialog/Dialog'

const Text = styled.div`
	padding: 24px;
	margin: auto;
	width: 100%;
	max-width: 358px;
	text-align: center;
`
const Time = styled(MinorText)`
	margin-top: 16px;
	color: ${color.espressoLight};
`

let stopCounter = noop // this stops the interval

const bufferTime = 30000

const ProcessDialog = styled(Dialog)`
	.MuiDialog-paper {
		width: 100%;
		max-width: 616px;
		height: auto;
		margin: auto;
		margin: 0 24px;
		background: ${color.white};
		border-radius: 8px;
		overflow: hidden;
	}
`

//todo: consider refactor progress logic after checkout component is migrated to Hooks

const ProcessingModal = ({visible, time, percentage}) => {
	const totalTime = time + bufferTime
	const {t} = useTranslation('checkout')
	const [countdown, setCountdown] = useState(totalTime)
	const [progress, setProgress] = useState(percentage)

	const isMobileLandscape = useMediaQuery({
		maxHeight: maxWidthMobile,
		orientation: landscape,
	})

	useEffect(() => {
		//don't start the timer until it's visible

		if (!visible) {
			stopCounter()

			// reset progress and counter when not visible
			setProgress(0)
			setCountdown(totalTime)
			return
		}

		const then = Date.now()
		stopCounter = safeInterval(() => {
			const elapsed = Date.now() - then
			setCountdown(Math.max(0, totalTime - elapsed))

			const timeProgress = Math.min(90, (elapsed * 100) / totalTime)
			if (progress < timeProgress) {
				// don't overwrite further progress
				setProgress(timeProgress)
			}
		}, 1000)
	}, [visible, totalTime])

	// parent could move progress forward, but not backwards
	if (visible && percentage > progress) {
		setProgress(percentage)
	}

	return (
		<ProcessDialog open={visible}>
			<div>
				{!isMobileLandscape && (
					<>
						<PlaneAnimation />
						<ProgressBar percentage={progress} />
					</>
				)}
				<Text>
					<Title2>{t('processingModalTitle')}</Title2>
					<BodyText>{t('processingModalDescriptions')}</BodyText>
					{totalTime && (
						<Time
							dangerouslySetInnerHTML={{
								__html: t('waitUpTo', {
									time: format(set(new Date(), {minutes: 0, seconds: 0, milliseconds: countdown}), 'mm:ss'),
								}),
							}}
						/>
					)}
				</Text>
			</div>
		</ProcessDialog>
	)
}

ProcessingModal.propTypes = {
	visible: PropTypes.bool,
	time: PropTypes.number,
	percentage: PropTypes.number,
}

export default memo(ProcessingModal)
