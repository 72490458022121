import colors from './components/global/Colors'
import { fontWeight } from './components/global/Typography'
import LBButton, {buttonTheme} from './components/LBButton'
import Header from './containers/huntsman/Header'
import SearchBar from './containers/huntsman/SearchBar'
import {buildMenuItems} from './shared/MenuData'

const Huntsman = {
  themes: {buttonTheme},
  components: {Header, SearchBar},
  utils: {buildMenuItems},
}

const core = {
  components: {LBButton},
  colors,
  typography: {fontWeight},
}

export {
  core,
  Huntsman
}
