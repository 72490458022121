import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {fetchJson} from '../../util/universal'
import {LBPropTypes, openChat} from '../utils'
import {useTranslation} from 'react-i18next'
import {Flexbox, FlexStrong, FlexInput} from './styles'
import PropTypes from 'prop-types'
import {Huntsman} from '@loungebuddy/lbds'
import Button from '../component/Button'
const {buttonTheme} = Huntsman.themes

const CancelForm = ({bookingId, bookingEmail, onSent, onCancel, lounge}) => {
	const [processing, setProcessing] = useState(false)
	const [err, setErr] = useState('')
	const {t} = useTranslation('booking')
	const {register, handleSubmit, ...formProps} = useForm({
		mode: 'onBlur',
	})

	const _cancel = e => {
		e.preventDefault()
		onCancel()
	}

	const _cancelBooking = async () => {
		setProcessing(true)
		try {
			await fetchJson(`/apiService/bookings/${bookingId}/cancel`, {
				body: {bookingEmail},
			})
			if (typeof onSent === 'function') onSent()
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Cancel Booking Error', err)
			setErr(err)
		}
	}

	return (
		<form onSubmit={handleSubmit(_cancelBooking)} className="lb-modal--confirmation lb-modal--cancel-booking lb-style ">
			<div style={{width: 390}}>
				<div className="modal-body">
					{!err && (
						<div className="lb-alert lb-alert--error">
							<span className="msg">
								<i className="fa fa-exclamation-triangle" aria-hidden="true" />
								{t('noUndone')}
							</span>
						</div>
					)}
					{err && (
						<div className="lb-alert lb-alert--error">
							<span className="msg">
								<i className="fa fa-exclamation-triangle" aria-hidden="true" />
								{' ' + t('errorOccurred') + ' '}
								<a href="#" style={{cursor: 'pointer'}} onClick={openChat}>
									{t('contactSupportLowercase')}
								</a>
							</span>
						</div>
					)}
					<p
						className="instruction"
						dangerouslySetInnerHTML={{
							__html: t('enterAirport', {
								IATA: lounge.airport.IATA,
								lounge: lounge.name,
							}),
						}}
					/>
				</div>

				<Flexbox>
					<FlexInput
						formRef={register({
							required: t('required'),
							validate: code => lounge.airport.IATA.toLowerCase() === code.toLowerCase() || t('invalidCode'),
						})}
						title={t('airportCode')}
						name="code"
						placeholder={t('airportCode')}
						{...formProps}
					/>
					{processing ? (
						<FlexStrong>{t('processing')}</FlexStrong>
					) : (
						<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
							<Button type="submit" fullWidth style={{marginRight: '8px'}}>
								{t('cancelReservation')}
							</Button>
							<Button onClick={_cancel} fullWidth theme={buttonTheme.normal} style={{marginLeft: '8px'}}>
								{t('neverMind')}
							</Button>
						</div>
					)}
				</Flexbox>
			</div>
		</form>
	)
}

CancelForm.propTypes = {
	lounge: LBPropTypes.lounge,
	bookingId: PropTypes.string,
	bookingEmail: PropTypes.string,
	onSent: PropTypes.func,
	onCancel: PropTypes.func,
}

export default CancelForm
