import React from 'react'
import { getOffset } from "../utils";
import { animateScroll } from 'react-scroll'

class ScrollLink extends React.Component {

	state = {
		active: false
	}

	componentDidMount() {
		const { to, end } = this.props
		this.toElement = document.querySelector(`[data-anchor=${to}]`)
		this.endElement = document.querySelector(`[data-anchor=${end}]`)
		this.height = this.element.parentNode.scrollHeight //todo: turn into props?

		window.addEventListener('scroll', this.checkScroll);
		window.addEventListener('resize', this.checkScroll);
		this.checkScroll()
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.checkScroll);
		window.removeEventListener('resize', this.checkScroll);
	}

	checkScroll = () => {
		const cutoff = this.height + 20
		const startPos = this.toElement.getBoundingClientRect().top
		const endPos = this.endElement ? this.endElement.getBoundingClientRect().top : cutoff + 1

		this.setState({ active: startPos <= cutoff && endPos > cutoff })
	}

	_scrollToElement = (e) => {
		e.preventDefault()
		const pos = getOffset(this.toElement)
		animateScroll.scrollTo(pos.top - this.height)
	}

	render() {
		const {
			activeStyle = { fontWeight: 'bold' }
		} = this.props

		const style = { cursor: 'pointer', ...(this.state.active ? activeStyle : {}) }

		return (
			<a href="#" style={style} onClick={this._scrollToElement} ref={(divElement) => this.element = divElement}>
				{this.props.children}
			</a>
		)
	}
}

export default ScrollLink
