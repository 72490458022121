import queryString from 'query-string'
import {getURLParameter, transformCloudinaryImage, _} from '../util/universal'
import {devices, loungeAccessTypes} from './constants'
import PropTypes from 'prop-types'
const config = require('../common/publishableConfig')()

/**
 * quick check for device type (mobile-detect is too expensive)
 * use tablet as the separation (base on screen size data from GA for 2019)
 * TabletMin in Portrait > 534x854
 * TabletMax in Portrait < 768x1024
 */
export function getScreenInfo(w = window.innerWidth, h = window.innerHeight) {
	const portrait = w < h

	//exceptions for common devices
	const UA = window.navigator.userAgent
	if (/\b(iPad)\b/i.test(UA)) {
		// ipad pro is 1024x1366
		return {
			device: devices.TABLET,
			portrait,
		}
	} else if (/\b(BlackBerry|webOS|iPhone|IEMobile|Android)\b/i.test(UA)) {
		return {
			device: devices.PHONE,
			portrait,
		}
	}

	return {
		device: !portrait && w > 768 ? devices.DESKTOP : (portrait ? w : h) < 534 ? devices.PHONE : devices.TABLET,
		portrait,
	}
}

export const isClient = typeof window !== 'undefined'

export const tPluralContext = count => ({
	count,
	context: count ? 'many' : 'zero',
})

export const setSearchbar = airport => {
	if (!airport) return

	document.querySelector('header .search-form .input-airport-search').value = `${airport.IATA} - ${airport.name}`
	document.querySelector('header .search-form').setAttribute('data-selectedairport', airport.IATA)
}

export function openChat(e) {
	e && e.preventDefault()

	if (typeof window.Kustomer.open === 'function') {
		window.Kustomer.open()
	} else {
		// eslint-disable-next-line no-console
		console.error('support chat failed to load')
	}
}

export function getOffset(el) {
	const rect = el.getBoundingClientRect()
	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop
	return {
		top: rect.top + scrollTop,
		left: rect.left + scrollLeft,
		height: rect.height,
		width: rect.width,
	}
}

export function attachSource(source) {
	return source ? '&source=' + source : ''
}

export const getQueryParameterByName = (url, name) => {
	const parser = document.createElement('a')
	parser.href = url

	return getURLParameter(name, parser.search)
}

export const addLBPersistentQueryString = (location, url = '', persistentParams = config.persistentParams) => {
	const qIndex = url.lastIndexOf('?')
	const path = qIndex >= 0 ? url.substring(0, qIndex) : url
	const qs = qIndex >= 0 ? url.substring(qIndex) : ''
	const parsedSearch = queryString.parse(location.search)
	const parsedQueryString = queryString.parse(qs)
	const newQueryParams = {}

	persistentParams.forEach(p => {
		if (parsedSearch[p]) {
			newQueryParams[p] = parsedSearch[p]
		}
	})

	for (const k in parsedQueryString) {
		if (config.overrideProhibitedPersistentParams.indexOf(k) === -1) {
			newQueryParams[k] = parsedQueryString[k]
		}
	}

	const newQueryString = queryString.stringify(newQueryParams)
	return newQueryString ? path + '?' + newQueryString : path
}

export const getPurchaseUrl = (lounge, search, hostUrl) => {
	const qs = queryString.parse(search)

	delete qs.loungeId
	delete qs.loungeid
	qs.loungeId = lounge.id || ''

	delete qs.popup

	const checkoutUrl = `/booking-review?${queryString.stringify(qs)}`
	const embedTransaction = qs.embed && !lounge.disableMobileDeepLinking && lounge.transactionFlow

	// loungebuddy://checkout?loungeId=8flM960C5H?web=https://www.loungebuddy.com.au/checkout?loungeId=8flM960C5H&source=tripit_test&embed=true
	const checkoutMobileDeepLink = `loungebuddy://checkout?loungeId=${lounge.id || ''}?web=${encodeURI(
		hostUrl + checkoutUrl
	)}`
	const purchaseUrl = embedTransaction ? checkoutMobileDeepLink : checkoutUrl
	return {purchaseUrl, embedTransaction}
}

export const appendQueryParamsToUrl = (url, params, override = true) => {
	const qIndex = url.lastIndexOf('?')
	const path = qIndex >= 0 ? url.substring(0, qIndex) : url
	const qs = qIndex >= 0 ? url.substring(qIndex) : ''
	const originalQueryParams = queryString.parse(qs)
	const newQueryParams = originalQueryParams

	for (const k in params) {
		if (!newQueryParams[k] || override) {
			newQueryParams[k] = params[k]
		}
	}

	const newQueryString = queryString.stringify(newQueryParams)
	return newQueryString ? path + '?' + newQueryString : path
}

export const getLoungeAccessType = lounge => {
	return lounge.purchasable && !lounge.hidePurchaseAccess
		? loungeAccessTypes.PURCHASABLE
		: lounge.nonContingentAccess
		? loungeAccessTypes.WALKINS_ACCEPTED
		: loungeAccessTypes.ACCESS_UNKNOWN
}

const getImageSpec = image => {
	const {width: w, height: h} = image.info || {width: 2000, height: 2000}
	return {
		w,
		h,
		src: transformCloudinaryImage(image.original),
		title: image.description,
	}
}

export const convertImagesForPhotoswipe = (images, params, orientation = 'landscape') =>
	images
		.filter(img => img && img.original)
		.map((img, i) =>
			Object.assign(
				{
					pid: i,
					orientation,
					thumbnail: transformCloudinaryImage(img.original, params),
					publicId: img.publicId,
				},
				getImageSpec(img)
			)
		)

export function getInventory(availabilities, inventoryId) {
	return availabilities.find(a => a.inventoryId === inventoryId)
}

export const LBPropTypes = {
	lounge: PropTypes.shape({
		id: PropTypes.string.isRequired,
		location: PropTypes.shape({
			terminal: PropTypes.string,
		}),
		hours: PropTypes.array,
		airport: PropTypes.shape({
			IATA: PropTypes.string,
			timeZone: PropTypes.string,
			name: PropTypes.string,
		}),
		airsideDesc: PropTypes.string,
		loungeThumbImage: PropTypes.string,
		importantInformation: PropTypes.shape({
			general: PropTypes.array,
			regulatory: PropTypes.array,
			warning: PropTypes.array,
		}),
		purchasable: PropTypes.bool,
		hidePurchaseAccess: PropTypes.bool,
		productName: PropTypes.string,
		name: PropTypes.string,
		displayIATA: PropTypes.string,
		description: PropTypes.string,
		displayPrice: PropTypes.shape({
			currencyCode: PropTypes.string,
			originalPrice: PropTypes.number,
			price: PropTypes.number,
		}),
		amenities: PropTypes.array,
	}),
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
	}),
	bookingInfo: PropTypes.shape({
		availabilities: PropTypes.array,
		currencyCode: PropTypes.string,
		promotion: PropTypes.shape({
			type: PropTypes.string,
			value: PropTypes.number,
			scope: PropTypes.string,
		}),
	}),
	images: PropTypes.arrayOf(
		PropTypes.shape({
			dimensions: PropTypes.shape({
				width: PropTypes.number,
				height: PropTypes.number,
			}),
			description: PropTypes.string,
			images: PropTypes.shape({
				publicId: PropTypes.string,
				keyart_mobile: PropTypes.string,
				thumbnail: PropTypes.string,
				small: PropTypes.string,
				medium: PropTypes.string,
				large: PropTypes.string,
				full: PropTypes.string,
			}),
		})
	),
	airport: PropTypes.shape({
		displayIATA: PropTypes.string,
		terminalList: PropTypes.arrayOf(PropTypes.string),
		imageURL: PropTypes.string,
		description: PropTypes.string,
		name: PropTypes.string,
		location: PropTypes.shape({
			description: PropTypes.string,
			city: PropTypes.string,
		}),
	}),
}

/**
 * GA session definition: https://support.google.com/analytics/answer/2731565?hl=en
 *
 * Time-based expiration:
 * 	After 30 minutes of inactivity
 * 	At midnight
 * Campaign change:
 * 	If a user arrives via one campaign, leaves, and then comes back via a different campaign.
 *
 * 	it runs the callback on new sessions
 *
 * 	@param session idle timeout, defaults to 30 minutes
 * 	@param callback function for a new session
 */
let _trackingSession = false
const sessionActiveKey = 'session_last_active'
export function onNewSession({timeout = 30 * 60000, callback = () => {}} = {}) {
	if (_trackingSession || !window.localStorage) return

	let today = new Date()
	let utm_campaign = getURLParameter('utm_campaign')

	const reset = _.debounce(() => {
		const now = new Date()
		const utm_campaign_new = getURLParameter('utm_campaign')

		// timeout check
		const lastActiveTime = window.localStorage.getItem(sessionActiveKey)

		// midnight check
		if (now.getDate() !== today.getDate()) {
			today = now
			callback() //no return, because we should start a new session
		} else if (utm_campaign !== utm_campaign_new) {
			utm_campaign = utm_campaign_new
			callback()
		} else if (!lastActiveTime || now - new Date(lastActiveTime) > timeout) {
			callback()
		}

		window.localStorage.setItem(sessionActiveKey, now.toISOString())
	}, 500, { leading: true })
	// if user pause all actions for 0.5s, it can check for new session
	// use `leading` to ensure checking for new session even if the user actions don't stop

	const events = ['mousemove', 'touchstart', 'keypress', 'hashchange', 'scroll']
	events.forEach(ev => {
		window.addEventListener(ev, reset)
	})

	_trackingSession = true
}

export function getLBGlobalConstants() {
	return (window.__LB_CONSTANTS__ = window.__LB_CONSTANTS__ || {})
}

export function scrollToAnchor(selector) {
	document.querySelector(selector).scrollIntoView({behavior: 'smooth'})
}

