import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {useLocation} from 'react-router'
import {compose} from 'recompose'

import useIframeResizer from './hooks/useIframeResizer'
import usePageview from './hooks/usePageview'

const Homepage = props => {
	const {initialLanguage, t, nextjsUrl, activeCurrency} = props

	const location = useLocation()

	usePageview()
	const iframeUrl = new URL(`/${initialLanguage}`, nextjsUrl)
	const iframeElement = useIframeResizer({
		title: 'Home Page',
		location,
		iframeUrl,
		qeId: 'homepage',
		currency: activeCurrency.code,
	})

	return (
		<div>
			<Helmet title={t('index.pageTitle')} />
			{iframeElement}
		</div>
	)
}

Homepage.propTypes = {
	t: PropTypes.func,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	//todo: update redux
	const {hostUrl, initialLanguage, nextjsUrl, activeCurrency} = state
	return {
		hostUrl,
		initialLanguage,
		nextjsUrl,
		activeCurrency,
	}
}
export default compose(withTranslation('translation'), connect(mapStateToProps))(Homepage)
