import React, {useEffect} from 'react'
import {Page} from '../component/Layout'
import usePageview from '../hooks/usePageview'

const Team = () => {
	usePageview()
	// We can't use react-router to directly redirect to an external url
	// so we redirect directly
	const redirectUrl = 'https://aexp.eightfold.ai/careers?query=loungebuddy'
	useEffect(() => {
		window.location.href = redirectUrl
	})

	return (
		<Page>
			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1em'}}>
				Redirecting to
				<a style={{margin: '0.25em'}} href={redirectUrl}>
					{redirectUrl}
				</a>
			</div>
		</Page>
	)
}

export default Team
