import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {fontWeight, MinorText} from './global/Typography'
import {media} from './global/Breakpoints'

const Icon = styled.span`
  display: flex;
  flex-wrap: nowrap;
`
const IconActive = styled(Icon)`
  display: none;
`
const whenActive = bg => css`
  background: ${bg};
  ${Icon} {
    display: none;
  }
  ${IconActive} {
    display: flex;
  }
`

const Wrap = styled.button`
  border-style: solid;
  border: none;
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  background: none;
  border-radius: 5px;
  cursor: pointer;

  ${media.tablet`
    padding: 6px;
  `}

  ${p =>
    p.active &&
    css`
      ${whenActive(p.bg)}
    `}
`

const Label = styled(MinorText)`
  display: inline-block;
  margin-left: 8px;

  font-weight: ${fontWeight.medium};

  ${media.tablet`
    display: none;
  `}
`

export const Button = ({
  label,
  ariaLabel,
  icon,
  iconActive,
  bgActive,
  isActive,
  onClick,
  innerRef,
}) => (
  <Wrap
    aria-haspopup="true"
    aria-expanded={isActive}
    aria-label={ariaLabel}
    ref={innerRef}
    active={isActive}
    bg={bgActive}
    onClick={onClick}>
    {icon && <Icon>{icon}</Icon>}
    {iconActive && <IconActive>{iconActive}</IconActive>}
    {label && <Label>{label}</Label>}
  </Wrap>
)

Button.propTypes = {
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  iconActive: PropTypes.element.isRequired,
  bgActive: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  innerRef: PropTypes.object,
}

const ListWrap = styled.div`
  display: inline-flex;

  > div {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const ButtonGroup = ({buttons}) => {
  return (
    <ListWrap>
      {buttons.map((button, i) => (
        <Button key={i} {...button} />
      ))}
    </ListWrap>
  )
}

ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
}
