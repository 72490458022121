import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {media} from '../component/Global/Breakpoints'

const TravelerButtonSet = styled.div`
	display: flex;
	margin-top: 25px;

	${media.mobile`
		flex-direction: column;
        margin-right: 0px;
    `}
`

const TravlerButton = styled.button`
	flex: 1; /* so that buttons fill the available space evenly */
	cursor: pointer;
	background: rgb(255, 255, 255);
	border: 1px solid rgb(232, 232, 233);
	border-radius: 8px;
	padding: 24px 12px;
	margin: 0 4px;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: -0.1px;
	text-transform: capitalize;

	&.travelerActive {
		border-width: 2px;
		border-color: rgb(0, 116, 217);
	}

	&:last-child {
		margin-right: 0px;
	}

	${media.desktop`
		&:hover {
			background-color: #f3f4f4;
		}

		&.travelerActive:hover {
			background-color: #FFF;
		}
	`}

	${media.mobile`
		margin-top: 8px;
		margin-right: 0px;

        &:first-child {
            margin-top: 0px;
        }
    `}
`

export const NumberOfTravelers = ({
	t,
	maxAdditonalGuest = 1,
	howManyComing = 1,
	setHowManyComing = () => {
		return null
	},
}) => {
	return (
		<TravelerButtonSet>
			{[...Array(maxAdditonalGuest)].map((_, i) => {
				const travelerNumber = i + 1

				return (
					<TravlerButton
						key={`traveler_button_${travelerNumber}`}
						type="button"
						className={`${travelerNumber === howManyComing ? 'travelerActive' : ''}`}
						onClick={() => setHowManyComing(travelerNumber)}>
						{t('travelerWithCount', {count: travelerNumber})}
					</TravlerButton>
				)
			})}
		</TravelerButtonSet>
	)
}

NumberOfTravelers.propTypes = {
	maxAdditonalGuest: PropTypes.number.isRequired,
	t: PropTypes.func,
	howManyComing: PropTypes.number,
	setHowManyComing: PropTypes.func,
}
