import React from 'react'
import PropTypes from 'prop-types'
import IconBase from '../IconBase'
import { _, iconIdPrefix } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const LockIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 16 16" style={{marginTop: -2}} {...props}>
			<defs>
				<path
					d="M5.69 4.285c0-1.328 1.136-2.404 2.535-2.404 1.4 0 2.535 1.076 2.535 2.404V5h1.982v-.715C12.742 1.92 10.72 0 8.225 0 5.73 0 3.708 1.919 3.708 4.285V5h1.983v-.715zm6.827 1.881H3.483c-1.095 0-1.983.843-1.983 1.881v6.072C1.5 15.158 2.388 16 3.483 16h9.034c1.095 0 1.983-.842 1.983-1.881V8.047c0-1.038-.888-1.88-1.983-1.88zm-3.816 5.531v1.632h-.013a.692.692 0 01-.688.54.692.692 0 01-.688-.54h-.013v-1.632c-.419-.23-.7-.66-.7-1.151 0-.735.627-1.33 1.401-1.33s1.402.595 1.402 1.33c0 .492-.282.921-.701 1.151z"
					id={id + 'a'}
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id={id + 'b'} fill="#fff">
					<use xlinkHref={`#${id + 'a'}`} />
				</mask>
				<g mask={`url(#${id + 'b'})`} fill={props.color || 'currentColor'}>
					<path d="M0 0h17v17H0z" />
				</g>
			</g>
		</IconBase>
	)
}

LockIcon.propTypes = {
	color: PropTypes.string
}

export default LockIcon
