import React, {memo} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {BodyText, fontWeight, MinorText} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import {maxWidthTablet} from '../constants'
import {useMediaQuery} from 'react-responsive'

const Bullet = styled.span`
	display: inline-block;
	margin: 0 8px;
	font-size: 10px;
	line-height: 18px;
	position: relative;
	top: -2px;
`
const TerminalInfo = styled.span`
	text-transform: capitalize;
`
const DescriptionRow = styled(MinorText)`
	display: block;
	color: ${color.espressoLight};
`
const Content = styled(BodyText)`
	display: block;
	padding: 24px;

	${p =>
		p.contentStyles &&
		css`
			${p.contentStyles};
		`}
`
const Section = styled.div`
	border-bottom: 1px solid ${color.lightGreyLighter};
	padding-bottom: 16px;
	margin-bottom: 16px;

	& div:last-child {
		margin-bottom: 0;
	}
`
const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;

	${p =>
		p.isLast &&
		css`
			margin-bottom: 0;
			font-weight: ${fontWeight.medium};
		`}
`

const DescRow = ({itemLeft, itemRight}) => (
	<DescriptionRow>
		<span>{itemLeft}</span>
		{itemRight && <Bullet>&#9679;</Bullet>}
		{itemRight && <span>{itemRight}</span>}
	</DescriptionRow>
)

DescRow.propTypes = {
	itemLeft: PropTypes.any.isRequired,
	itemRight: PropTypes.any,
}

export const continueToPayEvent = 'continueToPayEvent'
export const completeBookingEvent = 'completeBookingEvent'

const PriceBreakdown = ({
	t,
	lounge,
	getFormattedPrice,
	priceSummary,
	howManyComing,
	chosenAvailability,
	bookingSummary,
	isCheckout,
	contentStyles,
}) => {
	const terminal = <TerminalInfo>{`${lounge.location.terminal} ${lounge.airsideDesc}`}</TerminalInfo>

	const travelers = []
	for (let i = 0; i < howManyComing; i++) {
		travelers.push(
			<Row key={i}>
				<span>{`${t('traveler')} ${i + 1}`}</span>
				<span>{getFormattedPrice(chosenAvailability.price)}</span>
			</Row>
		)
	}

	const bookingDetails = (
		<div>
			<DescRow itemLeft={bookingSummary.date} />
			{chosenAvailability && <DescRow itemLeft={bookingSummary.type} itemRight={bookingSummary.numOfTravelers} />}
		</div>
	)

	const isTablet = useMediaQuery({maxWidth: maxWidthTablet})

	const totalPriceStr = chosenAvailability ? (
		<span>{getFormattedPrice(priceSummary.totalPrice)}</span>
	) : (
		<span>---</span>
	)

	return (
		<React.Fragment>
			<Content contentStyles={contentStyles}>
				<Section>
					<BodyText fontWeight="medium">{lounge.name}</BodyText>
					<DescRow itemLeft={lounge.airport.IATA} itemRight={terminal} />
					{isCheckout && bookingDetails}
					{!isCheckout && isTablet && bookingDetails}
				</Section>
				<Section>{travelers}</Section>
				<Row>
					<span>{t('subtotal')}</span>
					<span>{getFormattedPrice(priceSummary.originalTotalPrice)}</span>
				</Row>
				{priceSummary.isSale && (
					<Row>
						<span>{t('promotion')}</span>
						<span>- {getFormattedPrice(priceSummary.promotion.discount)}</span>
					</Row>
				)}
				<Row>
					<span>{t('taxesFees')}</span>
					<span>{t('included')}</span>
				</Row>
				<Row isLast medium>
					<span>{t('total')}</span>
					<span>{totalPriceStr}</span>
				</Row>
			</Content>
		</React.Fragment>
	)
}

PriceBreakdown.propTypes = {
	t: PropTypes.func.isRequired,
	images: PropTypes.array.isRequired,
	lounge: PropTypes.object.isRequired,
	getFormattedPrice: PropTypes.func.isRequired,
	priceSummary: PropTypes.object.isRequired,
	howManyComing: PropTypes.number.isRequired,
	chosenAvailability: PropTypes.object,
	bookingSummary: PropTypes.object.isRequired,
	isCheckout: PropTypes.bool,
	cloudinaryParams: PropTypes.string.isRequired,
	contentStyles: PropTypes.array,
}

export default memo(PriceBreakdown)
