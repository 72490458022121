import React, {SyntheticEvent, MouseEvent} from 'react'
import {Link, useLocation, LinkProps} from 'react-router-dom'
import {addLBPersistentQueryString, appendQueryParamsToUrl} from '../utils'
import {trackEvent} from '../trackHelper'

function delayedClickEvent(action: {(e: SyntheticEvent): Promise<void>}) {
	let firstClick = true
	return (e: MouseEvent<HTMLAnchorElement>) => {
		if (!firstClick) return

		firstClick = false
		const currentTarget = e.currentTarget

		// only run `click()` if the url of the current page will change,
		// because `click()` doesn't trigger new tab (i.e. target=_blank) on iPad
		const preventDefault =
			currentTarget.target === '_blank' || // open new tab
			e.metaKey || // hold command key on Mac or window key on Windows
			// don't preventDefault for internal links because react router will block navigation when pressing "Enter" key
			!currentTarget.dataset.internal

		if (preventDefault) e.preventDefault()

		// need to capture this before it gets NULLed by react
		action(e).then(() => {
			if (preventDefault) currentTarget.click() // `e.currentTarget` is null in eventCallback
		})
	}
}

// hide `eventCallback` details when tracking links
interface TrackLinkParams {
	sourceLink?: string
	gtmParams?: object
}

type TrackFunc = (e: SyntheticEvent, track: (name: string, params: TrackLinkParams) => Promise<void>) => void

const linkTrackHelper = (id?: string) => (trackCallback?: TrackFunc) =>
	delayedClickEvent((e: SyntheticEvent) => {
		if (!trackCallback) return Promise.resolve()

		return new Promise(resolve => {
			trackCallback(e, async (name, params) => {
				if (!name) {
					// eslint-disable-next-line no-console
					console.warn('Missing tracking name for ', params)
				}

				const newParams = {...params}
				if (id) {
					// for tracking which anchor link it was
					newParams.sourceLink = id
				}

				await trackEvent(name, newParams)
				resolve()
			})
		})
	})

export const LinkType = {
	ANCHOR: 'a',
	LINK: 'Link',
}

interface Prop extends LinkProps {
	isDeepLink?: boolean
	trackCallback?: TrackFunc
	type?: string
	id?: string
}

const LBLink = ({to, isDeepLink = false, type = LinkType.LINK, trackCallback, id, ...rest}: Prop) => {
	const location = useLocation<{params: object}>()

	// Use regular Link component in case of getting object-type "to" parameter
	if (typeof to !== 'string') {
		const newTo =
			typeof to === 'object'
				? {
						...to,
						search: addLBPersistentQueryString(location, to.search),
				  }
				: to
		return <Link id={id} {...rest} to={newTo} />
	}

	let toUrl = addLBPersistentQueryString(location, to)

	if (location?.state?.params) {
		toUrl = appendQueryParamsToUrl(toUrl, location.state.params)
	}

	// when linking outside of domain
	if (toUrl.indexOf('http') === 0) {
		type = LinkType.ANCHOR
	}

	const track = linkTrackHelper(id)(trackCallback)

	const handleDeepLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		track(e)

		window.open(toUrl)

		if (rest.onClick) {
			rest.onClick(e)
		}
	}

	const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
		if (rest.onClick) {
			rest.onClick(e)
		}

		const el = document.querySelector<HTMLButtonElement>('button.MuiAutocomplete-clearIndicator')

		if (el) {
			el?.click()
			window.focus()
			// Remove focus from any focused element
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur()
			}
		}

		track(e)
	}

	return isDeepLink ? ( // In case of deep link, force open in new window
		<a id={id} {...rest} href={toUrl} onClick={handleDeepLinkClick} />
	) : type === LinkType.ANCHOR ? (
		<a id={id} {...rest} href={toUrl} onClick={handleClick} />
	) : (
		<Link id={id} data-internal {...rest} to={toUrl} onClick={handleClick} />
	)
}

export default LBLink
