import React from 'react'
import {connect} from 'react-redux'
import LBLink from '../component/LBLink'
import {fetchJson, formatPrice} from '../../util/universal'
import {compose} from 'recompose'
import Helmet from 'react-helmet'
import loadData from '../component/loadData'
import {withTranslation} from 'react-i18next'
import LoungeInfo from '../component/LoungeInfo'
import classNames from 'classnames'
import AddToCalendar from '../component/AddToCalendar'
import FooterTiny from '../component/FooterTiny'
import Modal from 'react-modal'
import EmailConfirmationForm from './EmailConfirmationForm'
import CancelForm from './CancelForm'
import FixedPanel from '../component/FixedPanel'
import printJS from 'print-js'
import ScrollLink from '../component/ScrollLink'
import PaymentHeader from '../component/paymentHeader'
import {styles} from './styles'
import TerminalToggle from '../component/TerminalDetail/TerminalToggle'
import {format, differenceInDays, addDays, addHours} from 'date-fns'
import {utcToZonedTime} from 'date-fns-tz'
import {openChat, getQueryParameterByName, addLBPersistentQueryString} from '../utils'
import {Img} from '../component/LazyLoad'
import {EXTERNAL_DATE_FORMAT, EXTERNAL_TIME_FORMAT} from '../../util/constants'
import {trackConstants, trackEcommerceEvent, trackEvent} from '../trackHelper'
import locales from '../../locales'
import mapProps from 'recompose/mapProps'
import usePageview from '../hooks/usePageview'

class Booking extends React.Component {
	constructor(props) {
		super(props)

		//todo: need inventory type in the api data
		const {
			lounge,
			bookingData,
			location,
			// bookingInfo: {
			// 	inventoryTypes
			// }
		} = props

		this.state = {
			// inventoryTypes,
			animateStep: props.location.state && props.location.state.confirm ? 1 : 6,
			appleWalletEnabled: false, // todo
			isCreditCardCheckout: true,
			openDirection: false,
			modalIsOpen: false,
			email: '',
			onSent: undefined,
		}

		// moment.locale(props.initialLanguage);

		if (location && location.state && location.state.pageType) {
			const bookingDateTz = utcToZonedTime(bookingData.reservationTime, lounge.airport.timeZone)
			trackEvent(trackConstants.EVENT.retrieveBooking, {
				bookingDate: format(bookingDateTz, EXTERNAL_DATE_FORMAT),
				bookingTime: format(bookingDateTz, EXTERNAL_TIME_FORMAT),
				orderId: bookingData.orderId,
				success: true,
				pageType: location.state.pageType,
			})
		}
	}

	nextAnimateStep = () => {
		setTimeout(() => {
			if (this.state.animateStep <= 5) {
				this.setState({animateStep: this.state.animateStep + 1})
				this.nextAnimateStep()
			} else {
				const {history, location} = this.props
				if (location.state && location.state.confirm) {
					history.replace({
						pathname: location.pathname,
						search: location.search,
						state: {}, // remove the animateStep in state in case they refresh the page
					})
				}
			}
		}, 1000)
	}

	componentDidMount() {
		this.nextAnimateStep()
	}

	_getBaseTrackingData() {
		const {lounge} = this.props
		return {
			productName: lounge.name,
			airportIATACode: lounge.displayIATA,
			airportName: lounge.airport.name,
			terminalName: lounge.location.terminal,
		}
	}

	_printConfirmation = () => {
		const {
			bookingData: {confirmationAttachment, orderId},
			hostUrl,
		} = this.props
		const q = getQueryParameterByName(confirmationAttachment, 'q')
		printJS({
			printable: `${hostUrl}/orders/confirmationAttachment?q=${q}&orderId=${orderId}`,
			type: 'pdf',
			documentTitle: 'LoungeBuddy_Confirmation_' + orderId,
			showModal: true,
		})

		trackEvent(trackConstants.EVENT.clickPrint, this._getBaseTrackingData())
	}

	toggleZoomCode = url => e => {
		this.setState(prev => ({
			modalIsOpen: !prev.modalIsOpen,
			modalType: 'qrCode',
			codeUrl: url,
		}))
	}

	toggleModal = ({email = '', onSent, modalType = 'email'} = {}) => {
		this.setState(prev => ({modalIsOpen: !prev.modalIsOpen, email, onSent, modalType}))
	}

	_shareConfirmation = () => {
		const {
			bookingData: {user, bookingId},
		} = this.props
		this.toggleModal({
			onSent: () => {
				this.setState({modalIsOpen: false})
			},
		})
		trackEvent(trackConstants.EVENT.share, this._getBaseTrackingData())
	}

	_resendConfirmation = () => {
		const {
			bookingData: {user, bookingId},
		} = this.props
		this.toggleModal({
			email: user.email,
			onSent: () => {
				this.setState({modalIsOpen: false})
			},
		})
		trackEvent(trackConstants.EVENT.clickSendAgain, this._getBaseTrackingData())
	}

	_cancelBooking = () => {
		const {bookingData, lounge} = this.props
		this.toggleModal({
			modalType: 'cancel',
			onSent: () => {
				this.setState({modalIsOpen: false})

				const currencyCode = Object.keys(bookingData.payment.amount)[0]
				const totalPrice = bookingData.payment.amount[currencyCode]
				const inventoryPrice = bookingData.unitPrice[Object.keys(bookingData.unitPrice)[0]].price[currencyCode]

				trackEvent(trackConstants.EVENT.cancelBooking, {
					// days canceled in advance
					productName: lounge.name,
					airportIATACode: lounge.displayIATA,
					airportName: lounge.airport.name,
					terminalName: lounge.location.terminal,
					daysCanceledInAdvance: differenceInDays(new Date(bookingData.reservationTime), new Date()),
				})

				trackEcommerceEvent('refund', {actionField: {id: bookingData.bookingId}}, undefined, {
					product: lounge.name,
					airport: lounge.displayIATA,
					packageName: bookingData.inventory.lengthDesc,
					packageValue: `${inventoryPrice} ${currencyCode}`,
					orderSize: totalPrice,
					reservationDate: format(
						utcToZonedTime(bookingData.reservationTime, lounge.airport.timeZone),
						EXTERNAL_DATE_FORMAT
					),
					cancellationDate: format(utcToZonedTime(new Date(), lounge.airport.timeZone), EXTERNAL_DATE_FORMAT),
					eventCallback: () => location.reload(),
				})

				// in case google tracking is not allowed to run, eventCallback won't fire
				setTimeout(() => {
					location.reload()
				}, 1000)
			},
		})
	}

	getTotalVisitorsString(unitsPurchased) {
		const types = unitsPurchased ? Object.keys(unitsPurchased) : []
		let guests = []
		types.forEach(aType => {
			const count = parseInt(unitsPurchased[aType], 10)
			if (count > 0) {
				guests.push(this.props.t(aType + 'WithCount', {count}))
			}
		})

		return guests.join(', ')
	}

	render() {
		const {lounge, source, sourceSpecificBodyClassName, bookingData, t, i18n, location} = this.props
		const {
			animateStep,
			appleWalletEnabled,
			openDirection,
			isCreditCardCheckout,
			modalIsOpen,
			modalType,
			email,
			onSent,
			codeUrl,
		} = this.state

		const path = location.pathname
		const orderId = bookingData.orderId
		const airport = lounge.airport
		// const bookType = 'room' in inventoryTypes ? 'Room' : 'Lounge'
		const language = i18n.language
		const currencyCode = Object.keys(bookingData.payment.amount)[0]
		const totalPrice = bookingData.payment.amount[currencyCode]
		const originalTotalPrice = bookingData.payment.data.originalTotalPrice || totalPrice
		const bookingDate = new Date(bookingData.reservationTime)
		const bookingDateTz = utcToZonedTime(bookingDate, airport.timeZone) // for display
		const cancellationDate = addDays(new Date(bookingData.reservationTime), -1)
		const cancellationDateExpired = cancellationDate < new Date()
		const bookingEndDate = addHours(bookingDate, bookingData.inventory.visitLength)
		const totalVisitorsDesc = this.getTotalVisitorsString(bookingData.unit)
		const calendarDesc = `${t('total')}: ${totalVisitorsDesc}\n${t('lengthOfVisit')}: ${
			bookingData.inventory.lengthDesc
		}\n${t('directions')}: ${lounge.location.terminalDirections}\n    ${t('locatedInWithTerminal', {
			terminal: lounge.location.terminal,
		})}`

		//todo: migrate to styled component
		const _styles = {
			...styles,
			modalStyles: {
				overlay: {
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: 'rgba(255, 255, 255, 0.75)',
					zIndex: 99,
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					bottom: 'auto',
					marginRight: '-50%',
					transform: 'translate(-50%, -50%)',
				},
			},
		}

		const dateLocale = locales[language].dateFormat
		const reservationInfo = (
			<div className="reservation">
				<p>
					<strong>{t('reservationDate')}:</strong> {format(bookingDateTz, 'PPPPp', {locale: dateLocale})}
				</p>
				<p>
					<strong>{t('total')}:</strong> {totalVisitorsDesc}
				</p>
				<p>
					<strong>{t('lengthOfVisit')}:</strong> {bookingData.inventory.lengthDesc}
				</p>
				<p>
					<strong>{t('bookingEmail')}:</strong> {bookingData.user.email}
				</p>
			</div>
		)

		const direction = className => (
			<div className={classNames('directions', className)}>
				<h3>{t('directions') + ':'}</h3>
				<div className="info">
					<i className="checkout-icon checkout-icon--walk" />
					{lounge.location.terminalDirections}
				</div>
				<TerminalToggle
					location={lounge.location}
					textForShowDetails={t('showTerminal')}
					airsideDesc={lounge.airsideDesc}
					forceOpen={openDirection}
				/>
			</div>
		)

		return (
			<div>
				<Helmet
					title={`${airport.IATA} ${lounge.name} Confirmation`}
					bodyAttributes={{class: `page-spa page-spa--confirmation ${sourceSpecificBodyClassName}`}}
				/>

				<div className="confirmation-page loading">
					<Modal ariaHideApp={false} isOpen={modalIsOpen} style={_styles.modalStyles}>
						{modalType === 'email' ? (
							<EmailConfirmationForm
								email={email}
								onSent={onSent}
								onCancel={this.toggleModal}
								bookingId={bookingData.bookingId}
								bookingEmail={bookingData.user.email}
							/>
						) : modalType === 'qrCode' ? (
							<div onClick={this.toggleZoomCode()}>
								<img src={codeUrl} className="img-responsive center-block" />
							</div>
						) : (
							<CancelForm
								lounge={lounge}
								onSent={onSent}
								onCancel={this.toggleModal}
								bookingId={bookingData.bookingId}
								bookingEmail={bookingData.user.email}
							/>
						)}
					</Modal>

					<div className="loading-bar mob-only" />

					<FixedPanel className="lb-pay-toggle lb-pay-toggle--order mob-only" id="sum-panel" style={{zIndex: 100}}>
						<div className="toggle__button">
							<ul>
								<li>
									<ScrollLink to="confirmation" end="direction">
										{t('confirmation')}
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="direction" end="bookingDetails">
										{t('directions')}
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="bookingDetails" end="payment">
										{t('bookingDetails')}
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="payment">{t('payment')}</ScrollLink>
								</li>
							</ul>
						</div>
					</FixedPanel>

					<div
						className={classNames('lb-pay-content lb-pay-confirmation', bookingData.status, {loaded: animateStep > 5})}
						id="confirmation-page">
						<div className="inner">
							{animateStep <= 5 && (
								<div className="checkout-steps" id="confirmation-steps">
									<h2>{t('oasis')}</h2>
									<ul id="confirmation-loading">
										<li
											className={classNames({
												loading: animateStep === 1,
												loaded: animateStep > 1,
											})}
											id="loading-step-1">
											<i className="checkout-icon checkout-icon--disable" />
											<div className="spinner">
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
											</div>
											<i className="checkout-icon checkout-icon--success" />
											<h3>{t('reserve')}</h3>
											<p>{t('securing')}</p>
										</li>
										<li
											className={classNames({
												inactive: animateStep < 2,
												loading: animateStep === 2,
												loaded: animateStep > 2,
											})}
											id="loading-step-2">
											<i className="checkout-icon checkout-icon--disable" />
											<div className="spinner">
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
											</div>
											<i className="checkout-icon checkout-icon--success" />
											<h3>{t('processPayment')}</h3>
											<p>{t('beingProcessed')}</p>
										</li>
										<li
											className={classNames({
												inactive: animateStep < 3,
												loading: animateStep === 3,
												loaded: animateStep > 3,
											})}
											id="loading-step-3">
											<i className="checkout-icon checkout-icon--disable" />
											<div className="spinner">
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
												<div className="spinner-blade" />
											</div>
											<i className="checkout-icon checkout-icon--success" />
											<h3>{t('sendingConfirmation')}</h3>
											<p>{t('emailingConfirmation')}</p>
										</li>
									</ul>
									<a className="link-support" style={_styles.pointer} onClick={openChat}>
										{t('needHelp')}
									</a>
								</div>
							)}

							<div className="checkout-wrap" id="confirmation-form">
								<div className="form-wrap">
									{bookingData.status === 'canceled' ? (
										<div className="summary">
											<h3>
												<i className="checkout-icon checkout-icon--error" />
												{' ' + t('canceledTitle')}
											</h3>
											<p
												dangerouslySetInnerHTML={{
													__html: t('canceledDescription', {email: bookingData.user.email}),
												}}
											/>
											<div className="explanations">
												<p>
													<i className="checkout-icon checkout-icon--calendar" />
													{t('bookWrong') + ' '}
													<LBLink
														to={`/${lounge.airport.IATA}/${lounge.slugPath}`}
														trackCallback={(e, trackCB) => {
															trackCB(trackConstants.EVENT.bookAgain, this._getBaseTrackingData())
														}}>
														{t('bookAgain')}
													</LBLink>
												</p>
												<p>
													<i className="checkout-icon checkout-icon--help" />
													{t('needAssistance') + ' '}
													<a href="#" style={_styles.pointer} onClick={openChat}>
														{t('contactSupport')}
													</a>
												</p>
											</div>
										</div>
									) : (
										<div className="summary">
											<p>
												<strong>{bookingData.user.name}</strong>,
											</p>
											<h3>
												<i className="checkout-icon checkout-icon--success" />
												{t('reservationConfirmed')}
											</h3>
											<p
												dangerouslySetInnerHTML={{
													__html: t('emailSummary', {
														loungeLink: addLBPersistentQueryString(location, `/${airport.IATA}/${lounge.slugPath}`),
														lounge: lounge.name,
														listingLink: addLBPersistentQueryString(location, `/${airport.IATA}`),
														IATA: airport.IATA,
														date: format(bookingDateTz, 'PPP', {locale: dateLocale}),
													}),
												}}
											/>
											<p
												dangerouslySetInnerHTML={{
													__html: t('safeTravel'),
												}}
											/>
										</div>
									)}

									<div className="loading-bar" />

									<div className="form form--first form--confirmation">
										<div data-anchor="confirmation" className="form__inner">
											<h2>
												{t('orderConfirmation')}
												<span className="id">{orderId}</span>
											</h2>
											<div className="instructions">
												<p>{t('whenArrive')}</p>
											</div>
											<div className="informations">
												<div className="lounge">
													<div className="preview">
														<Img src={lounge.loungeThumbImage} alt="" />
													</div>
													<LoungeInfo lounge={lounge} showDirection={false} />
												</div>
												{reservationInfo}
											</div>

											{direction('hidden-mob')}
											<div className="qr-codes visible-confirmed">
												{bookingData.codes.map((code, i) => (
													<div key={i} className="qr-code" onClick={this.toggleZoomCode(code.url)}>
														<img src={code.url} className="img-responsive center-block" />
														<a className="zoom" style={_styles.pointer}>
															<i className="fa fa-search-plus" aria-hidden="true" />
															{t('tapToZoom')}
														</a>
													</div>
												))}
											</div>
											<div className="qr-codes visible-canceled">
												<div className="qr-code qr-code--canceled" />
											</div>
											<div className="text-center visible-confirmed">
												<a
													className="support-access"
													style={_styles.pointer}
													target="_blank"
													rel="noreferrer noopener"
													href="https://support.loungebuddy.com/contact/contact-us--BkXNtUJd_">
													{t('trouble')}
												</a>
											</div>
											<div data-anchor="direction" />
											{direction('mob-only')}
										</div>

										<div className="bottom-buttons visible-confirmed">
											<span
												className={classNames('fields fields--normal clearfix', {
													'fields--two-field': !appleWalletEnabled,
												})}>
												<span className="field field--print">
													<a className="button button--light" style={_styles.pointer} onClick={this._printConfirmation}>
														<span>
															<i className="fa fa-print" aria-hidden="true" /> {t('print')}
														</span>
													</a>
												</span>
												<span className="field">
													<AddToCalendar
														t={t}
														title={lounge.name}
														startDate={bookingDate}
														endDate={bookingEndDate}
														location={`${airport.name} (${airport.IATA})`}
														description={calendarDesc}
														btnText={t('addCalendar')}
														track={() => {
															trackEvent(trackConstants.EVENT.addToCalendar, this._getBaseTrackingData())
														}}
													/>
												</span>
												{appleWalletEnabled && (
													<span className="field field--apple-wallet">
														<a className="button button--apple-wallet">
															<span>{t('addAppleWallet')}</span>
														</a>
													</span>
												)}
											</span>
										</div>
									</div>

									<div className="form form--tip visible-confirmed">
										<div className="form__inner">
											<i className="checkout-icon checkout-icon--light" />
											<p>{t('flexible')}</p>
										</div>
									</div>

									<div className="form form--confirmation form--details">
										<div data-anchor="bookingDetails" className="form__inner">
											<h2>
												{t('bookingDetails')}:<span className="id">{orderId}</span>
											</h2>
											<div className="informations">
												<div className="lounge">
													<div className="preview">
														<Img src={lounge.loungeThumbImage} alt="" />
													</div>
													<LoungeInfo lounge={lounge} />
												</div>
												{reservationInfo}
											</div>
										</div>
									</div>

									<div
										className={classNames('form form--payment form--last', {
											'form--credit-card-payment': isCreditCardCheckout,
										})}>
										<div data-anchor="payment" className="form__inner">
											<PaymentHeader />
											<span className="fields">
												<span className="field field--66">
													<span className="lounge-name">
														<LBLink to={`/${lounge.airport.IATA}/${lounge.slugPath}`} target="_blank">
															{lounge.name} ({airport.IATA})
														</LBLink>
													</span>
													<span className="lounge-entries">
														{totalVisitorsDesc}, {bookingData.inventory.lengthDesc}
													</span>
												</span>
												<span className="field field--33">
													{formatPrice(originalTotalPrice, language, currencyCode)}
												</span>
											</span>
											{bookingData.payment.data.promotion && bookingData.payment.data.promotion !== 'No Promotion' && (
												<span className="fields">
													<span className="field field--66">
														<span className="promo-code">
															<strong>{bookingData.payment.data.promotion}</strong>
															<div style={{textTransform: 'capitalize'}}>{bookingData.payment.data.promotionType}</div>
														</span>
													</span>
													<span className="field field--33">
														{formatPrice(totalPrice - originalTotalPrice, language, currencyCode)}
													</span>
												</span>
											)}
											<span className="fields fields--total">
												<span className="field field--33">
													<span className="label-big">{t('orderTotal')}</span>
												</span>
												<span className="field field--66">
													<span className="price-big">
														<strong>{formatPrice(totalPrice, language, currencyCode)}</strong>
														<small>{t('taxIncluded')}</small>
													</span>
												</span>
											</span>
										</div>
										{isCreditCardCheckout && (
											<div className="credit-card">
												<p className="visible-confirmed">
													<i className="fa fa-credit-card" aria-hidden="true" />
													{t('chargeToCard') + ' '}
													<strong>*{bookingData.payment.data.last4}</strong>
												</p>
												<p className="visible-canceled">
													<i className="fa fa-credit-card" aria-hidden="true" />
													{t('refundToCard') + ' '}
													<strong>*{bookingData.payment.data.last4}</strong>
												</p>
											</div>
										)}
									</div>

									<div className="explanations visible-confirmed">
										<p>
											<i className="checkout-icon checkout-icon--refresh" />
											{t('failToReceive')}
											<a style={_styles.pointer} onClick={this._resendConfirmation}>
												{' ' + t('resendIt')}
											</a>
										</p>
										<p>
											<i className="checkout-icon checkout-icon--mail" />
											{t('sendCopy')}
											<a style={_styles.pointer} onClick={this._shareConfirmation}>
												{' ' + t('shareConfirmation')}
											</a>
										</p>
										{!cancellationDateExpired && (
											<p>
												<i className="checkout-icon checkout-icon--wrong" />
												{t('bookedWrong')}
												<a style={_styles.pointer} onClick={this._cancelBooking}>
													{' ' + t('cancelReservation')}
												</a>
											</p>
										)}

										<div className="mobile">
											<a style={_styles.pointer} onClick={this._resendConfirmation}>
												{' ' + t('resendConfirmation')}
											</a>
											<a style={_styles.pointer} onClick={this._shareConfirmation}>
												{' ' + t('shareConfirmation')}
											</a>
											{!cancellationDateExpired && (
												<a style={_styles.pointer} onClick={this._cancelBooking}>
													{' ' + t('cancelReservation')}
												</a>
											)}
											<a style={_styles.pointer} onClick={openChat}>
												{' ' + t('trouble')}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<FooterTiny />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const {lounge, bookingData, hostUrl, initialI18nStore, initialLanguage, error} = state
	return {lounge, bookingData, hostUrl, initialI18nStore, initialLanguage, error}
}

export default compose(
	connect(mapStateToProps),
	withTranslation('booking'),
	loadData({
		dataLoaded: ({bookingData}) => bookingData,
		updateData: location => {
			return fetchJson(`/api${location.pathname}${location.search}`)
		},
	}),
	mapProps(props => {
		usePageview(props)
		return props
	})
)(Booking)
