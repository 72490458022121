import React from 'react'
import { iconIdPrefix, _ } from '../../../../shared/util'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const MapPointIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 12 14" {...props}>
			<defs>
				<path
					d="M6.934 13.554a1.265 1.265 0 01-1.868 0C2.022 10.224.5 7.603.5 5.693.5 2.549 2.962 0 6 0s5.5 2.549 5.5 5.693c0 1.91-1.522 4.53-4.566 7.861zM6 8.224c1.35 0 2.444-1.134 2.444-2.531 0-1.397-1.094-2.53-2.444-2.53-1.35 0-2.444 1.133-2.444 2.53 0 1.397 1.094 2.53 2.444 2.53z"
					id={id + 'a'}
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id={id + 'b'} fill="#fff">
					<use xlinkHref={"#" + id + "a"} />
				</mask>
				<g mask={`url(#${id + 'b'})`} fill={props.color || 'currentColor'}>
					<path d="M-2-1h17v17H-2z" />
				</g>
			</g>
		</IconBase>
	)
}

export default MapPointIcon
