module.exports = () => {
	const persistentParamsForTracking = [
		'source',
		'gclid',
		'gclsrc',
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_content',
		'utm_term',
	]
	const persistentParamsForPartner = [
		'booking_date',
		'booking_time',
		'booking_adults',
		'booking_children',
		'passenger_email',
		'passenger_name',
		'promotion',
		'embed',
	]
	const persistentParams = persistentParamsForPartner.concat(persistentParamsForTracking)
	const overrideProhibitedPersistentParams = persistentParamsForTracking.slice(1) // List of persistent parameters which must never be overridden through user journey
	const hitBasisDataLayerProperties = [
		'target',
		'target-properties',
		'before',
		'searchTerm',
		'airport',
		'product',
		'terminal',
		'reservationDate',
		'reservationTime',
		'packageName',
		'packageValue',
		'loungeAccessType',
		'purchasableLoungesNr',
		'cancellationDate',
		'orderSize',
		'viewedPhotoCount',
		'quantity',
	]
	const disablePaymentRequest = true // Apple Pay / Payment Request button is disabled

	return {
		persistentParamsForTracking,
		persistentParamsForPartner,
		persistentParams,
		overrideProhibitedPersistentParams,
		hitBasisDataLayerProperties,
		disablePaymentRequest,
	}
}
