import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {useLocation} from 'react-router'

import useIframeResizer from './hooks/useIframeResizer'
import usePageview from './hooks/usePageview'
import {LBPropTypes} from './utils'

const Partners = ({initialLanguage, nextjsUrl, activeCurrency}) => {
	usePageview()
	const {t} = useTranslation('translation')

	const title = t('partners')

	const location = useLocation()
	const iframeUrl = new URL(`/${initialLanguage}/partners`, nextjsUrl)
	const iframeElement = useIframeResizer({
		title,
		location,
		iframeUrl,
		qeId: 'partners',
		currency: activeCurrency.code,
	})

	return (
		<div>
			<Helmet title={title} />
			{iframeElement}
		</div>
	)
}

Partners.propTypes = {
	t: PropTypes.func,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	location: LBPropTypes.location,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	const {hostUrl, initialLanguage, nextjsUrl, activeCurrency} = state
	return {
		hostUrl,
		initialLanguage,
		nextjsUrl,
		activeCurrency,
	}
}

export default connect(mapStateToProps)(Partners)
