import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const SearchIcon = props => (
	<IconBase viewBox="0 0 14 14" {...props}>
		<defs>
			<path id="SearchIconprefix__a" d="M0 0h16v16H0z" />
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="SearchIconprefix__b" fill={props.color || 'currentColor'}>
				<use xlinkHref="#SearchIconprefix__a" />
			</mask>
			<path
				d="M8 9.5a4 4 0 100-8 4 4 0 000 8zM8 0a5.5 5.5 0 011 10.91V15a1 1 0 01-2 0v-4.09A5.502 5.502 0 018 0z"
				fill={props.color || 'currentColor'}
				mask="url(#SearchIconprefix__b)"
				transform="rotate(-45 8 8)"
			/>
		</g>
	</IconBase>
)

export default SearchIcon
