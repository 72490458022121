import React from 'react'
import {withTranslation} from 'react-i18next'
import styled from 'styled-components'

const Msg = styled.div`
	position: relative;
	padding: 0;
	font-family: 'Avenir Next', 'Avenir Next Local', sans-serif, Arial;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: -0.1px;
	font-size: 13px;
	line-height: 16px;
	margin-right: 21px;
`

const Heading = styled.h2`
	border-bottom: 0 !important;
	padding: 0 !important;
`

const SubHeading = styled.div`
	position: relative;
	margin: 0 0 10px;
	padding: 0 0 8px;
	font-family: 'Avenir Next', 'Avenir Next Local', sans-serif, Arial;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: -0.17px;
	font-weight: 400;
	color: #22282b;
	text-transform: none;
	border-bottom: 1px solid #e8e9e9;
	margin-bottom: 0;
`

const Content = styled.div`
	margin-top: 5px;
	margin-bottom: 0;
	line-height: 16px;
	padding: 0;
	background: 0 0;
	border: 0;
	color: #047626;
	border-radius: 4px;
`

export default withTranslation('paymentHeader')(({t}) => (
	<>
		<Heading>
			<i className="fa fa-lock" aria-hidden="true" />
			{t('paymentDetails')}
		</Heading>
		<SubHeading>
			<Content>
				<Msg>
					<i className="fa fa-check" aria-hidden="true" />
					{t('useSecure')}
				</Msg>
				<Msg>
					<i className="fa fa-check" aria-hidden="true" />
					{t('protectInfo')}
				</Msg>
			</Content>
		</SubHeading>
	</>
))
