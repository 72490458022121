import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Helmet from 'react-helmet'
import { withTranslation } from 'react-i18next';

const PageNotFound = ({ t, staticContext = {} }) => {
	staticContext.status = 404;

	return (
		<div>
			<Helmet
				title={t('notFound.title')}
			/>
			<section className="page-content">
				<div className="container">
					<div className="content-section content-section-first">
						<p>{t('notFound.unableToFind')}</p>
					</div>
				</div>
			</section>
		</div>
	)
}
function mapStateToProps(state) {
	const { initialI18nStore, initialLanguage, hostUrl, error } = state;
	return { initialI18nStore, initialLanguage, hostUrl, error }
}

export default compose(
	connect(mapStateToProps),
	withTranslation('translation')
)(PageNotFound)
