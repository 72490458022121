import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {Title3Styles} from './Global/Typography'
import {media} from './Global/Breakpoints'
import {color} from './Global/Colors'
import {contentPadding} from '../constants'

export const SectionWrapper = styled.div`
	margin-bottom: 24px;
	border-top: 1px solid ${color.lightGreyLighter};

	${media.desktop`
		${p =>
			p.noSeparator &&
			css`
				border-top: 0;
			`}
	`}
	${media.tablet`
		width: calc(100% + (2*${p => p.padding}px));
		margin-left: -${p => p.padding}px;
		padding: 0 ${p => p.padding}px;
	`}
`
export const SectionTitle = styled.h2`
	${Title3Styles};
	display: block;
	padding-top: ${contentPadding}px;
	margin-bottom: 16px;
`

const DetailSection = ({title, children, inCheckout, ...rest}) => (
	<SectionWrapper {...rest} padding={inCheckout ? 16 : contentPadding}>
		{title && <SectionTitle style={inCheckout && {marginBottom: '8px'}}>{title}</SectionTitle>}
		{children}
	</SectionWrapper>
)

DetailSection.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	inCheckout: PropTypes.bool,
}

export default DetailSection
