import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Title3Styles} from './global/Typography'
import colors from './global/Colors'

import {globalPadding} from './global/Constants'
import {cumulativeOffset} from '../shared/util'
import {maxWidthMobile, maxWidthSmallMobile} from './global/Breakpoints'

const Wrap = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 8px 0 8px;
  background: ${colors.blondeDark};
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1301;

  ${p =>
    p.absolute &&
    css`
      position: absolute;
      right: 0px;
      margin-top: 14px;
    `}
  ${p =>
    p.width &&
    css`
      width: ${p.width}px;
    `}
`
const Title = styled.div`
  ${Title3Styles};
  color: ${colors.espressoDark};
  padding-bottom: 8px;
  margin: 0 8px 8px 8px;
  box-shadow: inset 0 -1px 0 0 ${colors.lightGreyLighter};
`
const Content = styled.ul`
  max-height: ${p => p.height}px;
  padding-bottom: 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const sidePadding = globalPadding * 2 // allow padding space

export default function Paper({
  title,
  children,
  height = 334,
  absolute,
  width,
  onItemClick,
}) {
  // limit to window height for viewport height, e.g. landscape might be smaller than `height`
  const [maxHeight, setMaxHeight] = useState(height)

  // adjust max width to mobile full width, otherwise cap at maxWidthSmallMobile
  const maxDropdownWidth =
    (window.innerWidth > maxWidthMobile
      ? maxWidthSmallMobile
      : window.innerWidth) -
    sidePadding

  return (
    <Wrap
      ref={el => {
        // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
        if (!el) return

        const {top} = cumulativeOffset(el)

        setMaxHeight(Math.min(window.innerHeight - top, height))
      }}
      absolute={absolute}
      width={Math.min(width, maxDropdownWidth)}>
      {title && <Title>{title}</Title>}
      {children && (
        <Content onClick={onItemClick} height={maxHeight - sidePadding}>
          {children}
        </Content>
      )}
    </Wrap>
  )
}

Paper.propTypes = {
  absolute: PropTypes.bool,
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  height: PropTypes.number,
  onItemClick: PropTypes.func,
}
