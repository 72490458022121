/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React from 'react'
import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {LBPropTypes} from '../utils'
import usePageview from '../hooks/usePageview'
import useIframeResizer from '../hooks/useIframeResizer'
import loadData from '../component/loadData'
import Cookies from 'universal-cookie'
import {clearQueryParam, fetchJson} from '../../util/universal'

const BookingReview = ({
	sourceSpecificBodyClassName,
	lounge,
	initialLanguage,
	// bookingInfo,
	error: errorProp,
	location,
	// hostUrl,
	activeCurrency,
	nextjsUrl,
}) => {
	const {t} = useTranslation('bookingReview')

	React.useEffect(() => {
		window.history.replaceState(null, '', window.location.pathname + clearQueryParam(location.search, 'currency'))
	}, [])

	usePageview()
	const otherQuerystrings = location.search ? `&${location.search.substring(1)}` : ''
	const iframeUrl = new URL(`/${initialLanguage}/booking-review/${lounge.id}?${otherQuerystrings}`, nextjsUrl)

	const iframeElement = useIframeResizer({
		title: t('title'),
		location,
		iframeUrl,
		qeId: 'booking-review',
		currency: activeCurrency.code,
	})

	if (errorProp) {
		return <div>Error: {errorProp}</div>
	}

	return (
		<div>
			<Helmet
				title={t('title')}
				bodyAttributes={{
					class: `page-spa ${sourceSpecificBodyClassName}`,
				}}
			/>

			{iframeElement}
		</div>
	)
}

BookingReview.propTypes = {
	sourceSpecificBodyClassName: PropTypes.string,
	lounge: LBPropTypes.lounge,
	location: LBPropTypes.location,
	bookingInfo: LBPropTypes.bookingInfo,
	hostUrl: PropTypes.string.isRequired,
	images: LBPropTypes.images,
	error: PropTypes.string,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	const {lounge, bookingInfo, initialI18nStore, initialLanguage, nextjsUrl, error, images, activeCurrency} = state
	return {lounge, bookingInfo, initialI18nStore, initialLanguage, nextjsUrl, error, images, activeCurrency}
}

export default compose(
	connect(mapStateToProps),
	loadData({
		dataLoaded: ({lounge, bookingInfo, error}) => (lounge && bookingInfo && bookingInfo.inventoryId) || error,
		updateData: location => {
			const cookies = new Cookies()
			const currency = cookies.get('currencyCode') || 'USD'
			const otherParams = location.search ? '&' + location.search.substring(1) : ''
			return fetchJson(`/api/checkout?currency=${currency}${otherParams}`)
		},
	})
)(BookingReview)
