import React from 'react'
import {useTranslation} from 'react-i18next'
import styled, {css} from 'styled-components'
import {BodyText, MinorText, Title3, LinkStyles, BodyTextStyles} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import DetailSection from '../component/Section'
import Popup from '../component/Popup'
import CircleCheckmarkIcon from '../component/LBIcons/icons/CircleCheckmark'
import CircleDollarIcon from '../component/LBIcons/icons/CircleDollar'
import {media} from '../component/Global/Breakpoints'
import {getComplimentaryAmenities} from './utils'
import PropTypes from 'prop-types'
import AmenityIcons from '../component/LBIcons/icons/amenities'

const Wrap = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const Item = styled.div`
	display: flex;
	align-items: center;
	width: 50%;
	margin-bottom: 16px;

	${media.mobile`
		width: 100%;
	`}
`
const IconWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-right: 16px;
	color: ${color.espressoMedium};
`
const Link = styled.a`
	${LinkStyles};
	${BodyTextStyles}
`
const ListSection = styled.div`
	margin-bottom: 24px;
`
const List = styled.ul`
	margin-top: 16px;
`
const ListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
	box-shadow: inset 0 1px 0 0 ${color.lightGreyLighter};

	&:last-child {
		box-shadow: inset 0 1px 0 0 ${color.lightGreyLighter}, inset 0 -1px 0 0 ${color.lightGreyLighter};
	}
`
const Info = styled.div`
	display: flex;
	align-items: center;
	flex: 1;

	${p =>
		p.notIncluded &&
		css`
			opacity: 0.25;
		`}
`
const Name = styled(BodyText)`
	${p =>
		p.notIncluded &&
		css`
			text-decoration: line-through;
		`}
`
const Type = styled.div`
	width: 16px;
	margin-left: 16px;
`

const AmenityType = PropTypes.shape({
	paid: PropTypes.bool,
	icons: PropTypes.shape({
		size24: PropTypes.string,
	}),
	name: PropTypes.string,
})

const Icon = ({amenity, t}) => (
	<IconWrap>
		{AmenityIcons[amenity.id]({
			role: 'presentation',
			title: amenity.name + (amenity.paid ? ` (${t('paid')})` : ''),
		})}
	</IconWrap>
)

Icon.propTypes = {
	amenity: AmenityType,
	t: PropTypes.func,
}

const Amenity = ({amenity, included, t}) => (
	<ListItem>
		<Info notIncluded={!included}>
			<Icon amenity={amenity} t={t} />
			<div>
				<Name notIncluded={!included}>{amenity.name}</Name>
			</div>
		</Info>
		{included && (
			<Type>
				{amenity.paid ? (
					<CircleDollarIcon size={16} color={color.green} />
				) : (
					<CircleCheckmarkIcon size={16} color={color.green} />
				)}
			</Type>
		)}
	</ListItem>
)

Amenity.propTypes = {
	amenity: AmenityType,
	included: PropTypes.bool,
	t: PropTypes.func,
}

export const AMENITY_CUTOFF = 6

const Amenities = ({onPopupOpen, loungeAmenities, amenities}) => {
	const {t} = useTranslation('loungeDetail')
	const [popupVisible, setPopupVisible] = React.useState(false)
	const popupButtonRef = React.useRef(null)

	const handlePopupOpen = e => {
		e.preventDefault()
		setPopupVisible(true)
		onPopupOpen()
	}

	const handlePopupClose = () => {
		setPopupVisible(false)
	}

	const renderComplimentaryAmenityItem = (amenity, index) => {
		return (
			<Item key={index}>
				<Icon amenity={amenity} t={t} />
				<BodyText>{amenity.name}</BodyText>
			</Item>
		)
	}

	const renderListSection = (amenities, title, description, included, t) => {
		if (!amenities || amenities.length === 0) {
			return null
		}

		return (
			<ListSection>
				{title && <Title3>{title}</Title3>}
				{description && <MinorText>{description}</MinorText>}
				<List>
					{amenities.map((amenity, i) => (
						<Amenity key={i} amenity={amenity} included={included} t={t} />
					))}
				</List>
			</ListSection>
		)
	}

	const removeFromNotIncluded = {
		'21+ Cardholder': 1,
		'18+ Cardholder': 1,
		'Upscale Dress Code Required': 1,
		'LoungeBuddy Check-In': 1,
	}

	const complimentaryAmenities = getComplimentaryAmenities(loungeAmenities)
	const paidAmenities = popupVisible && loungeAmenities.filter(item => item && item.paid)
	const {notIncludedAmenities} = popupVisible
		? amenities.reduce(
				(acc, val) => {
					const notIncluded = !loungeAmenities.some(have => have.name === val.name)
					const notMarked = !(val.name in acc.uniq)
					if (notIncluded && notMarked) {
						acc.uniq[val.name] = 1
						acc.notIncludedAmenities.push(val)
					}
					return acc
				},
				{uniq: removeFromNotIncluded, notIncludedAmenities: []}
		  )
		: {}

	return (
		<DetailSection title={t('topComplimentaryAmenities')}>
			<Wrap>{complimentaryAmenities.slice(0, AMENITY_CUTOFF).map(renderComplimentaryAmenityItem)}</Wrap>
			<Link ref={popupButtonRef} href="#" aria-haspopup="dialog" onClick={handlePopupOpen}>
				{t('viewMoreAmenities')}
			</Link>
			{popupVisible && (
				<Popup open={popupVisible} title={t('loungeAmenities')} onClose={handlePopupClose} button={popupButtonRef}>
					<div>
						{renderListSection(
							complimentaryAmenities,
							t('complimentaryAmenitiesTitle'),
							t('complimentaryAmenitiesDescription'),
							true,
							t
						)}
						{renderListSection(paidAmenities, t('paidAmenitiesTitle'), t('paidAmenitiesDescription'), true, t)}
						{renderListSection(notIncludedAmenities, t('notAvailable'), null, false, t)}
					</div>
				</Popup>
			)}
		</DetailSection>
	)
}

Amenities.propTypes = {
	loungeAmenities: PropTypes.arrayOf(AmenityType),
	amenities: PropTypes.arrayOf(AmenityType),
	onPopupOpen: PropTypes.func,
}

export default Amenities
