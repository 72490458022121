import React from 'react'
import {Experiment, OneXPClient} from '../../types/global'

export const OneXpContext = React.createContext({
	baseUrl: '',
	faasUrl: '',
})

/* example experiment
[
    {
        "id": "9f1dec73-9c3b-4b27-8c25-f1e0a91f4429",
        "name": "Airport Page Maps",
        "dimensions": [
            {
                "name": "Airport Page",
                "variant": "control"
            }
        ],
        "objectives": [
            {
                "name": "Clicks",
                "description": "The Click through rate for lounges, i.e. the number of conversions from airport page to lounge pages",
                "identifier": "AirportPage_Lounge_Clicks",
                "success": "",
                "objectiveKeys": []
            }
        ],
        "type": "AB",
        "tags": [],
        "locator": "(http|https):\\/\\/www.loungebuddy.[com|com.au|co.uk|fr|de|mx]+\\/[A-Z]{3}[\\?|\\/]*"
    }
]
*/
function OneXp() {
	const {baseUrl, faasUrl} = React.useContext(OneXpContext)

	const [sdk, setSdk] = React.useState<OneXPClient | null>(null)

	React.useEffect(() => {
		if (!window.oneXP || !baseUrl) {
			return
		}

		setSdk(
			window.oneXP.initialize({
				oneXPBaseURL: window.location.origin + '/api/onexp',
				oneXPFaasURL: window.location.origin + '/api/onexp',
			})
		)
	}, [baseUrl, faasUrl])

	// fix the function reference here to avoid multiple experiment notifications to oneXp
	const instance = React.useMemo(() => {
		async function getExperiments() {
			if (!sdk) {
				console.error("oneXP client hasn't been initialized")
				return []
			}

			return sdk
				.findVariants({
					locator: window.location.href,
					fetchOpts: {credentials: 'include'},
				})
				.then(experiments => {
					experiments.forEach(exp => {
						// indicate exp has been fetched
						window.trackIt.trackExperiment({
							id: exp.id,
							name: exp.name,
							dimensions: exp.dimensions.map(d => Object.assign(d, {impressed: false})),
							source: 'ONE XP',
						})
					})

					return experiments
				})
				.catch(err => {
					console.error('OneXp error: ', err)
					throw err
				})
		}

		function setImpressed(exp: Experiment) {
			if (!window.trackIt) {
				console.error("trackIt hasn't been initialized")
				return
			}

			window.trackIt.impressExperimentDimension({
				experimentName: exp.name,
				dimension: exp.dimensions[0].name,
			})
		}

		function trackEvent({identifier, element, type}: {identifier: string; element: string; type: string}) {
			if (!window.trackIt) {
				console.error("trackIt hasn't been initialized")
				return
			}

			window.trackIt.trackEvent({
				identifier, //: 'axp-app-name',
				element, //: 'some-component',
				type, //: 'impression',
			})
		}

		return {getExperiments, setImpressed, trackEvent}
	}, [sdk])

	return instance
}

export default OneXp
