import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paper from './Paper'
import {Button} from './Button'
import colors from './global/Colors'
import {maxWidthSmallMobile, media} from './global/Breakpoints'
import {useHoverClick} from './hooks/useHoverClick'
import {isClient} from '../shared/util'
import useOnClickOutside from 'use-onclickoutside'

const Wrap = styled.li`
  display: inline-block;
  position: relative;

  ${media.mobile`
    position: static;
    width: 32px;
  `}
`
const Arrow = styled.div`
  width: 16px;
  height: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  overflow: hidden;

  &:after {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    position: relative;
    right: -1px;
    top: 9px;
    border-radius: 2px;
    background: ${colors.blondeDark};
    border: 1px solid ${colors.lightGrey};
    border-bottom: 0;
    border-right: 0;
    transform: rotate(45deg);
  }
`
// arrow and paper need to be above modal so they don't get blocked when search results are open
const ArrowWrap = styled.div`
  width: 100%;
  height: 16px;
  position: absolute;
  z-index: 1302;
`

// debug true will disable hover
export const Menu = ({
  id,
  activeId,
  setActiveId,
  button,
  dropdownTitle,
  children,
  debug = false,
}) => {

  const isActiveRef = React.useRef(null)
  isActiveRef.current = activeId === id

  const setActive = () => setActiveId(isActiveRef.current ? null : id)

  const [leaveRef, buttonRef] = useHoverClick(debug, isActiveRef)

  // It works only on initial load now
  const innerWidth = isClient() ? window.innerWidth : maxWidthSmallMobile

  return (
    <Wrap ref={leaveRef}>
      <div style={{position: 'relative'}}>
        <Button
          innerRef={buttonRef}
          onClick={setActive}
          {...button}
          isActive={isActiveRef.current}
        />
        {isActiveRef.current && <ArrowWrap>
          <Arrow />
        </ArrowWrap>}
      </div>
      {isActiveRef.current && (
        <Paper
          onItemClick={setActive}
          absolute
          width={innerWidth}
          title={dropdownTitle}>
          {children}
        </Paper>
      )}
    </Wrap>
  )
}

Menu.propTypes = {
  id: PropTypes.number,
  activeId: PropTypes.string,
  setActiveId: PropTypes.func,
  button: PropTypes.object.isRequired,
  dropdownTitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  debug: PropTypes.bool,
}

const GroupWrap = styled.nav`
  display: inline-block;
  position: relative;

  > div {
    margin-right: 8px;

    ${media.mobile_small`
      margin-right: 0;
    `}

    &:last-child {
      margin-right: 0;
    }
  }
`

export const MenuGroup = React.memo(({text = {menuLabel: "Menu"}, items, debug}) => {
  const [activeId, setActiveId] = useState(null)

  const ref = useRef(null)
  useOnClickOutside(ref, () => {
    setActiveId(null)
  })

  return (
    <GroupWrap ref={ref} aria-label={text.menuLabel}>
      <ul>
        {items.map((item, i) => (
          <Menu debug={debug} key={i} id={i} {...item} activeId={activeId} setActiveId={setActiveId} />
        ))}
      </ul>
    </GroupWrap>
  )
})

MenuGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(Menu.propTypes)),
  innerRef: PropTypes.object,
  activeId: PropTypes.number,
  debug: PropTypes.bool,
  text: PropTypes.shape({
    menuLabel: PropTypes.string
  })
}
