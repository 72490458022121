import 'print-js/dist/print.min.css'

import './LoungeDetail/OtherLounges/slider.css'
import 'slick-carousel/slick/slick.css'

// move gallery styles here, todo: code splitting doesn't load css, try again later on with parceljs v2
// import 'react-photoswipe/lib/photoswipe.css'
import './component/Gallery/style.css'
import './BookingReview/Gallery/slick-styles.css'

import 'outdated-browser-rework/dist/style.css'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './styles/slider.css'

// Bootstrap link defaults rewrites - we can remove Bootstrap from Gulp file once we
// will use styled-components based layout for page (this is the only dependency)
import './component/GlobalStyles.css'
