import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import usePageview from '../hooks/usePageview'

import useIframeResizer from '../hooks/useIframeResizer'
import {LBPropTypes} from '../utils'

const About = ({t, initialLanguage, nextjsUrl, location, activeCurrency}) => {
	usePageview()

	const iframeUrl = new URL(`/${initialLanguage}/about`, nextjsUrl)
	const iframeElement = useIframeResizer({
		title: 'Find reservation',
		location,
		iframeUrl,
		qeId: 'aboutUs',
		currency: activeCurrency.code,
	})

	return (
		<div>
			<Helmet title={t('about.title')} />
			{iframeElement}
		</div>
	)
}

function mapStateToProps(state) {
	const {initialI18nStore, initialLanguage, hostUrl, error, nextjsUrl, activeCurrency} = state
	return {initialI18nStore, initialLanguage, hostUrl, error, nextjsUrl, activeCurrency}
}

About.propTypes = {
	t: PropTypes.func,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	location: LBPropTypes.location,
	activeCurrency: PropTypes.object,
}

export default compose(connect(mapStateToProps), withTranslation('translation'))(About)
