import { css } from 'styled-components'
import { breakpoints } from '../../constants'

// iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
	// use em in breakpoints to work properly cross-browser and support users
	// changing their browsers font-size: https://zellwk.com/blog/media-query-units/

	accumulator[label.toLowerCase()] = (...args) => css`
		@media (${breakpoints[label]}) {
			${css(...args)}
		}
	`
	return accumulator
}, {})
