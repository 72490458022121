import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M7.5 3c.167 1 .25 1.834.25 2.5 0 1-.25 1.5-.25 2.5S11 9 11 12v9a3 3 0 01-3 3H3a3 3 0 01-3-3v-9c0-3 3.5-3 3.5-4s-.25-1.5-.25-2.5c0-.666.083-1.5.25-2.5h4zm14.395 9a2 2 0 012 2l-.002.1-.008.099-.8 8a2 2 0 01-1.837 1.795l-.153.006h-6.19a2 2 0 01-1.97-1.65l-.02-.151-.8-8a2 2 0 011.791-2.19l.1-.007.099-.002h7.79zm0 1h-7.79l-.1.005a1 1 0 00-.9.978l.005.117.294 2.94 1.134.114-.002.048c0 .233.056.448.171.72l1.063 2.501c.138.326.277.534.505.717.225.182.496.291.785.317.29.026.535-.026.861-.164l2.502-1.063c.326-.138.534-.277.717-.505.182-.225.291-.496.317-.785.026-.29-.026-.535-.164-.861l-.111-.261 1.323.132.385-3.85.005-.1a1 1 0 00-1-1zm-3.045 2.539a.454.454 0 01.248.1c.072.058.125.123.212.329l1.062 2.502c.087.205.097.288.09.38a.454.454 0 01-.101.248c-.058.072-.123.125-.329.212l-2.502 1.062c-.205.087-.288.097-.38.09a.454.454 0 01-.248-.101.59.59 0 01-.171-.237l-1.103-2.594c-.087-.205-.097-.288-.09-.38a.454.454 0 01.101-.248.59.59 0 01.237-.171l2.594-1.103c.205-.087.288-.097.38-.09zM7.5 18h-4a.5.5 0 00-.09.992L3.5 19h4a.5.5 0 100-1zm-2-6C3.567 12 2 13.12 2 14.5S3.567 17 5.5 17 9 15.88 9 14.5 7.433 12 5.5 12zM6.745 0c.413 0 .58.046.743.134.163.087.291.215.378.378.088.163.134.33.134.743V2H3v-.745c0-.413.046-.58.134-.743a.909.909 0 01.378-.378C3.675.046 3.842 0 4.255 0h2.49z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
