import React from 'react'
import {withRouter} from 'react-router-dom'
import {compose, withState, branch, renderComponent, lifecycle, mapProps} from 'recompose'
import {getURLParameter} from '../../../util/universal'
import Error from './Error'
import Loading from './Loading'
import usePageview from '../../hooks/usePageview'
import {LoadingContext} from './loadingContext'

const queryString = require('query-string')
const config = require('../../../common/publishableConfig')()

export default ({dataLoaded, updateData}) =>
	compose(
		withRouter,
		withState('dataStore', 'updateDataStore', {}),
		withState('isUpdating', 'updateIsUpdating', false),
		lifecycle({
			componentDidMount() {
				const {location, updateDataStore, i18n, ...others} = this.props

				if (dataLoaded(others)) return

				// console.log('load data', this.props)
				updateData(location).then(function updateDidMount(json) {
					// console.log('got data', json)
					if (i18n) i18n.changeLanguage(json.initialLanguage)
					updateDataStore(json)
				})
			},
			componentWillReceiveProps(nextProps) {
				const {location} = nextProps
				const {location: prevLocation, updateDataStore, updateIsUpdating} = this.props
				// if (!location.pathname || !prevLocation.pathname) return;

				// if (location.pathname + location.search === prevLocation.pathname + prevLocation.search) return;
				if (this.props.location === location) return
				// console.log('load data loca', prevLocation, location)

				if (location.pathname === prevLocation.pathname) {
					//don't update data for filter querystring changes (at least for now, until we have paging)
					const parsedSearch = queryString.parse(location.search)
					const parsedPrevSearch = queryString.parse(prevLocation.search)
					const searchKeys = Object.keys(parsedSearch)
					const prevSearchKeys = Object.keys(parsedPrevSearch)
					const keys = Array.from(new Set(searchKeys.concat(prevSearchKeys)))
					if (keys.every(key => key.includes('filter_') || parsedSearch[key] === parsedPrevSearch[key])) return
				}

				//need to refresh data
				updateIsUpdating(true)

				updateData(location).then(function updateWillReceive(json) {
					// console.log('update data', json)
					if (nextProps.i18n) nextProps.i18n.changeLanguage(json.initialLanguage)
					updateDataStore(json)
					updateIsUpdating(false)
				})
			},
		}),
		mapProps(({dataStore, ...others}) => {
			const source = getURLParameter('source', others.location.search) || ''

			return {
				...others,
				...dataStore,
				source: source ? source : null,
				sourceSpecificBodyClassName: source ? `source-${source}` : '',
			}
		}),
		branch(props => props.error && !props.handled, renderComponent(Error)),
		branch(props => {
			const {setIsLoading} = React.useContext(LoadingContext)
			const isLoading = props.isUpdating || !dataLoaded(props)
			setIsLoading(isLoading)
			return isLoading
		}, renderComponent(Loading))
		// mapProps(props => {
		// 	usePageview(props)
		// 	return props
		// })
	)
