import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import LBLink from './LBLink'

const FooterTiny = ({t, ...rest}) => (
	<div className="lb-pay-footer" {...rest}>
		<div className="inner">
			<div className="right-side">
				<div className="link">
					<a rel="noopener noreferrer" href="https://support.loungebuddy.com/" target="_blank">
						{t('frequentQuestions')}
					</a>
				</div>
				<div className="link mob-only">
					<LBLink to="/terms-of-use" target="_blank">
						{t('termsAndConditions')}
					</LBLink>
				</div>
				<div className="link link--last">
					<a href="mailto:support@loungebuddy.americanexpress.com?subject=LoungeBuddy Feedback">{t('leaveFeedback')}</a>
				</div>
			</div>
			<div className="copyright">{t('copyright', {year: new Date().getFullYear()})}</div>
		</div>
	</div>
)

FooterTiny.propTypes = {
	t: PropTypes.func.isRequired,
}

export default withTranslation('footer')(FooterTiny)
