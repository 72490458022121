import React from 'react'
import styled, {css, keyframes} from 'styled-components'
import {MinorText} from '../Global/Typography'
import {color} from '../Global/Colors'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const Wrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: ${p => (p.height ? p.height + 'px' : '100%')};
	min-height: 200px;
	margin: auto;

	${p =>
		p.overlay &&
		css`
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(255, 255, 255, 0.6);
		`}
`
const Rect = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 36px;
	padding: 0 16px;
	margin: auto;
	background: ${color.white};
	border: ${p => (p.noBorder ? '0px' : '1px')} solid ${color.lightGrey};
	box-shadow: 0 1px 3px 0 ${color.lightGreyLighter};
	border-radius: 5px;
`
const Content = styled.div`
	display: flex;
`
const Indicator = styled.div`
	width: 16px;
	height: 16px;
	margin: auto;
	margin-right: ${p => (p.addMargin ? '10px' : '0px')};
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 3px solid ${color.lightGrey};
	border-right: 3px solid ${color.lightGrey};
	border-bottom: 3px solid ${color.lightGrey};
	border-left: 3px solid ${color.espressoMedium};
	border-radius: 50%;
	transform: translateZ(0);
	animation: ${rotate} 1.1s infinite linear;

	&:after {
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}
`

const Loading = ({msg, height, style, overlay, ...rest}) => (
	<Wrap height={height} overlay={overlay} style={style} {...rest}>
		<Rect noBorder={!msg}>
			<Content>
				<Indicator addMargin={Boolean(msg)} />
				<MinorText fontWeight="medium">{msg}</MinorText>
			</Content>
		</Rect>
	</Wrap>
)

export default Loading
