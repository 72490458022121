import React from 'react'
import PropTypes from 'prop-types'
import {core, Huntsman} from '@loungebuddy/lbds'
import LBLink from './LBLink'

const {LBButton} = core.components
const {buttonTheme} = Huntsman.themes
const actionTheme = {...buttonTheme.action, bgGradient: 'none', bgColorHover: '#065EAC', borderColorHover: '#065EAC'}

const Button = ({linkProps, children, theme = actionTheme, fontWeight = 600, btnRef, ...props}) => {
	return linkProps ? (
		<LBLink role="button" style={{textDecoration: 'none'}} {...linkProps}>
			<LBButton ref={btnRef} stylesOnly theme={theme} fontWeight={fontWeight} {...props}>
				{children}
			</LBButton>
		</LBLink>
	) : (
		<LBButton ref={btnRef} theme={theme} fontWeight={fontWeight} {...props}>
			{children}
		</LBButton>
	)
}

Button.propTypes = {
	children: PropTypes.node,
	linkProps: PropTypes.object,
	fontWeight: PropTypes.number,
	theme: PropTypes.object,
	btnRef: PropTypes.object,
}

export default Button
