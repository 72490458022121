import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {useLocation} from 'react-router'

import {termPagePathKeys} from '../util/constants'
import useIframeResizer from './hooks/useIframeResizer'
import usePageview from './hooks/usePageview'
import {LBPropTypes} from './utils'

const Terms = ({initialLanguage, nextjsUrl, activeCurrency}) => {
	usePageview()
	const {t} = useTranslation('terms')
	const location = useLocation()
	const {pathname} = location

	const key = termPagePathKeys[pathname]
	const title = t(`${key}.title`)

	const iframeUrl = new URL(`/${initialLanguage}${pathname}`, nextjsUrl)
	const iframeElement = useIframeResizer({
		title,
		location,
		iframeUrl,
		qeId: pathname.substring(1),
		currency: activeCurrency.code,
	})

	return (
		<div>
			<Helmet title={title} />
			{iframeElement}
		</div>
	)
}

Terms.propTypes = {
	t: PropTypes.func,
	initialLanguage: PropTypes.string,
	nextjsUrl: PropTypes.string,
	location: LBPropTypes.location,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	const {hostUrl, initialLanguage, nextjsUrl, activeCurrency} = state
	return {
		hostUrl,
		initialLanguage,
		nextjsUrl,
		activeCurrency,
	}
}

export default connect(mapStateToProps)(Terms)
