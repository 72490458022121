import React from 'react'
import styled, {css} from 'styled-components'
import {contentPadding, contentMaxWidth} from '../constants'
import {Title1Styles, Title2Styles, BodyTextStyles, fontWeight, LinkStyles} from './Global/Typography'
import {media} from './Global/Breakpoints'
import {color} from './Global/Colors'
import PropTypes from 'prop-types'

export const PageWrap = styled.section`
	display: block;
	clear: both;
	min-height: 200px;
	padding: ${contentPadding}px 0;
`
export const ContainerStyles = css`
	max-width: ${contentMaxWidth}px;
	margin: 0 auto;
	padding: 0 ${contentPadding}px;
	text-align: inherit;
	box-sizing: content-box;
`
export const Container = styled.div`
	${ContainerStyles};
`

export const PageContainer = ({children}) => (
	<PageWrap>
		<Container>{children}</Container>
	</PageWrap>
)

PageContainer.propTypes = {
	children: PropTypes.any,
}

const TitleStyles = css`
	color: ${color.blondeDark};
	font-weight: ${fontWeight.medium};
	margin: auto;
	margin-bottom: 24px;
`

const PageTypoBasics = css`
	h1 {
		${Title1Styles};
		${TitleStyles};
		${media.mobile`
			${Title2Styles};
			${TitleStyles};
		`}
	}
	h2,
	.page-locations h2 {
		${Title1Styles};
		margin-bottom: 8px;
	}
	h3 {
		${Title2Styles};
		margin-bottom: 8px;
	}
	p {
		${BodyTextStyles};
	}
`
export const Page = styled.div`
	${PageTypoBasics};
`
export const TermsPage = styled.div`
	${PageTypoBasics};
	h3 {
		margin: 32px 0 16px;
	}
	ul,
	ol {
		padding: 0 0 16px 24px;
	}
	ul {
		list-style: circle;
	}
	ol {
		padding-left: 24px;
		list-style: decimal;
	}
	ol.lowerRoman {
		list-style: lower-roman;
	}
	li,
	table {
		${BodyTextStyles};
	}
	li {
		margin: 16px 0;

		ul,
		ol {
			padding: 4px 0 24px 32px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	table {
		margin: 32px 0;

		li {
			margin: 0;
		}
	}
	strong {
		font-weight: ${fontWeight.bold};
	}
	a {
		overflow-wrap: break-word;
		${LinkStyles};
	}
`
