import React from 'react'
import IconBase from '../IconBase'
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const PlusCircleIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 26 26" width={props.size} height={props.size} {...props}>
			<defs>
				<path
					d="M13 11h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5V6a1 1 0 0 1 2 0v5z"
					id={id + 'a'}
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(1 1)">
					<circle stroke="#FFF" fill="#FFF" cx={12} cy={12} r={12.5} />
					<circle strokeOpacity={0.1} stroke={props.color || "currentColor"} cx={12} cy={12} r={12.5} />
				</g>
				<g transform="translate(1 1)">
					<mask id={id + 'b'} fill="#fff">
						<use xlinkHref={`#${id + 'a'}`} />
					</mask>
					<g mask={`url(#${id + 'b'})`} fill={props.color || "currentColor"}>
						<path d="M0 0h25v25H0z" />
					</g>
				</g>
			</g>
		</IconBase>
	)
}

export default PlusCircleIcon
