import React from 'react'
import { transformCloudinaryImage } from "../../../util/universal";
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import PropTypes from "prop-types";

const Picture = ({src, desktopParams, tabletParams, mobileParams, alt}) => {
	return (
		<LazyLoadComponent>
			<picture style={{display: 'block', minHeight: '1rem'}}>
				<source media="(min-width: 769px)" srcSet={ transformCloudinaryImage(src, desktopParams)} />
				<source media="(min-width: 421px)" srcSet={ transformCloudinaryImage(src, tabletParams)} />
				<img src={transformCloudinaryImage(src, mobileParams)} alt={alt} />
			</picture>
		</LazyLoadComponent>
	)
}

Picture.propTypes = {
	src: PropTypes.string,
	desktopParams: PropTypes.string,
	tabletParams: PropTypes.string,
	mobileParams: PropTypes.string,
	alt: PropTypes.string,
}

export default Picture
