import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {color} from '../component/Global/Colors'
import {minWidthDesktop} from '../constants'
import {useMediaQuery} from 'react-responsive'

const Wrap = styled.div`
	width: 352px;
	height: auto;
	margin-bottom: 84px;
	background: #ffffff;
	border: 1px solid ${color.lightGrey};
	box-shadow: 0 4px 8px 0 ${color.lightGreyLighter};
	border-radius: 8px;
	overflow: hidden;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
`

const PriceBreakdownWrap = ({children}) => {
	const isDesktop = useMediaQuery({minWidth: minWidthDesktop})
	if (!isDesktop) return null

	//This extra div is required because our flexbox layout (price breakdown is 100% height then)
	return (
		<div>
			<Wrap>{children}</Wrap>
		</div>
	)
}

PriceBreakdownWrap.propTypes = {
	children: PropTypes.element.isRequired,
}

export default PriceBreakdownWrap
