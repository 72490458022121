import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements'
import { getStripePaymentRequest } from './utils'
import { Pointer, paymentRequestButtonElementClasses, paymentRequestButtonElementStyles } from './styles'

// Support apple pay and pay with google
class PaymentRequestBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			checking: true,
			canMakePayment: false,
			paymentRequest: undefined,
		}
	}

	componentDidMount() {
		this.checkPaymentRequest()
	}

	componentDidUpdate(prevProps) {
		if (this.props.priceSummary.totalPrice !== prevProps.priceSummary.totalPrice) {
			this.checkPaymentRequest()
		}
	}

	checkPaymentRequest() {
		const { stripe, priceSummary, t, promotionAppliedDesc } = this.props
		const paymentRequest = stripe.paymentRequest(getStripePaymentRequest(priceSummary, promotionAppliedDesc, t))

		this.setState({ checking: true, paymentRequest })

		paymentRequest.on('token', ({ complete, token, payerName, payerEmail }) => {
			complete('success')
			this.props.onPaymentRequestTokenized({ token, payerName, payerEmail })
		})

		paymentRequest.canMakePayment().then((result) => {
			const canMakePayment = !!result
			this.setState({ checking: false, canMakePayment })
			if (!canMakePayment) {
				this.props.onPaymentAPIUnavailable()
			}
		})
	}

	render() {
		if (this.state.checking) {
			return <p>{this.props.t('checkingPaymentOptions')}</p>
		} else if (!this.state.canMakePayment) {
			return null
		}

		return (
			<span className="fields fields--pay">
				<span className="field field--card">
					<Pointer className="button button--light" onClick={this.props.onPayWithCardClick}>
						<span>{this.props.t('payWithCard')}</span>
					</Pointer>
				</span>
				<span className="field field--apple-pay">
					<PaymentRequestButtonElement
						paymentRequest={this.state.paymentRequest}
						classes={paymentRequestButtonElementClasses}
						style={paymentRequestButtonElementStyles}
						onClick={this.props.onPaymentRequestButtonClick}
					/>
				</span>
			</span>
		)
	}
}

PaymentRequestBlock.propTypes = {
	priceSummary: PropTypes.object.isRequired,
	promotionAppliedDesc: PropTypes.string,
	onPayWithCardClick: PropTypes.func.isRequired,
	onPaymentAPIUnavailable: PropTypes.func.isRequired,
	onPaymentRequestTokenized: PropTypes.func.isRequired,
	onPaymentRequestButtonClick: PropTypes.func.isRequired,
}

export default compose(
	withTranslation('checkout'),
	injectStripe
)(PaymentRequestBlock)
