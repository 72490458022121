import styled from 'styled-components'

export const BaseIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% auto;
`
export const BaseFlag = styled.span`
  display: inline-block;
  width: 20px;
  height: 14px;
  background-size: 100% auto;
`
