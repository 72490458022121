import React from 'react'
import { transformCloudinaryImage } from "../../../util/universal";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import PropTypes from "prop-types";

const Img = ({ src, cloudinaryParams, ...rest }) => {
	return (
		<LazyLoadImage
			src={transformCloudinaryImage(src, cloudinaryParams)}
			{...rest} />
	)
}

Img.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	cloudinaryParams: PropTypes.string
}

export default Img
