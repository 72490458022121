import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

const Svg = styled.svg`
	vertical-align: middle;
	margin-top: -1px;
	${p =>
		p.opacity ||
		(p.opacity === 0 &&
			css`
				opacity: ${p.opacity};
			`)}
	${p =>
		p.color &&
		css`
			color: ${p.color};
		`}
`

const IconBase = ({size = '1em', width, height, title, children, ...rest}) => (
	<Svg {...rest} fill="currentColor" preserveAspectRatio="xMidYMid meet" height={height || size} width={width || size}>
		{title && <title>{title}</title>}
		{children}
	</Svg>
)

IconBase.propTypes = {
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	style: PropTypes.object,
	title: PropTypes.string,
	children: PropTypes.element,
}

export default IconBase
