import styled from 'styled-components'

export const Pointer = styled.a`
	cursor: 'pointer';
`

export const paymentRequestButtonElementStyles = {
	// For more details on how to style the Payment Request Button, see:
	// https://stripe.com/docs/elements/payment-request-button#styling-the-element
	paymentRequestButton: {
		theme: 'dark',
	},
}

export const paymentRequestButtonElementClasses = { base: 'PaymentRequestButton' }
