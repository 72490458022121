import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const AirplaneIcon = props => (
	<IconBase viewBox="0 0 16 16" {...props}>
		<defs>
			<path
				d="M14.602 6.893C15.477 7.058 16 7.38 16 7.995c0 .614-.514.946-1.366 1.111-.643.125-1.671.189-3.1.195l-.847 1.154c.03.014.059.03.086.046a.82.82 0 01.412.722.82.82 0 01-.412.721c-.202.122-.451.172-.752.172h-.536l-.322.447a.819.819 0 01.506.768c0 .309-.154.57-.422.725-.203.118-.453.169-.752.169H7.99c-.35.575-.699 1-1.057 1.273A2.439 2.439 0 015.414 16h-.208a.5.5 0 01-.452-.714c.701-1.48 1.259-2.78 1.673-3.894.389-1.048.589-1.747.597-2.028-.006.054-.028.047-.126.012-.252-.09-.667-.155-1.234-.186a34.46 34.46 0 01-2.309-.195c-.09.098-.206.241-.345.426-.244.323-.563.781-.954 1.372a.5.5 0 01-.289.207c-.192.05-.388.094-.59.13-.195.035-.395.066-.6.094a.5.5 0 01-.531-.682c.187-.465.342-.898.467-1.299.073-.233.136-.46.19-.68a2.557 2.557 0 01-.272-.128.5.5 0 01.025-.893c.077-.036.158-.07.244-.1-.058-.233-.125-.47-.201-.714A15.891 15.891 0 00.037 5.46a.5.5 0 01.527-.685c.325.042.698.108 1.14.202.058.016.11.033.162.06a.5.5 0 01.192.171c.39.597.708 1.058.952 1.38.136.181.25.32.334.41a47.46 47.46 0 012.2-.169c.609-.035 1.056-.103 1.329-.198.136-.047.15-.054.151.005-.007-.269-.205-.96-.592-1.998-.411-1.103-.97-2.412-1.679-3.927A.5.5 0 015.206 0h.208c.57 0 1.082.168 1.518.502.358.274.707.698 1.057 1.273h.506c.299 0 .549.05.752.17a.819.819 0 01.422.724c0 .308-.154.569-.422.725a1.04 1.04 0 01-.084.043l.322.447h.536c.3 0 .55.05.752.172a.82.82 0 01.412.721.82.82 0 01-.412.722 1.01 1.01 0 01-.086.046l.846 1.154c1.391.006 2.41.07 3.069.194z"
				id="AirplaneIconprefix__a"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="AirplaneIconprefix__b" fill="#fff">
				<use xlinkHref="#AirplaneIconprefix__a" />
			</mask>
			<g mask="url(#AirplaneIconprefix__b)" fill={props.color || 'currentColor'}>
				<path d="M0 0h17v17H0z" />
			</g>
		</g>
	</IconBase>
)

export default AirplaneIcon