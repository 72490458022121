import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M11.93 4a8 8 0 110 16 8 8 0 010-16zm-8.5 0c.14 0 .254.11.259.25L3.859 9a1.93 1.93 0 01-1.338 1.907l.76 7.6a1.357 1.357 0 11-2.702 0l.76-7.6A1.93 1.93 0 01.001 9.001l.17-4.751A.26.26 0 01.43 4c.14 0 .257.11.265.25l.22 3.5a.266.266 0 00.53 0l.22-3.5a.266.266 0 01.53 0l.22 3.5a.266.266 0 00.53 0l.22-3.5A.266.266 0 013.43 4zm20.5 0v14.5a1.5 1.5 0 01-1.5 1.5 1.3 1.3 0 01-1.288-1.485L21.93 13a1 1 0 01-1-1V7a3 3 0 012.824-2.995L23.93 4zm-12 2a6 6 0 100 12 6 6 0 000-12zm0 1a5 5 0 110 10 5 5 0 010-10z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
