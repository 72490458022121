import React, {useEffect} from 'react'
import Helmet from 'react-helmet'
import {useTranslation} from 'react-i18next'
import usePageview from './hooks/usePageview'

const GetApp = () => {
	usePageview()
	const {t} = useTranslation('translation')

	useEffect(() => {
		if (/\b(iPad|iPhone|iPod)\b/i.test(window.navigator.userAgent)) {
			window.location =
				'https://itunes.apple.com/us/app/loungebuddy-find-airport-lounges/id674176920?mt=8&at=10ldrI&ct=website_app_page_redirect'
		}
	}, [])

	return (
		<div>
			<Helmet title={t('mobile.title')} />
		</div>
	)
}

export default GetApp
