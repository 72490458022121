const ns = require('./nsList.json')
module.exports = {
	ns,

	load: 'currentOnly',

	// default namespace (needs no prefix on calling t)
	defaultNS: 'translation',
	fallbackLng: 'en',
	preload: ['en'],

	backend: {
		loadPath: __dirname + '/{{lng}}/{{ns}}.json',
		addPath: __dirname + '/{{lng}}/{{ns}}.missing.json'
	}
}
