import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import {PriceLink, PriceInfo} from './Layout'
import {useMediaQuery} from 'react-responsive'
import Popup from '../component/Popup'
import {PRICING_BREAKDOWN_POPUP_STYLES, maxWidthTablet} from '../constants'
import {getNumOfInventoryString, getTotalPriceHtml} from '../LoungeDetail/utils'
import {useTranslation} from 'react-i18next'
import {trackConstants, trackEvent} from '../trackHelper'

export const MobilePriceBreakdown = ({howManyComing, priceSummary, priceBreakdown, defaultTrackingData}) => {
	const {t, i18n} = useTranslation('bookingReview')
	const [priceBreakdownPopupVisible, setPriceBreakdownPopupVisible] = useState(false)

	const togglePriceBreakdownPopup = useCallback(() => {
		setPriceBreakdownPopupVisible(wasVisible => {
			if (!wasVisible) {
				trackEvent(trackConstants.EVENT.openPriceBreakdownOnMobile, {
					...defaultTrackingData,
					numberOfInventoryItems: howManyComing,
				})
			}
			return !wasVisible
		})
	}, [])

	const isTablet = useMediaQuery({maxWidth: maxWidthTablet})
	if (!isTablet) return null

	return (
		<>
			<PriceInfo>
				<div
					dangerouslySetInnerHTML={{
						__html: t('totalPricePanel', {
							numOfTravelers: getNumOfInventoryString(t, howManyComing, 'lounge'),
							totalPrice: getTotalPriceHtml(priceSummary, i18n.language),
						}),
					}}
				/>
				<PriceLink onClick={togglePriceBreakdownPopup} role="button" aria-label={t('viewPriceBreakdown')}>
					{t('viewPriceBreakdown')}
				</PriceLink>
			</PriceInfo>
			<Popup
				open={priceBreakdownPopupVisible}
				title={t('priceBreakdown')}
				onClose={togglePriceBreakdownPopup}
				contentStyles={PRICING_BREAKDOWN_POPUP_STYLES}>
				<div>{priceBreakdown}</div>
			</Popup>
		</>
	)
}

MobilePriceBreakdown.propTypes = {
	howManyComing: PropTypes.number.isRequired,
	priceSummary: PropTypes.object.isRequired,
	priceBreakdown: PropTypes.any.isRequired,
	defaultTrackingData: PropTypes.object,
}
