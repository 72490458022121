import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import config from '../locales/config'
import {isClient} from './utils'

const options = {...config}

if (isClient) {
	i18n
		.use(XHR)
		.use(initReactI18next)
		.use(LanguageDetector)

	Object.assign(options, {
		preload: false,
		react: {
			// wait: true, // globally set to wait for loaded translations in translate hoc
			// exposeNamespace: true // exposes namespace on data-i18next-options to be used in eg. locize-editor
		},

		saveMissing: true,

		debug: process.env.NODE_ENV !== 'production',

		// cache: {
		//   enabled: true
		// },

		wait: true,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			formatSeparator: ',',
			format: function(value, format, lng) {
				if (format === 'uppercase') return value.toUpperCase()
				return value
			},
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
	})
}

if (!i18n.isInitialized) {
	// console.log('init', options)
	i18n.init(options)
}

export default i18n
