import React from 'react'
import styled from 'styled-components'
import {media} from '../component/Global/Breakpoints'
import {color} from '../component/Global/Colors'
import {
	BodyText,
	LinkStyles,
	BodyTextStyles,
} from '../component/Global/Typography'
import {SectionWrapper} from '../component/Section'
import ArrowIcon from '../component/LBIcons/icons/Arrow'
import { ContainerStyles } from '../component/Layout'
import { contentPadding } from '../constants'

export const Layout = styled.div`
	${ContainerStyles};
	display: flex;
	justify-content: space-between;
	padding-top: 48px;

	${media.tablet`
		padding-top: 24px;
		box-shadow: inset 0 4px 8px ${color.lightGreyLighter};
	`}
`
export const Content = styled.div`
	width: 100%;
	flex: 1;
	margin-right: 65px;

	${media.tablet`
		margin-right: 0;
	`}
`
export const BookingSummary = styled(SectionWrapper)`
	display: flex;
	justify-content: space-between;
	padding-top: 24px;

	${media.desktop`
		border-top: 0;
	`}
	${media.tablet`
		padding: 24px ${contentPadding}px 0 ${contentPadding}px;
	`}
`
export const PriceInfo = styled(BodyText)`
	margin-top: 4px;
	margin-bottom: 24px;
`
export const PriceLink = styled(BodyText)`
	${LinkStyles};
`
export const Actions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 24px 0 48px;
	${BodyTextStyles};

	${media.tablet`
		margin-bottom: 24px;
	`}
`
export const BackIcon = styled(ArrowIcon)`
	position: relative;
	top: -1px;
	margin-right: 8px;
	transform: rotate(180deg);
`
export const EditBtn = styled.a`
	display: block;
	${LinkStyles};
	${BodyTextStyles};
`
export const SummaryList = styled.ul`
	margin-top: 16px;
`
export const Item = styled.li`
    display: flex;
    align-items: center;
	margin-bottom: 15px;
	list-style: none;

	&:last-child {
		margin-bottom: 0;
	}
`
const Icon = styled.span`
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-top: -11px;
	margin-right: 16px;
`
export const SummaryItem = ({ icon, data }) => (
	<Item>
		{icon && <Icon>{icon}</Icon>}
		{data}
	</Item>
)
