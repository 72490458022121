/* eslint-disable max-lines */
import {
	defaults,
	merge,
	mergeWith,
	pick,
	pickBy,
	uniqueId,
	debounce,
	memoize,
	isFunction,
	mapValues,
	random,
	result,
	keyBy,
	groupBy,
	without,
	uniqBy,
	sortBy,
	has,
	last,
	get,
	range,
	isEmpty,
	union,
	omit,
	cloneDeep,
	takeRight,
	forOwn,
	minBy,
	throttle,
} from 'lodash'
import {getMinutes, set} from 'date-fns'
import {PROMOTION} from './constants'
import {isProduction} from './env'
import Keyv from 'keyv'
import QuickLRU from 'quick-lru'
import querystring from 'querystring'

// create a wrapper around lodash to make migrations easier in the future
export const _ = {
	defaults,
	merge,
	mergeWith,
	pick,
	pickBy,
	uniqueId,
	debounce,
	memoize,
	isFunction,
	mapValues,
	random,
	result,
	keyBy,
	groupBy,
	without,
	uniqBy,
	sortBy,
	has,
	last,
	get,
	range,
	isEmpty,
	union,
	omit,
	cloneDeep,
	takeRight,
	forOwn,
	minBy,
	throttle,
}

// this will skip already encoded strings
export function encodeStr(str) {
	const isEncoded = str !== decodeURIComponent(str)
	return isEncoded ? str : encodeURIComponent(str)
}

// ?n=asd#er
// ?n=df+we
export function getURLParameter(param, search) {
	search = search || (typeof window !== 'undefined' && window.location.search)
	if (!search || !param) return

	const m = new RegExp('[?&]' + param + '=([^&#]*)').exec(search)
	if (!m) return
	try {
		const val = m[1].replace(/\+/g, ' ').replace(/%u/g, '\\u')
		return encodeStr(val) // encode to prevent XSS
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('getURLParameter error', param, search, m)
	}
}

/*
 cache GET requests with LRU store
*/
const lru = new QuickLRU({maxSize: 1000})
const keyv = new Keyv({store: lru})

export async function fetchJson(url, {body, method, headers = {}, ttl} = {}) {
	if (typeof url === 'object') {
		body = url.body
		method = url.method
		headers = url.headers

		url = url.url // copy last
	}

	method = method || (body ? 'POST' : 'GET')

	if (method === 'GET') {
		const data = await keyv.get(url)
		if (data) {
			return data
		}
	}

	try {
		const response = await fetch(
			url,
			Object.assign(
				{
					method,
					headers: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						...headers,
					},
				},
				body ? {body: JSON.stringify(body)} : null
			)
		)

		const text = await response.text()
		if (!text) return {}

		const contentType = response.headers.get('content-type')
		const result = contentType?.includes('application/json') ? JSON.parse(text) : text

		// only cache GET data if there is a ttl
		if (method === 'GET' && ttl) {
			keyv.set(url, result, ttl)
		}

		return result
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('err', err)
	}
}

const localeDataCache = {}

export function extractProps(req) {
	const loc = req.locale

	//init cache
	if (!localeDataCache[loc]) {
		const data = req.i18n.services.resourceStore.data
		localeDataCache[loc] = _.merge({}, data['en'], data[loc])
	}

	return {
		//pass the host for canonical url
		hostUrl: _getProtocol(req) + '://' + req.get('host'),

		//i18n
		initialLanguage: loc,
		initialI18nStore: {[loc]: localeDataCache[loc]},
	}
}

function _getProtocol(req) {
	let protocol = req.connection.encrypted ? 'https' : 'http'
	if (req.headers['cf-visitor']) {
		const match = req.headers['cf-visitor'].match(/"scheme":"(http|https)"/)
		if (match) protocol = match[1]
	}
	if (req.headers['x-forwarded-proto']) {
		protocol = req.headers['x-forwarded-proto'].split(',')[0]
	}

	return protocol
}

//www.jacklmoore.com/notes/rounding-in-javascript/
export const round = (value, decimals = 0) => Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)

export const isWholeNumber = val => val % 1 === 0

// Get the nearest quarter-hour in future
export function roundUpDate(d = new Date(), nearestToMinutes = 15) {
	const minutes = Math.ceil(getMinutes(d) / nearestToMinutes) * nearestToMinutes
	return set(d, {minutes, seconds: 0, milliseconds: 0})
}

//work out price to charge
export function calculateTotal(loungeId, promotion, currency, howManyComing, chosenAvailability) {
	if (!chosenAvailability) {
		return {
			inventoryPrice: 0,
			originalTotalPrice: 0,
			totalPrice: 0,
			isSale: false,
			currency,
			howManyComing,
			promotion: {
				applied: false,
				discount: null,
			},
		}
	}

	const inventoryPrice = Number(chosenAvailability.price)
	const originalTotalPrice = round(howManyComing * inventoryPrice, 2)

	const totalPrice = getPriceWithPromotion(loungeId, originalTotalPrice, promotion, inventoryPrice)

	const promo =
		originalTotalPrice !== totalPrice
			? {
					...promotion,
					applied: true,
					discount: round(originalTotalPrice - totalPrice, 2),
			  }
			: {applied: false, discount: 0}

	return {
		inventoryPrice,
		originalTotalPrice,
		totalPrice,
		promotion: promo,
		isSale: promo.applied, //todo: why not just use promotion.applied?
		currency,
		howManyComing,
	}
}

/**
 * Calculate the promotional price if the promotion can be applied to the lounge
 * @param {string} loungeId lounge id
 * @param {number} originalPrice total price without discount
 * @param {object} promotion promo object
 * @param {number} inventoryPrice unit price
 * @return {number} promotional price
 */
export function getPriceWithPromotion(loungeId, originalPrice, promotion, inventoryPrice = 0) {
	if (!promotion || (promotion.loungeids && !promotion.loungeids.includes(loungeId))) {
		return originalPrice
	}

	const {type, value, scope, version, minimumSpend, maximumSave} = promotion
	const promotionValue = Number(value)

	if (version === 'v2' && minimumSpend > 0 && originalPrice < minimumSpend) {
		return originalPrice
	}

	let discountAmount = 0
	// per person uses inventory price instead of the booking total price
	if (type === PROMOTION.types.credit) {
		discountAmount = promotionValue
		if (scope === PROMOTION.scopes.person && promotionValue > inventoryPrice) {
			// cap at inventory price for person
			discountAmount = inventoryPrice
		}
	} else if (type === PROMOTION.types.discount) {
		discountAmount = ((scope === PROMOTION.scopes.person ? inventoryPrice : originalPrice) * promotionValue) / 100
	}

	if (version === 'v2' && maximumSave > 0 && discountAmount > maximumSave) {
		// cap at maxSave if present
		discountAmount = maximumSave
	}

	return Math.max(0, round(originalPrice - discountAmount, 2))
}

export function pad(str, padStr = '00') {
	return (padStr + str).slice(-padStr.length)
}

export function parseTime(time) {
	// Moment Parse Error for h:mm or hh:m
	if (time === 'now') return time
	if (!time || !time.includes(':')) return undefined
	const [h, m] = time.split(':')
	return pad(h) + ':' + pad(m)
}

export function parseDate(date) {
	// Convert M-D-yyyy format date to yyyy-M-D
	if (!date) return
	const [m, d, y] = date.split('-')
	return y && y + '-' + pad(m) + '-' + pad(d)
}

export function clearQueryParam(search, name) {
	if (!name) return search
	return search.replace(new RegExp(`${name}=[^&]*&?`), '').replace(/[?&]$/, '')
}

const _defaultCloudinaryParam = 'f_auto,q_auto'
export const CLOUDINARY_PARAMS = {
	default: _defaultCloudinaryParam,
	loungeDetailGallery: 'w_768,h_576,c_fill,' + _defaultCloudinaryParam,
	priceBreakdownGallery: 'w_680,h_250,c_fill,dpr_2.0,' + _defaultCloudinaryParam,
	tabletPriceBreakdownGallery: 'w_958,h_250,c_fill,dpr_2.0,' + _defaultCloudinaryParam,
}

export function transformCloudinaryImage(url, param = CLOUDINARY_PARAMS.default) {
	if (!url) return url

	if (url.includes(param)) return url

	return url
		.replace('http://', 'https://')
		.replace('cloudinary.com/loungebuddy/image/upload/', `cloudinary.com/loungebuddy/image/upload/${param}/`)
}

//todo: remove after api fix
export function stripConcourse(s) {
	return s
		.replace(/Concourse[^,.]*/i, '')
		.replace(/[,.]/, '')
		.trim()
}

// Lodash.defaults for collection
export function collectionDefaults(collection, other, key) {
	// Iterate over first array of objects
	return collection.map(obj => {
		// add the default properties from second array matching the key
		// to the object from first array and return the updated object
		return _.defaults(
			{},
			obj,
			other.find(a => a[key] === obj[key])
		)
	})
}

export function addQueryParamToUrl(url = '', parameterName, parameterValue, overrideExisting = true) {
	if (url instanceof URL) {
		url = url.href
	}

	const [urlNoHash, hash] = url.split('#')
	const [urlWithPath, params] = urlNoHash.split('?')

	const vals = querystring.decode(params)

	if (parameterName in vals && !overrideExisting) return url

	vals[parameterName] = parameterValue

	return `${urlWithPath}?${querystring.encode(vals)}${hash ? '#' + hash : ''}`
}

export function getLocaleLink(hostUrl, currentHost, locale, localeHostUrl, path) {
	return hostUrl.replace(currentHost, localeHostUrl) + addQueryParamToUrl(path, 'rd', locale === 'en' ? '0' : '')
}

export function getQueryModel(query) {
	if (!query) return {}

	const source = query.source || ''

	const model = {}

	if (source) {
		// Add Source-Specific Class to Body Tag
		model.bodyClasses = ['source-' + encodeStr(source)]
		model.source = source
	}

	const {embed} = query
	if (embed) {
		model.embed = embed
	}

	return model
}

export async function waitFor(ms) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

// exception won't break the next run
// guarantees at least `waitMs` milliseconds between each run
export function safeInterval(fn, waitMs, leading = false) {
	let _stop = false
	if (leading) {
		run()
	}

	function run() {
		try {
			fn()
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('safeInterval error', err)
		}
	}

	const runHelper = () => {
		const timeout = setTimeout(() => {
			if (_stop) {
				clearTimeout(timeout)
				return
			}

			run()
			runHelper()
		}, waitMs)
	}

	runHelper()

	// return a stopper
	return () => {
		_stop = true
	}
}

// expiry 0 means never expires
export function getCacheHelper(
	getVal,
	{_cache = {}, interval = 60000 * 10, expiry = 60000 * 20, logInfo = isProduction} = {}
) {
	// cache format, {[key]: {expiry: expiryTimestamp, response: cachedResponse}}

	// clean up expired entries
	const _cleanCache = () => {
		let count = 0
		Object.keys(_cache).forEach(key => {
			if (Date.now() >= _cache[key].expiry) {
				count += 1
				delete _cache[key]
			}
		})

		// eslint-disable-next-line no-console
		logInfo && console.info('Purged expired cache: ' + count)
	}
	const stopper = expiry === 0 ? noop : safeInterval(_cleanCache, interval)

	const fn = async (...args) => {
		// in cache
		if (args in _cache && (expiry === 0 || Date.now() < _cache[args].expiry)) {
			return _cache[args].response
		}

		const response = await Promise.resolve(getVal(...args))
		if (response) {
			_cache[args] = {
				expiry: Date.now() + expiry,
				response,
			}
		}
		return response
	}

	fn.stopCleaner = stopper
	fn.cleanCache = _cleanCache

	return fn
}

// try to clean up invalid path
export function sanitizePath(path) {
	return encodeURI(path.replace(/\/+/g, '/'))
}

export function getActiveCurrency(currencies, currencyCode) {
	if (!currencies[currencyCode]) {
		currencyCode = 'USD'
	}

	const activeCurrency = {...currencies[currencyCode]}
	activeCurrency.code = currencyCode

	// uniqSymbol is unique among all currencies so it's preferred
	const displaySymbol =
		(activeCurrency.uniqSymbol && activeCurrency.uniqSymbol.grapheme) ||
		(activeCurrency.symbol && activeCurrency.symbol.grapheme) ||
		''
	activeCurrency.displaySymbol = displaySymbol
	activeCurrency.label = activeCurrency.code

	return activeCurrency
}

export function intlNumber(number, locale, opt) {
	return new Intl.NumberFormat(locale, opt).format(number)
}

export const formatPrice = (price, language, currency) => {
	return intlNumber(price, language, {
		currency,
		style: 'currency',
		minimumFractionDigits: isWholeNumber(price) ? 0 : 2,
	})
}

export const iconIdPrefix = 'icon_'
