import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Title2} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import {POPUP_MAX_CONTENT_WIDTH, maxWidthTablet} from '../constants'
import {CLOUDINARY_PARAMS} from '../../util/universal'
import {useMediaQuery} from 'react-responsive'
import {SectionWrapper} from '../component/Section'
import {useTranslation} from 'react-i18next'

const Wrap = styled(SectionWrapper)`
	margin-bottom: 0;
`
const Title = styled(Title2)`
	margin: 24px 0 16px;
`
const BreakdownWrap = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	background: ${color.blondeDark};
	border: 1px solid ${color.lightGrey};
	box-shadow: 0 4px 8px 0 ${color.lightGreyLighter};
	border-radius: 8px;
	overflow: hidden;
	z-index: 2;
`
const ContentStyles = css`
	padding: 16px;
`

const MobilePriceSummary = ({priceBreakdown}) => {
	const showSmallerGallery = useMediaQuery({maxWidth: POPUP_MAX_CONTENT_WIDTH})
	const {t} = useTranslation('checkout')
	const isTablet = useMediaQuery({maxWidth: maxWidthTablet})
	if (!isTablet) return null

	return (
		<Wrap>
			<Title>{t('readyToBook')}</Title>
			<BreakdownWrap>
				{priceBreakdown(
					showSmallerGallery
						? CLOUDINARY_PARAMS.priceBreakdownGallery
						: CLOUDINARY_PARAMS.tabletPriceBreakdownGallery,
					ContentStyles
				)}
			</BreakdownWrap>
		</Wrap>
	)
}

MobilePriceSummary.propTypes = {
	priceBreakdown: PropTypes.any.isRequired,
}

export default MobilePriceSummary
