import React from 'react'
import colors from './global/Colors'
import {Input} from '@material-ui/core'
import styled, {css} from 'styled-components'
import {BodyTextStyles} from './global/Typography'

const Wrap = styled.div`
  flex: 1;
  position: relative;
`
const Icon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 100%;
  position: absolute;
  left: 16px;
  top: 0;
  color: ${colors.espressoMedium};
  pointer-events: none;
  transition: color 0.16s ease;
`
const BoxShadow = css`
  box-shadow: inset 0 0 0 1px ${colors.lightGrey},
    inset 0 1px 4px 0 ${colors.lightGrey};
`
const InputFocus = css`
  box-shadow: inset 0 0 0 2px ${colors.blue},
    inset 0 1px 4px 0 ${colors.lightGrey};
  background: ${colors.blondeDark};
`
const InputErrorShadow = css`
  box-shadow: inset 0 0 0 2px ${colors.red}, inset 0 1px 4px 0 ${colors.lightGrey};
`
const InputError = css`
  background: ${colors.inputErrorBg};
  ${InputErrorShadow};
`
const Placeholder = css`
  color: ${colors.espressoLight};
  opacity: 1;
  line-height: 24px;
`

export default styled(({icon, ...otherProps}) => (
  <Wrap>
    <Input icon={icon} {...otherProps} />
    {icon && <Icon>{icon}</Icon>}
  </Wrap>
))`
  display: flex;
  align-items: center;
  height: ${p => p.height ?? 48}px;
  padding: 0;
  background: ${colors.blondeDark};
  ${BoxShadow};
  border-radius: 5px;
  transition: box-shadow 0.16s ease, background-color 0.16s ease;
  box-sizing: border-box;

  .MuiInput-input {
    margin: ${p => (p.icon ? '0 56px 0 48px' : '0 16px')};
    padding: 0;
    ${BodyTextStyles};
    color: ${colors.espressoMedium};
    line-height: 24px;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
    height: 100%;

    &::-webkit-input-placeholder {
      ${Placeholder};
    }
    &::-moz-placeholder {
      ${Placeholder};
    }
    &:-ms-input-placeholder {
      ${Placeholder};
    }
    &:-moz-placeholder {
      ${Placeholder};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: inset 0 30px 0 0 white !important;
    }
  }

  ~ ${Icon} {
    color: ${colors.espressoLight};
  }

  &.Mui-focused {
    ${InputFocus};

    & ~ ${Icon} {
      color: ${colors.blue};
    }
  }
  &.Mui-error {
    ${InputError};

    & ~ ${Icon} {
      color: ${colors.red};
    }
  }
`
