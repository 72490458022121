import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const MailIcon = props => (
	<IconBase viewBox="0 0 16 16" {...props}>
		<g fill="none" fillRule="evenodd">
			<path fillOpacity={0} fill="#FFF" d="M0 0h16v16H0z" />
			<path
				d="M14 2a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V4a2 2 0 012-2h12zm.402 2.702a.5.5 0 00-.7-.104L8.301 8.604a.505.505 0 01-.602 0L2.298 4.598a.5.5 0 10-.596.804l5.402 4.005c.532.395 1.26.395 1.792 0l5.402-4.005a.5.5 0 00.104-.7z"
				fill={props.color || 'currentColor'}
			/>
		</g>
	</IconBase>
)

export default MailIcon
