import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Icon = styled.span`
	display: inline-block;
	margin-right: 8px;
`

const WithIcon = ({icon, children}) => (
	<span>
		<Icon>{icon}</Icon>
		{children}
	</span>
)

WithIcon.propTypes = {
	icon: PropTypes.element.isRequired,
	children: PropTypes.string.isRequired,
}

export default WithIcon
