import React from 'react'
import IconBase from '../IconBase'
import { color } from '../../Global/Colors';
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const CircleDollarIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 16 16" {...props}>
			<g transform="translate(1 1)" fill="none" fillRule="evenodd" id={id}>
				<rect
					stroke={props.color || 'currentColor'}
					x={-0.5}
					y={-0.5}
					width={15}
					height={15}
					rx={7.5}
				/>
				<g fill={props.color || 'currentColor'}>
					<path d="M5.759 5.436c0 .37.233.648.719.819.063.02.126.039.194.058V4.569c-.525.063-.913.365-.913.867zM8.392 8.51c0-.438-.243-.726-.894-.91l-.121-.03v1.876c.597-.054 1.015-.39 1.015-.936zM7.382 11h-.73v-.614C5.133 10.28 4.51 9.363 4.5 8.462h1.093c.03.497.394.891 1.079.979V7.429l-.398-.093c-.85-.2-1.633-.736-1.633-1.812 0-1.155.89-1.783 2.016-1.89V3h.73v.633c1.16.122 1.972.848 1.996 1.876H8.31c-.015-.443-.35-.848-.933-.935v1.89l.403.093c.991.229 1.72.716 1.72 1.846 0 1.17-.86 1.861-2.119 1.978V11z" />
					<path d="M6.5 3h1v8h-1z" />
				</g>
			</g>
		</IconBase>
	)
}

export default CircleDollarIcon
