import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M24 10v5.436c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267h-2.055l.54 4.314a1.5 1.5 0 01-1.16 1.65l-.142.024-.031.004-.024.002-.131.006H5.566a1.5 1.5 0 01-1.5-1.5l.001-.031v-.024l.011-.131L4.617 18H2.564c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 01-.757-.756C.093 16.65 0 16.327 0 15.437V10h24zm-6 5H6l-.125 1-.082.66-.723 5.778-.004.062a.5.5 0 00.41.492l.09.008h12.868l.062-.004a.5.5 0 00.437-.468l-.003-.09-.187-1.5h-.001l-.036-.283L18 15zm-.383 5l.125 1H6.257l.125-1h11.235zm-.25-2l.125 1H6.508l.124-1h10.735zm-.25-2l.125 1H6.757l.125-1h10.235zm3.176-3H3.707a.293.293 0 00-.249.447l.042.053.207.207a1 1 0 00.576.284l.131.009h15.172a1 1 0 00.608-.206l.099-.087.207-.207a.293.293 0 00-.207-.5zM17.5 0a1.5 1.5 0 011.493 1.356L19 1.5V2h1a1 1 0 011 1v3h.436c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54V9H0v-.436c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267H3V3a1 1 0 011-1h1v-.5A1.5 1.5 0 016.356.007L6.5 0h11zm4 7a.5.5 0 100 1 .5.5 0 000-1zm-2 0a.5.5 0 100 1 .5.5 0 000-1zm-2-6h-11a.5.5 0 00-.492.41L6 1.5V6h12V1.5a.5.5 0 00-.5-.5z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
