import {lazy} from 'react'
import {RouteRegex, termPagePathKeys} from '../../util/constants'

export default [
	{
		path: '/',
		exact: true,
		component: lazy(() => import('../Homepage')),
		key: 'homepage',
		siteMap: true,
	},
	{
		path: '/bookings/:orderId',
		component: lazy(() => import('../Booking')),
		key: 'booking',
	},
	{
		path: '/checkout',
		component: lazy(() => import('../Checkout')),
		key: 'checkout',
	},
	{
		path: '/booking-review',
		component: lazy(() => import('../BookingReview')),
		key: 'booking-review',
	},
	{
		path: '/([A-Z]{3})/([\\w-]*)',
		component: lazy(() => import('../LoungeDetail')),
		key: 'detail',
	},
	{
		path: '/airports',
		component: lazy(() => import('../Airports')),
		key: 'airports',
		siteMap: true,
	},
	{
		path: '/find-reservation',
		component: lazy(() => import('../FindReservation')),
		key: 'find-reservation',
		siteMap: true,
	},
	{
		path: RouteRegex.airportListing,
		component: lazy(() => import('../LoungeListing')),
		key: 'listing',
	},
	// {
	// 	path: '/([A-Za-z0-9]{2})(-.*)?',
	// 	component: lazy(() => import('../Brand')),
	// 	key: 'brand',
	// },
	{
		path: '/about/team',
		component: lazy(() => import('../About/Team')),
		key: 'about/team',
		siteMap: true,
	},
	{
		path: '/about',
		component: lazy(() => import('../About')),
		key: 'about',
	},
	{
		path: Object.keys(termPagePathKeys),
		component: lazy(() => import('../TermsPage')),
		key: 'terms',
		siteMap: true,
	},
	{
		path: '/partners',
		component: lazy(() => import('../Partners')),
		key: 'partners',
		siteMap: true,
	},
	// {
	// 	path: '/airline-lounges',
	// 	component: lazy(() => import('../AirlineLounges')),
	// 	key: 'airline-lounges',
	// 	siteMap: true,
	// },
	{
		path: '/airport-lounge-benefits',
		component: lazy(() => import('../AirportLoungeBenefits')),
		key: 'airport-lounge-benefits',
		siteMap: true,
	},
	{
		path: '/select/locations',
		component: lazy(() => import('../Select/Locations')),
		key: 'select/locations',
		siteMap: true,
	},
	{
		path: '/mobile',
		component: lazy(() => import('../GetApp')),
		key: 'mobile',
		siteMap: true,
	},
	// {
	// 	path: '/blog/:path?',
	// 	component: lazy(() => import('../Blog')),
	// 	key: 'blog',
	// },
]
