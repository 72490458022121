import React from 'react'
import IconBase from '../IconBase'
import {iconIdPrefix, _} from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const Close = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 16 16" {...props}>
			<defs>
				<path
					id={`${id}a`}
					d="M2.29536704,2.29536704 C2.68918977,1.90154432 3.32770256,1.90154432 3.72152528,2.29536704 L8,6.574 L12.2784747,2.29536704 C12.6722974,1.90154432 13.3108102,1.90154432 13.704633,2.29536704 C14.0681616,2.65889571 14.0961254,3.23091769 13.7885242,3.62652225 L13.704633,3.72152528 L9.426,8 L13.704633,12.2784747 L13.7885242,12.3734777 C14.0961254,12.7690823 14.0681616,13.3411043 13.704633,13.704633 C13.3108102,14.0984557 12.6722974,14.0984557 12.2784747,13.704633 L8,9.426 L3.72152528,13.704633 C3.32770256,14.0984557 2.68918977,14.0984557 2.29536704,13.704633 C1.93183837,13.3411043 1.90387463,12.7690823 2.21147581,12.3734777 L2.29536704,12.2784747 L6.574,8 L2.29536704,3.72152528 L2.21147581,3.62652225 C1.90387463,3.23091769 1.93183837,2.65889571 2.29536704,2.29536704 Z"></path>
			</defs>
			<g id={`${id}b`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id={`${id}c`} transform="translate(-2.000000, -2.000000)">
					<mask id={`${id}d`} fill="white">
						<use xlinkHref={`#${id}a`}></use>
					</mask>
					<use id={`${id}e`} fill="#5E6268" fillRule="nonzero" xlinkHref={`#${id}a`}></use>
				</g>
			</g>
		</IconBase>
	)
}

export default Close
