import React, {useCallback} from 'react'
import {tPluralContext} from '../utils'
import {isWholeNumber, _, intlNumber, formatPrice} from '../../util/universal'
import {PROMOTION} from '../../util/constants'
import {loungeAccessTypes, loungeAccessTypeIcons, maximumInventoryNumber} from '../constants'
import {InventoryTypes} from '../../shared/inventoryHelper'
import {trackConstants} from '../trackHelper'
import LBLink from '../component/LBLink'

export const formatTime = (input, t) => {
	if (typeof input !== 'string') return input

	const num = Number(input)
	if (!num && num !== 0) {
		// handle known codes
		return input.replace(/FD/i, t('firstDeparture')).replace(/LD/i, t('lastDeparture'))
	}

	const min = num % 100
	const hr = ((num - min) / 100) % 12
	return `${hr || 12}:${min < 10 ? '0' + min.toString() : min}${num < 1200 ? 'am' : 'pm'}`
}

export const getPromoDisplayValue = (promotion, lang, currency) => {
	const {value, type} = promotion
	const minimumFractionDigits = isWholeNumber(value) ? 0 : 2
	return type === 'discount'
		? intlNumber(value / 100, lang, {style: 'percent', minimumFractionDigits})
		: formatPrice(value, lang, currency)
}

export const getLoungeAccessInfos = t => {
	const accessUnknownDesc = (
		<span
			dangerouslySetInnerHTML={{
				__html: t('accessUnknownDesc'),
			}}
		/>
	)

	const AccessUnknownLink = () => {
		const onAppStoreClick = useCallback((e, trackCB) => {
			trackCB(trackConstants.EVENT.openAppStore)
		}, [])

		return (
			<LBLink
				id="Access Unknown Hover State"
				type="a"
				target="_blank"
				to="https://itunes.apple.com/us/app/loungebuddy-find-airport-lounges/id674176920?mt=8&at=10ldrI&ct=website_app_page_redirect"
				trackCallback={onAppStoreClick}>
				{t('getTheApp')}
			</LBLink>
		)
	}

	return {
		[loungeAccessTypes.PURCHASABLE]: {
			iconProps: {
				icon: loungeAccessTypeIcons.LB_NETWORK,
				boxShadow: '0 1px 3px 0 rgba(13,16,20,0.25)',
			},
			title: t('bookOnline'),
			description: t('bookOnlineDesc'),
			showInHeader: true,
		},
		[loungeAccessTypes.WALKINS_ACCEPTED]: {
			iconProps: {
				icon: loungeAccessTypeIcons.WALKINS,
				boxShadow: 'none',
			},
			title: t('walkInsAccepted'),
			description: t('nonPurchasableAccessDesc'),
			showInHeader: true,
		},
		[loungeAccessTypes.ACCESS_UNKNOWN]: {
			title: t('accessUnknown'),
			description: accessUnknownDesc,
			IconDescLink: AccessUnknownLink,
			showInHeader: false,
		},
	}
}

export const getComplimentaryAmenities = amenities => amenities.filter(item => item && !item.paid)

export const getCheapestInventory = inventories => _.minBy(inventories, 'price')

export const getTotalPriceHtml = (priceSummary, language) =>
	priceSummary.isSale
		? `<del>${formatPrice(priceSummary.originalTotalPrice, language, priceSummary.currency)}</del> <em>${formatPrice(
				priceSummary.totalPrice,
				language,
				priceSummary.currency
		  )}</em>`
		: `${formatPrice(priceSummary.totalPrice, language, priceSummary.currency)}`

export const getInventoryPriceHtml = (priceSummary, language, t, inventoryType) => {
	const inventoryPriceStr = formatPrice(priceSummary.inventoryPrice, language, priceSummary.currency)
	const inventoryKey = inventoryType === InventoryTypes.Room ? 'pricePerRoom' : 'pricePerTraveler'
	return priceSummary.isSale
		? priceSummary.promotion.scope === PROMOTION.scopes.person
			? `<del>${inventoryPriceStr}</del> <em>${t(`${inventoryKey}WithAdditional`, {
					priceForFirst: formatPrice(
						priceSummary.inventoryPrice - priceSummary.promotion.discount,
						language,
						priceSummary.currency
					),
					priceForAdditional: inventoryPriceStr,
			  })}</em>`
			: `<del>${inventoryPriceStr}</del> <em>${t(inventoryKey, {
					price: formatPrice(priceSummary.totalPrice / priceSummary.howManyComing, language, priceSummary.currency),
			  })}</em>`
		: t(inventoryKey, {price: inventoryPriceStr})
}

export const getLoungePricePerInventoryHtml = (loungeDisplayPrice, language, t, inventoryType) => {
	const inventoryKey = inventoryType === InventoryTypes.Room ? 'pricePerRoom' : 'pricePerTraveler'
	return loungeDisplayPrice.price >= 0
		? t(inventoryKey, {price: formatPrice(loungeDisplayPrice.price, language, loungeDisplayPrice.currencyCode)})
		: null
}

export const getSelectOptions = (t, inventoryType) => {
	return _.range(1, maximumInventoryNumber + 1).map(n => ({
		value: n,
		text: getNumOfInventoryString(t, n, inventoryType),
	}))
}

export const getNumOfInventoryString = (t, n, inventoryType) => {
	return inventoryType === InventoryTypes.Room ? t('entryRoom', tPluralContext(n)) : t('travelerWithCount', {count: n})
}

/*
	Keep string shorter than softLimit and end at a non-word boundary
 */
export const getLimitedString = (str, softLimit) => {
	if (softLimit >= str.length) return str

	const limitedStr = str.slice(0, softLimit)

	const matches = [...limitedStr.matchAll(/\W/g)] // match non-word character
	const end = matches.length ? matches[matches.length - 1].index : limitedStr.length
	return limitedStr.slice(0, end)
}
