import LBLink from '../LBLink'
import {collectionDefaults, getLocaleLink} from '../../../util/universal'
import React from 'react'
import {openChat} from '../../utils'
import {trackConstants, trackEvent} from '../../trackHelper'

export function getMenuData({
	t,
	activeCurrency,
	locale,
	locales,
	currencies,
	supportedLocales,
	hostUrl,
	currentHost,
	isCheckoutView,
	location,
}) {
	const {EVENT} = trackConstants

	const supportTracking = name => (e, track) => {
		track(name, {
			gtmParams: {
				category: 'Support',
			},
			sourceLink: 'supportMenu',
		})
	}

	const targetBlank = '_blank'
	const targetSelf = '_self'
	const defaultInfo = [
		{
			__id: 'manage_reservation',
			__href: '/find-reservation',
			__target: targetBlank,
			__icon: 'confirmation',
			trackName: EVENT.manageBooking,
		},
		{
			__id: 'common_questions',
			__href: 'https://support.loungebuddy.com/',
			__target: targetBlank,
			__icon: 'question-mark',
			trackName: EVENT.openFaqs,
		},
		{
			__id: 'leave_message',
			__href: 'https://support.loungebuddy.com/contact/contact-us--BkXNtUJd_',
			__target: targetBlank,
			__icon: 'email',
			trackName: EVENT.leaveAMessage,
		},
		{
			__id: 'live_chat',
			onClick: e => {
				openChat(e)
				supportTracking(EVENT.startLiveChat)(null, trackEvent)
			},
			__icon: 'chat-bubble',
			__class: 'live-chat',
		},
	]

	const supportDropdownItems = collectionDefaults(
		t('header.supportDropdownItems', {returnObjects: true}),
		defaultInfo,
		'__id'
	)

	const supportMenuData = supportDropdownItems.map(({__href, onClick, trackName, __target, title, subtitle}) => ({
		text: {
			title,
			description: subtitle,
		},
		WrapComponent: function SupportWrap(props) {
			return (
				<LBLink
					style={{textDecoration: 'none'}}
					to={__href}
					onClick={onClick}
					target={isCheckoutView ? __target : targetSelf}
					trackCallback={supportTracking(trackName)}
					{...props}
				/>
			)
		},
	}))

	const changeLocale = (e, trackCB) => {
		const locale = e.currentTarget.hreflang
		trackCB(EVENT.changeLanguage, {
			gtmParams: {
				category: 'Locale',
			},
			selectedLocale: locale,
		})
	}

	const changeCurrency = code => async e => {
		e.preventDefault()
		if (code === activeCurrency.code) return

		window.Cookies.set('currencyCode', code)

		await trackEvent(EVENT.changeCurrency, {
			gtmParams: {
				category: 'Currency',
			},
			currency: code,
			previousCurrency: activeCurrency.code,
		})

		window.location.reload()
	}

	const topCurrenciesMap = {
		USD: '1',
		EUR: '2',
		GBP: '3',
		AUD: '4',
		CAD: '5',
	}
	topCurrenciesMap[activeCurrency.code] = '0'
	const compareFunc = (a, b) => {
		return (topCurrenciesMap[a] || a).localeCompare(topCurrenciesMap[b] || b)
	}
	const currencyCodes = Object.keys(currencies).sort(compareFunc)

	const currencyMenuData = currencyCodes.map((code, i) => {
		const currency = currencies[code]
		return {
			key: i,
			symbol: code,
			text: {
				title: currency.name,
			},
			isSelected: activeCurrency.code === code,
			WrapComponent: function CurrencyWrapper(props) {
				return <a href="#" {...props} onClick={changeCurrency(code)} />
			},
		}
	})

	const path = location.pathname + location.search
	const linkByLocale = loc =>
		locales[loc] && locale !== loc ? getLocaleLink(hostUrl, currentHost, loc, locales[loc].host, path) : ''

	const localeMenuData = supportedLocales.map(loc => ({
		isSelected: locale === loc,
		WrapComponent: function LocaleWrapper(props) {
			return (
				<LBLink
					{...props}
					style={{textDecoration: 'none'}}
					to={linkByLocale(loc)}
					hrefLang={loc}
					trackCallback={changeLocale}
				/>
			)
		},
	}))

	return [
		{
			label: 'Support',
			title: 'Get Support',
			listItems: supportMenuData,
		},
		{
			label: activeCurrency.label,
			title: 'Change Currency',
			listItems: currencyMenuData,
		},
		{
			label: locales[locale].name,
			title: 'Select Language',
			listItems: localeMenuData,
		},
	]
}
