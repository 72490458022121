import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {media} from '../component/Global/Breakpoints'
import Input from '../component/Input'
import {useTranslation} from 'react-i18next'
import isEmail from 'validator/lib/isEmail'

const Wrap = styled.div`
	margin-top: 16px;
`
const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;

	${media.tablet`
		flex-direction: column;
	`}
`
const Col = styled.div`
	flex: 1;
	width: 50%;

	&:first-child {
		padding-right: 8px;
	}
	&:last-child {
		padding-left: 8px;
	}

	${media.tablet`
		width: 100%;

		&:first-child,
		&:last-child {
			padding: 0;
		}
		&:first-child {
			margin-bottom: 16px;
		}
	`}
`

const CustomerInfoForm = ({onTracking, formProps}) => {
	const {t} = useTranslation('checkout')
	const {register} = formProps

	return (
		<Wrap>
			<Row>
				<Col>
					<Input
						formRef={register({
							required: t('validFirstName'),
							validate: val => val.trim().length > 0 || t('validFirstName'),
						})}
						name="firstName"
						title={t('firstName')}
						placeholder="Jane"
						autoComplete="cc-given-name"
						{...formProps}
					/>
				</Col>
				<Col>
					<Input
						formRef={register({
							required: t('validLastName'),
							validate: val => val.trim().length > 0 || t('validLastName'),
						})}
						name="lastName"
						title={t('lastName')}
						placeholder="Doe"
						autoComplete="cc-family-name"
						{...formProps}
					/>
				</Col>
			</Row>
			<Row>
				<Input
					formRef={register({
						required: t('validEmail'),
						validate: email => {
							if (isEmail(email.trim())) {
								onTracking({key: 'email'})
							} else {
								return t('validEmail')
							}
						},
					})}
					type="email"
					name="email"
					title="Email Address"
					placeholder="janedoe@example.com"
					note={t('willSend')}
					autoComplete="email"
					{...formProps}
				/>
			</Row>
		</Wrap>
	)
}

CustomerInfoForm.propTypes = {
	onTracking: PropTypes.func,
	formProps: PropTypes.shape({
		register: PropTypes.func,
	}),
}

export default CustomerInfoForm
