import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M12 0c.799 0 1.703.102 2.71.305a4.8 4.8 0 013.7 3.51l.447 1.74.405 1.57A9.966 9.966 0 0122 14c0 5.523-4.477 10-10 10S2 19.523 2 14a9.966 9.966 0 012.738-6.875l.405-1.57.447-1.74a4.8 4.8 0 013.7-3.51C10.296.102 11.2 0 12 0zm2.446 11.24c-1.61 0-2.465 1.027-2.465 2.434v.605c0 1.328.7 2.477 2.453 2.477 1.653 0 2.301-1.032 2.301-2.075V13.67h-2.184v1.035h.739v.11c0 .347-.266.698-.817.698-.738 0-.93-.585-.93-1.242v-.578c0-.777.31-1.21.891-1.21.492 0 .762.308.805.648h1.492c-.016-.934-.742-1.891-2.285-1.891zm-5.643.09H7.26v5.332h1.543v-1.5l.402-.492 1.176 1.992h1.793l-1.844-3.004 1.793-2.328h-1.62l-1.63 2.14h-.07v-2.14zM12 2c-1.543 0-2.836.292-3.879.875-.428.24-.692.69-.692 1.18 0 .366.271.668.624.716l.078.005A9.97 9.97 0 0112 4a9.97 9.97 0 013.869.776l-.02.002a.722.722 0 00.722-.722c0-.49-.264-.942-.692-1.181C14.836 2.292 13.543 2 12 2z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
