/* eslint-disable no-var */
import {Huntsman} from '@loungebuddy/lbds'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, {useState} from 'react'
import Helmet from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {FEATURE_TOGGLES} from '../../../util/constants'
import {getActiveCurrency, getQueryModel} from '../../../util/universal'
import {LBPropTypes, addLBPersistentQueryString, isClient} from '../../utils'
import {color} from '../Global/Colors'

import {getMenuData} from './helper'

const HeaderO = ({
	activeCurrency: _activeCurrency = {},
	currencies,
	t,
	locales,
	locale,
	supportedLocales,
	headerWrapperClasses,
	children,
	isCheckoutView,
	currentHost,
	hostUrl,
	hideHeader,
}) => {
	const location = useLocation()
	const history = useHistory()
	const [activeCurrency] = useState(() => {
		if (isClient) {
			const currencyCode = window.Cookies.get('currencyCode')
			return getActiveCurrency(currencies, currencyCode)
		} else {
			return _activeCurrency
		}
	})

	const [chatOnline, setChatOnline] = useState(false)

	React.useEffect(() => {
		window.Kustomer.start({brandId: '60624fe1ee8abdeb8cb7bdd5', hideChatIcon: true}, () => {
			setChatOnline(window.Kustomer.isChatAvailable().availability === 'online')
		})
	}, [])

	const locationSearchQuery = queryString.parse(location.search)
	const qModel = getQueryModel(locationSearchQuery)

	const isKioskMode = locationSearchQuery.lbfid === FEATURE_TOGGLES.kiosk.id
	const menuData = Huntsman.utils.buildMenuItems(
		getMenuData({
			t,
			location,
			activeCurrency,
			currencies,
			locales,
			hostUrl,
			currentHost,
			isCheckoutView,
			locale,
			supportedLocales,
		})
	)

	if (!chatOnline) {
		delete menuData[0].children[3] // remove liveChat from the support menu
	}

	if (isKioskMode) {
		delete menuData[0] // Remove support button from header on checkout page for Hong Kong Centurion Lounge traveler add scenario
	}

	const onBrandClick = () => {
		if (isKioskMode) return // stop travelers at a kiosk from navigating away
		history.push(addLBPersistentQueryString(location, '/'))
	}

	const {Header} = Huntsman.components

	return (
		<div
			className={classNames('header-wrapper', 'brand-bgcolor', ...headerWrapperClasses, qModel.headerWrapperClasses)}>
			<Helmet bodyAttributes={{class: classNames(qModel.bodyClasses)}} />

			{!hideHeader && (
				<div
					style={{
						backgroundColor: color.blondeDark,
						// borderBottom: `1px solid ${color.lightGrey}`,
						// boxShadow: `0 4px 8px 0 ${color.lightGreyLighter}`,
					}}>
					<Header
						text={{
							loungeBuddy: <span dangerouslySetInnerHTML={{__html: t('logoText')}} />,
						}}
						items={menuData}
						onBrandClick={onBrandClick}
					/>
				</div>
			)}

			{children}
		</div>
	)
}

const currencyPropType = PropTypes.shape({
	code: PropTypes.string,
	name: PropTypes.string,
	uniqSymbol: PropTypes.shape({
		grapheme: PropTypes.string,
	}),
})

HeaderO.propTypes = {
	t: PropTypes.func.isRequired,
	isCheckoutView: PropTypes.bool,
	currencies: PropTypes.objectOf(currencyPropType),
	activeCurrency: currencyPropType,
	location: LBPropTypes.location,
	children: PropTypes.node,
	currentHost: PropTypes.string,
	hostUrl: PropTypes.string,
	locale: PropTypes.string,
	locales: PropTypes.object,
	supportedLocales: PropTypes.arrayOf(PropTypes.string),
	headerWrapperClasses: PropTypes.arrayOf(PropTypes.string),
	showInfoBanner: PropTypes.bool,
	hideSearchBar: PropTypes.bool,
}

export default withTranslation('translation')(HeaderO)
