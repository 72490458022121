import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const TicketIcon = props => (
	<IconBase viewBox="0 0 16 16" {...props}>
		<defs>
			<path
				d="M14.4.5A1.6 1.6 0 0116 2.1v3.275c-.884 0-1.6.728-1.6 1.625s.716 1.625 1.6 1.625V11.9a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 11.9V8.625c.884 0 1.6-.728 1.6-1.625S.884 5.375 0 5.375V2.1A1.6 1.6 0 011.6.5h12.8zM7.31 3.75h-.218a.4.4 0 00-.392.322l-.288 1.435h-.61a.4.4 0 00-.393.326l-.041.218a.4.4 0 00.393.475h.45l-.244 1.237h-.643a.4.4 0 00-.393.327l-.043.228a.4.4 0 00.394.474h.484l-.264 1.356a.4.4 0 00.393.477h.218a.4.4 0 00.392-.322l.301-1.511h1.097l-.267 1.355a.4.4 0 00.393.478h.225a.4.4 0 00.392-.323l.298-1.51h.66a.4.4 0 00.393-.329l.042-.228a.4.4 0 00-.394-.472h-.504l.248-1.237h.698a.4.4 0 00.394-.328l.04-.219a.4.4 0 00-.394-.472h-.536l.252-1.28a.4.4 0 00-.392-.477h-.225a.4.4 0 00-.393.323L8.55 5.507H7.453l.25-1.28a.4.4 0 00-.393-.477zm1.123 2.69l-.286 1.414H6.919L7.2 6.44h1.233z"
				id="prefix__a"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<path fillOpacity={0} fill="#FFF" d="M0 0h16v16H0z" />
			<g transform="translate(0 1)">
				<mask id="prefix__b" fill="#fff">
					<use xlinkHref="#prefix__a" />
				</mask>
				<g mask="url(#prefix__b)" fill={props.color || 'currentColor'}>
					<path d="M0-1h17v17H0z" />
				</g>
			</g>
		</g>
	</IconBase>
)

export default TicketIcon
