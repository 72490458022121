import {useEffect} from 'react'
import {useLocation, useRouteMatch} from 'react-router'
import {RouteRegex} from '../../util/constants'
import {getLoungeAccessType} from '../utils'
import {AmplitudeRun, trackConstants, trackEvent} from '../trackHelper'
import {loungeAccessTypeNameMapForTracking} from '../constants'
import {AMENITY_CUTOFF} from '../LoungeDetail/Amenities'
import {getURLParameter, clearQueryParam} from '../../util/universal'

// convert absolute urls from loungebuddy.com to just pathname
function _getDomainPathname(url) {
	const hostname = window.location.hostname
	const startIndex = url.indexOf(hostname + '/')

	const path = startIndex > -1 ? url.substring(startIndex + hostname.length) : url

	const qIndex = path.indexOf('?')
	return qIndex > -1 ? path.substring(0, qIndex) : path
}

let _previousLocation // for internal referrer
export default function usePageview({
	airport,
	lounge,
	otherLounges,
	airline,
	purchasableLounges,
	regularLounges,
	reviews,
	images,
	additionalPageViewParams,
} = {}) {
	const location = useLocation()
	const isAirportPage = useRouteMatch({path: RouteRegex.airportListing})
	const isLoungeDetailPage = useRouteMatch({path: RouteRegex.loungeDetailPage})
	const isBrandPage = useRouteMatch({path: RouteRegex.brandPage})

	useEffect(() => {
		;(async () => {
			// Trigger locationChanged jquery event on location change
			document.dispatchEvent(new Event('locationChanged'))

			if (process.env.NODE_ENV === 'development') {
				console.log('[dev] page view')
			}

			const pageViewParams = {
				sourceUrl: _getDomainPathname(_previousLocation ? _previousLocation.pathname : document.referrer),
				sourceLink: location?.state?.sourceLink,
			}

			if (pageViewParams.sourceUrl.startsWith('http')) {
				pageViewParams.referrer = pageViewParams.sourceUrl
			}

			if (isAirportPage && airport) {
				pageViewParams.airportIATACode = airport.displayIATA
				pageViewParams.airportName = airport.name
				pageViewParams.pageType = trackConstants.PAGE_TYPE.airport
				pageViewParams.numOfLoungesAtAirport = purchasableLounges.length + regularLounges.length
				pageViewParams.loungeOptions =
					purchasableLounges.length + regularLounges.length === 0
						? 'no lounges'
						: regularLounges.length === 0
						? 'bookable only'
						: purchasableLounges.length === 0
						? 'non-bookable only'
						: 'both bookable and non-bookable'

				AmplitudeRun(
					trackConstants.AMPLITUDE_ACTION.append,
					trackConstants.USER_PROPS.airportsViewed,
					airport.displayIATA
				)
			} else if (isLoungeDetailPage && lounge && otherLounges) {
				pageViewParams.airportIATACode = lounge.displayIATA
				pageViewParams.airportName = lounge.airport.name
				pageViewParams.terminalName = lounge.location.terminal
				pageViewParams.productName = lounge.name
				pageViewParams.loungeAccessType = loungeAccessTypeNameMapForTracking[getLoungeAccessType(lounge)]
				pageViewParams.numOfLoungesAtTerminal = otherLounges.length + 1
				pageViewParams.numOfLoungesAtTerminalPurchasable = otherLounges.filter(lounge => lounge.purchasable).length
				pageViewParams.pageType = trackConstants.PAGE_TYPE.loungeDetail
				pageViewParams.numOfFreeAmenities = lounge.amenities.filter(v => !v.paid).length
				pageViewParams.amenities = lounge.amenities.map(v => v.id)
				pageViewParams.amenitiesOnLoungePage = lounge.amenities.slice(0, AMENITY_CUTOFF).map(v => v.id)
				pageViewParams.numOfPhotosAvailable = images.length
				pageViewParams.numOfReviewsAvailable = reviews.length
				pageViewParams.recentReviewDate = reviews.length ? reviews[0].createdAt : null
				pageViewParams.loungeId = lounge.id

				const stateStr = getURLParameter('state', location.search)
				const state = stateStr
					? JSON.parse(decodeURIComponent(stateStr)) // desktop uses querystring
					: Object.keys(location.state || {}).length
					? location.state // for mobile and tablet
					: {}
				Object.assign(pageViewParams, state) // track everything on state

				// remove internal state param from url after it's stored in pageViewParams
				window.history.replaceState(null, '', window.location.pathname + clearQueryParam(location.search, 'state'))
			} else if (isBrandPage && airline) {
				pageViewParams.brandName = airline.shortName
			}

			//only fire pageview if the path is different, not for querystring change
			if (!_previousLocation || _previousLocation.pathname !== location.pathname) {
				let params = additionalPageViewParams
				if (typeof additionalPageViewParams === 'function') {
					params = await additionalPageViewParams()
				}
				trackEvent(trackConstants.EVENT.pageView, Object.assign(pageViewParams, params))
			}

			_previousLocation = location
		})()
	}, [location])
}
