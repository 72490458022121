import {useEffect, useRef} from 'react'
import {isTouchDevice} from '../../shared/util'

//debug will disable hover
export function useHoverClick(debug, isActiveRef) {
  const leaveRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (debug) return

    const button = buttonRef.current
    const leaveElement = leaveRef.current
    if (!button || !leaveElement || isTouchDevice()) return

    const handleEnter = () => {
      if(!isActiveRef.current)
        button.click()
    }

    const handleLeave = () => {
      if(isActiveRef.current)
        button.click()
    }

    leaveElement.addEventListener('mouseenter', handleEnter)
    leaveElement.addEventListener('mouseleave', handleLeave)
    return () => {
      leaveElement.removeEventListener('mouseenter', handleEnter)
      leaveElement.removeEventListener('mouseleave', handleLeave)
    }
  }, [leaveRef, buttonRef, debug])

  return [leaveRef, buttonRef]
}
