import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M18.72 20a1 1 0 01.97.757l.5 2A1 1 0 0119.22 24H4.78a1 1 0 01-.97-1.243l.5-2A1 1 0 015.28 20h13.44zm2.56.5a1 1 0 01.948.684l.456 1.367A.72.72 0 0122 23.5h-.28a1 1 0 01-.948-.684l-.456-1.367A.72.72 0 0121 20.5h.28zM7 22a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zM6 21a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm2 0a.5.5 0 100 1 .5.5 0 000-1zm-1.908-4.122c.531.749 1 1.122 1.408 1.122a.5.5 0 110 1h-11a.5.5 0 110-1c.407 0 .877-.373 1.409-1.12 1.323.08 2.687.12 4.091.12 1.404 0 2.768-.04 4.092-.122zM12 0c2.283 0 4.457.109 6.523.326A5 5 0 0123 5.298v5.404a5 5 0 01-4.477 4.972c-2.066.217-4.24.326-6.523.326-2.283 0-4.457-.109-6.523-.326A5 5 0 011 10.702V5.298A5 5 0 015.477.326 62.449 62.449 0 0112 0zm2.328 5.24c-1.555 0-2.41.977-2.41 2.5v.512c0 1.523.855 2.504 2.41 2.504 1.312 0 2.253-.77 2.261-1.946v-.215h-1.46c-.009.512-.282.903-.79.903-.531 0-.84-.434-.84-1.235V7.74c0-.805.309-1.242.84-1.242.504 0 .781.39.79.91h1.46v-.211c-.008-1.187-.949-1.957-2.261-1.957zm-4.53.09H7.415v5.332h1.54v-1.5h.823c1.32 0 1.957-.867 1.957-1.902 0-1.016-.62-1.93-1.937-1.93zm-.403 1.148c.473 0 .782.274.782.782 0 .5-.309.78-.782.78H8.95V6.479h.445z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
