import styled, {css} from 'styled-components'
import Input from '../component/Input'

export const styles = {
	half: {
		width: '49%',
	},
	withGapRight: {
		marginRight: '2%',
	},
	pointer: {
		cursor: 'pointer',
	},
}

export const Flexbox = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
`
const rowStyle = css`
	flex-basis: 100%;
	padding-bottom: 20px;
`
export const FlexInput = styled(Input)`
	${rowStyle}
`
export const FlexStrong = styled.strong`
	${rowStyle}
`
