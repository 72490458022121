import React from 'react'
import styled from 'styled-components'
import colors from '../../components/global/Colors'
import Brand from '../../components/Brand'
import {MenuGroup} from '../../components/Menu'
import {InnerWrap} from '../../components/Header'

const Wrap = styled.header`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  background: ${colors.blondeDark};
  border: 1px solid rgba(25, 31, 39, 0.1);
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${InnerWrap}
`

function Header({text, items, onBrandClick, debug}) {
  return (
    <Wrap>
      <HeaderWrap>
        <Brand text={text} onBrandClick={onBrandClick} />
        <MenuGroup text={text} debug={debug} items={items} />
      </HeaderWrap>
    </Wrap>
  )
}

Header.propTypes = {
  ...Brand.propTypes,
  ...MenuGroup.propTypes,
}

export default Header
