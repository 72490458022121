import React from 'react'
import USFlag from './Flags/UnitedStates'
import UKFlag from './Flags/UnitedKingdom'
import AUFlag from './Flags/Australia'
import DEFlag from './Flags/Germany'
import FRFlag from './Flags/France'
import MXFlag from './Flags/Mexico'

export default {
  us: <USFlag />,
  gb: <UKFlag />,
  au: <AUFlag />,
  de: <DEFlag />,
  fr: <FRFlag />,
  mx: <MXFlag />,
}
