import React from 'react'
import { uniqueId } from 'lodash'

// create a wrapper around lodash to make migrations easier in the future
export const _ = {
  uniqueId,
}

// used for the stories
export const ListHOC = Component => items =>
  items.map(({id, ...rest}, i) => <Component key={id || i} id={id} {...rest} />)

export function isTouchDevice() {
  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0
  }

  if ('msMaxTouchPoints' in navigator) {
    return navigator.msMaxTouchPoints > 0
  }

  const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  if (iOS) {
    return true
  }

  const mQ = window.matchMedia && matchMedia('(pointer:coarse)')
  return mQ && mQ.media === '(pointer:coarse)'
}

/**
 * Get the absolute position of the element
 *
 * @param element
 * @returns {{top: number, left: number}}
 */
export function cumulativeOffset(element) {
  let sumTop = 0,
    sumLeft = 0
  do {
    const {top, left} = element.getBoundingClientRect()
    sumTop += top
    sumLeft += left
    element = element.parentElement
  } while (element)

  return {
    top: sumTop,
    left: sumLeft,
  }
}

export function isClient() {
  return typeof window !== 'undefined'
}

export const iconIdPrefix = 'icon_'