import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {fetchJson} from '../../util/universal'
import {useTranslation} from 'react-i18next'
import isEmail from 'validator/lib/isEmail'
import PropTypes from 'prop-types'
import {Flexbox, FlexStrong, FlexInput} from './styles'
import {Huntsman} from '@loungebuddy/lbds'
import Button from '../component/Button'
const {buttonTheme} = Huntsman.themes

const EmailConfirmationForm = ({email, onCancel, onSent, bookingEmail, bookingId}) => {
	const [processing, setProcessing] = useState(false)
	const {t} = useTranslation('booking')
	const {register, handleSubmit, ...formProps} = useForm({
		mode: 'onBlur',
		defaultValues: {
			email,
		},
	})

	const _sendConfirmation = async ({email}) => {
		setProcessing(true)
		await fetchJson(`/apiService/bookings/${bookingId}/email`, {
			body: {
				email,
				bookingEmail,
			},
		})

		if (typeof onSent === 'function') onSent()
	}

	const _cancel = e => {
		e.preventDefault()
		onCancel()
	}

	return (
		<form onSubmit={handleSubmit(_sendConfirmation)}>
			<Flexbox>
				<FlexStrong className="modal-title">{t('emailConfirmation')}</FlexStrong>
				<FlexInput
					formRef={register({
						required: t('required'),
						validate: email => isEmail(email.trim()) || t('invalidEmailAddress'),
					})}
					name="email"
					placeholder="janedoe@example.com"
					autocomplete="email"
					type="email"
					{...formProps}
				/>

				{processing ? (
					<FlexStrong>{t('processing')}</FlexStrong>
				) : (
					<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
						<Button type="submit" fullWidth style={{marginRight: '8px'}}>
							{t('send')}
						</Button>
						<Button onClick={_cancel} fullWidth theme={buttonTheme.normal} style={{marginLeft: '8px'}}>
							{t('cancel')}
						</Button>
					</div>
				)}
			</Flexbox>
		</form>
	)
}

EmailConfirmationForm.propTypes = {
	email: PropTypes.string,
	onCancel: PropTypes.func,
	onSent: PropTypes.func,
	bookingEmail: PropTypes.string,
	bookingId: PropTypes.string,
}

export default EmailConfirmationForm
