import React from 'react'
import TextField from '@material-ui/core/TextField'
import {StylesProvider} from '@material-ui/core/styles'
import throttle from 'lodash/throttle'
import Grid from '@material-ui/core/Grid'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import colors from '../../components/global/Colors'
import {Box} from '@material-ui/core'
import LBInput from '../../components/LBInput'
import styled, {css} from 'styled-components'
import LBButton, {buttonHeight, buttonTheme} from '../../components/LBButton'
import AirplaneIcon from '../../components/global/LBIcons/icons/Airplane'
import LBAvatar from '../../components/LBAvatar'
import LBAutocomplete from '../../components/LBAutocomplete'
import {
  BodyText,
  fontWeight,
  MinorText,
} from '../../components/global/Typography'
import LBNetworkImage from '../../../public/static/lbnetwork@2x.png'
import MiniCaretDownIcon from '../../components/global/LBIcons/icons/MiniCaretDown'
import MapPointIcon from '../../components/global/LBIcons/icons/MapPoint'
import SearchIcon from '../../components/global/LBIcons/icons/Search'
import {Icons} from '../../components/global/Images'

import PropTypes from 'prop-types'
import {InnerWrap} from '../../components/Header'
import {globalPadding} from '../../components/global/Constants'

const Wrap = styled.div`
  padding: 16px 0;
`
const Inner = styled.div`
  display: flex;
  position: relative;
  ${InnerWrap};
`
const LBButtonStyles = {
  position: 'absolute',
  right: globalPadding + 'px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}
const NoOptionText = styled(BodyText)`
  color: ${colors.espressoMedium};
`
const Thumbnail = styled(LBAvatar)`
  margin-right: 16px;
  color: ${colors.espressoLight};
  background-color: ${colors.lightGrey};
`
const ResultName = styled(BodyText)`
  display: inline;
  color: ${colors.espressoMedium};
  white-space: pre;
`
const ResultText = styled(MinorText)`
  color: ${colors.espressoMedium};
`
const Bullet = styled.span`
  display: inline-block;
  margin: 0 8px;
  font-size: 9px;
  position: relative;
  top: -2px;
`
const LBNetwork = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url(${LBNetworkImage}) no-repeat center center;
  background-size: 100% auto;
  text-indent: -9999px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(13, 16, 20, 0.25);
`
const ButtonIcon = styled(SearchIcon)`
  position: relative;
  top: -1px;
  left: -1px;
`
const ContentGrid = styled(Grid)`
  padding-right: 16px;
  width: calc(100% - 48px);
`
const TextOverflow = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const SYM_NO_RESULTS = Symbol.for('noResults')

const SearchBar = ({
  text,
  searchApi,
  limit = 6,
  onSelect = () => {},
  onSearchClick = () => {},
  showLoading,
  value = '',
}) => {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(value)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selected, setSelected] = React.useState(undefined)
  const [focus, setFocus] = React.useState(false)

  const handleChange = event => {
    setOptions([])
    setSelected(undefined)
    setInputValue(event.target.value)
  }

  const searchAirport = React.useCallback(
    throttle(async (input, callback) => {
      !loading && setLoading(true)
      if (!open) {
        setOpen(true)
      }

      const data = await searchApi(input)
      if (Array.isArray(data) && data.length === 0) {
        data.push(SYM_NO_RESULTS)
      }
      callback(data)

      setLoading(false)
    }, 300),
    [searchApi]
  )

  React.useEffect(() => {
    if (inputValue === '' || typeof inputValue !== 'string' || selected) {
      setOptions([])
      setOpen(false)
      return
    }

    let active = true

    searchAirport({input: inputValue}, results => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue])

  const ThumbDefault = (
    <Thumbnail>
      <AirplaneIcon size={16} color={colors.espressoLight} />
    </Thumbnail>
  )

  const onSearchButtonClick = e => {
    onSearchClick(e, inputValue)
  }

  const onSelectValue = (e, val) => {
    if (!val) return

    setSelected(val)
    onSelect(e, val, inputValue)
  }

  const getLabel = airport => airport?.name ? `${airport.iata} • ${airport.name}` : airport

  return (
    <StylesProvider injectFirst>
      <Wrap>
        <Inner>
          <LBAutocomplete
            value={getLabel(
              selected ?? (focus ? inputValue : inputValue || value)
            )}
            id="search-airport"
            filterOptions={x => x}
            options={options.slice(0, limit)}
            getOptionLabel={(option = {}) => {
              if (typeof option === 'string') {
                return option
              }

              return getLabel(option)
            }}
            autoComplete
            includeInputInList
            disableOpenOnFocus
            freeSolo
            forcePopupIcon={false}
            loading={loading}
            noOptionsText={<NoOptionText>{text.loadingText}</NoOptionText>}
            loadingText={<NoOptionText>{text.loadingText}</NoOptionText>}
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => {
              setFocus(false)
            }}
            disablePortal
            renderInput={params => (
              <TextField
                {...params}
                placeholder={text.placeholder}
                fullWidth
                label={text.label}
                onChange={handleChange}
                InputProps={{
                  ...params.InputProps,
                  name: 'search-input',
                  disableUnderline: true,
                  inputComponent: LBInput,
                  inputProps: {
                    ...params.inputProps,
                    fullWidth: true,
                    disableUnderline: true,
                    icon: <MapPointIcon size={16} />,
                    autoComplete: 'off',
                  },
                }}
              />
            )}
            renderOption={option => {
              if (option === SYM_NO_RESULTS) {
                return <NoOptionText>{text.noResults}</NoOptionText>
              }

              const airportName = option.name
              const matches = match(airportName, inputValue)
              const parts = parse(airportName, matches)
              const highlightStyle = {
                fontWeight: fontWeight.medium,
                color: colors.blue,
                ...TextOverflow,
              }

              return (
                <Grid container alignItems="center">
                  <ContentGrid item xs>
                    <Box display="flex">
                      {option.imageUrl ? (
                        <Thumbnail alt={option.name} src={option.imageUrl} />
                      ) : (
                        ThumbDefault
                      )}
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        style={TextOverflow}>
                        <div style={TextOverflow}>
                          {parts.map((part, index) => (
                            <ResultName
                              key={index}
                              style={part.highlight ? highlightStyle : {}}>
                              {part.text}
                            </ResultName>
                          ))}
                        </div>
                        <ResultText
                          purchasable={option.purchasable}
                          style={TextOverflow}>
                          {option.iata}
                          <Bullet>&#9679;</Bullet>
                          {option.description}
                        </ResultText>
                      </Box>
                    </Box>
                  </ContentGrid>
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      {option.purchasable && (
                        <LBNetwork>{text.lbAccessable}</LBNetwork>
                      )}
                      <MiniCaretDownIcon
                        size={16}
                        color={colors.espressoLight}
                        style={{transform: 'rotate(-90deg)'}}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )
            }}
            onChange={onSelectValue}
          />
          <LBButton
            theme={buttonTheme.action}
            onClick={!showLoading && onSearchButtonClick}
            height={buttonHeight.medium}
            style={LBButtonStyles}
            labelStyle={{ padding: '0 16px' }}
            aria-label={text.searchButtonLabel}>
            {showLoading ? <Icons.Loading /> : <ButtonIcon size={16} />}
          </LBButton>
        </Inner>
      </Wrap>
    </StylesProvider>
  )
}

SearchBar.propTypes = {
  text: PropTypes.shape({
    lbAccessable: PropTypes.string,
    placeholder: PropTypes.string,
    noResults: PropTypes.string,
    loadingText: PropTypes.string,
    searchButtonLabel: PropTypes.string,
  }),
  searchApi: PropTypes.func,
  limit: PropTypes.number,
  onSearchClick: PropTypes.func,
  onSelect: PropTypes.func,
  showLoading: PropTypes.bool,
  value: PropTypes.shape({
    name: PropTypes.string,
    IATA: PropTypes.string
  })
}

export default SearchBar
