import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {fontWeight, MinorTextStyles} from './global/Typography'
import colors from './global/Colors'
import CircleCheckmarkIcon from './global/LBIcons/icons/CircleCheckmark'

// for support menu
const FullLinkItem = (() => {
  const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 34px;
    width: 34px;
    height: 34px;
    margin-right: 12px;
    margin-top: 3px;
    background: #ffffff;
    border: 1px solid rgba(25, 31, 39, 0.1);
    border-radius: 18px;
  `
  const Content = styled.div`
    display: flex;
    flex-direction: column;
    ${MinorTextStyles};
    color: ${colors.espressoLight};
  `
  const Title = styled.span`
    color: ${colors.espressoMedium};
    font-weight: ${fontWeight.medium};
  `

  const Template = ({icon, text: {title, description}}) => {
    return (
      <>
        {icon && <Icon>{icon}</Icon>}
        <Content>
          {title && <Title>{title}</Title>}
          {description && <span>{description}</span>}
        </Content>
      </>
    )
  }

  Template.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }

  return Template
})()

// for currency and locale menus
const LinkItem = (() => {
  const Content = styled.div`
    display: flex;
    align-items: center;
    ${MinorTextStyles};
    color: ${colors.espressoMedium};
  `
  const Icon = styled.span`
    display: flex;
    align-items: center;
    margin-right: 8px;
  `

  const SelectedIcon = styled(CircleCheckmarkIcon)`
    flex: 0 0 16px;
    margin-left: 16px;
  `

  const Template = ({icon, text: {title}, isSelected}) => {
    return (
      <>
        <Content>
          {icon && <Icon>{icon}</Icon>}
          {title}
        </Content>
        {isSelected && <SelectedIcon size={16} color={colors.blue} />}
      </>
    )
  }

  Template.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.shape({
      title: PropTypes.string,
    }),
    isSelected: PropTypes.bool,
  }

  return Template
})()

const DummyLink = props => <a {...props}>{props.children || ''}</a>
DummyLink.propTypes = {
  children: PropTypes.node,
}

const Content = styled.li`
  .listItem {
    display: flex;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
  }
  .listItem:hover,
  .listItem:active {
    background: ${colors.lightGreyLighter};
  }
  .listItem.center {
    justify-content: space-between;
    align-items: center;
  }
`

export const ListItem = ({
  WrapComponent = props => <DummyLink {...props} />,
  text = {
    title: '',
  },
  isSelected,
  ...rest
}) => {
  const isFullLink = text.description

  return (
    <Content aria-current={isSelected}>
      <WrapComponent className={'listItem' + (isFullLink ? '' : ' center')}>
        {isFullLink ? (
          <FullLinkItem text={text} {...rest} />
        ) : (
          <LinkItem text={text} isSelected={isSelected} {...rest} />
        )}
      </WrapComponent>
    </Content>
  )
}

ListItem.propTypes = {
  text: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  isSelected: PropTypes.boolean,
  WrapComponent: PropTypes.func,
}
