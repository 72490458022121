/* eslint-disable max-lines */
import React, {useEffect} from 'react'
import {compose, withProps} from 'recompose'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'
import loadData from '../component/loadData'
import {_, clearQueryParam, encodeStr, fetchJson, roundUpDate} from '../../util/universal'
import {getLoungeAccessType, LBPropTypes} from '../utils'
import {getLoungeAccessInfos} from './utils'
import {loungeAccessTypeNameMapForTracking} from '../constants'

import {trackEcommerceEvent} from '../trackHelper'
import useIframeResizer from '../hooks/useIframeResizer'
import usePageview from '../hooks/usePageview'

// TODO: use media queries breakpoints from constants
const LoungeDetail = props => {
	const {lounge, location, loungeAccessTypeNameForTracking, hostUrl, activeCurrency, nextjsUrl, initialLanguage} = props

	usePageview(props)

	const {t} = useTranslation('loungeDetail')

	const {airport, loungeThumbImage} = lounge

	useEffect(() => {
		// eCommerce tracking - measuring view of lounge detail
		const products = [
			{
				name: lounge.productName,
				id: lounge.id,
			},
		]

		// TODO: Add property “origin” (where did the customer come from, e.g. Airport page, Homepage, open the link directly)
		trackEcommerceEvent('detail', {products}, undefined, {
			loungeAccessType: loungeAccessTypeNameForTracking,
			product: lounge.name,
			airport: lounge.displayIATA,
			terminal: lounge.location.terminal,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const metaDescription = `${lounge.name} at ${airport.name} (${airport.IATA}), ${lounge.location.terminal}. Learn more about the lounge: amenities, photos, review, opening hours, location.`

	// state is for tracking on lounge detail page due to iframe
	const otherQuerystrings = location.search ? `&${clearQueryParam(location.search, 'state').substring(1)}` : ''
	const iframeUrl = new URL(`/${initialLanguage}${location.pathname}?${otherQuerystrings}`, nextjsUrl)

	const [iFrameResizer, setIframeResizer] = React.useState(null)
	const iframeElement = useIframeResizer({
		location,
		iframeUrl,
		qeId: 'lounge',
		currency: activeCurrency.code,
		onReady: iFrameResizer => {
			setIframeResizer(iFrameResizer)
		},
	})

	return (
		<div>
			<Helmet
				title={`${lounge.displayIATA}: ${lounge.name} ${t('reviewsAndPhotos')} - ${lounge.location.terminal}, ${
					airport.name
				}`}
				meta={[
					{name: 'description', content: metaDescription},
					{name: 'loungeId', content: lounge.id},
					{property: 'og:type', content: 'website'},
					{
						property: 'og:title',
						content: `${lounge.name} - ${lounge.location.terminal} - ${lounge.displayIATA}`,
					},
					{property: 'og:description', content: lounge.description},
					{property: 'og:image', content: loungeThumbImage},
					{property: 'og:site_name', content: 'LoungeBuddy'},
					{name: 'twitter:card', content: 'summary_large_image'},
					{name: 'twitter:site', content: '@loungebuddy'},
					{
						name: 'twitter:title',
						content: `${lounge.name} - ${lounge.location.terminal} - ${lounge.displayIATA}`,
					},
					{name: 'twitter:description', content: lounge.description},
					{name: 'twitter:image', content: loungeThumbImage},
				]}
				link={[{rel: 'canonical', href: hostUrl + encodeStr(location.pathname)}]}
			/>

			{iframeElement}
		</div>
	)
}

LoungeDetail.propTypes = {
	lounge: LBPropTypes.lounge,
	loungeAccessTypeNameForTracking: PropTypes.string.isRequired,
	hostUrl: PropTypes.string.isRequired,
	loungeAccess: PropTypes.shape({
		title: PropTypes.string,
		iconProps: PropTypes.shape({
			icon: PropTypes.string,
			boxShadow: PropTypes.string,
		}),
	}),
	reviews: PropTypes.array,
	otherLounges: PropTypes.array,
	images: LBPropTypes.images,
	googleMapApiKey: PropTypes.string,
	sourceSpecificBodyClassName: PropTypes.string,
	firstDayOfWeek: PropTypes.number,
	amenities: PropTypes.array,
	bookingInfo: LBPropTypes.bookingInfo,
	location: LBPropTypes.location,
	history: PropTypes.shape({
		replace: PropTypes.func,
	}),
	loungeUsage: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				start: PropTypes.string,
				end: PropTypes.string,
				count: PropTypes.number,
				percentUsed: PropTypes.number,
			})
		)
	),
	nextjsUrl: PropTypes.string,
	initialLanguage: PropTypes.string,
	activeCurrency: PropTypes.object,
}

function mapStateToProps(state) {
	return _.pick(state, [
		'lounge',
		'promotion',
		'reviews',
		'otherLounges',
		'images',
		'hostUrl',
		'initialI18nStore',
		'initialLanguage',
		'error',
		'firstDayOfWeek',
		'bookingInfo',
		'amenities',
		'nextjsUrl',
		'activeCurrency',
	])
}

export default compose(
	connect(mapStateToProps),
	loadData({
		dataLoaded: ({lounge, location}) => lounge && location && location.pathname.includes(lounge.id),
		updateData: location => {
			const loungeUrl = location.pathname
			const loungeId = loungeUrl.substring(loungeUrl.lastIndexOf('-') + 1)
			const cookies = new Cookies()
			const currency = cookies.get('currencyCode') || 'USD'
			const otherParams = location.search ? '&' + location.search.substring(1) : ''

			// inventory is in 15-minute intervals
			const expiry = roundUpDate() - 1
			const ttl = expiry - Date.now()

			return fetchJson(`/api/lounges/${loungeId}?currency=${currency + otherParams}`, {ttl})
		},
	}),
	withProps(props => {
		const {t} = useTranslation('loungeDetail')
		const loungeAccessType = getLoungeAccessType(props.lounge)
		const loungeAccessTypeNameForTracking = loungeAccessTypeNameMapForTracking[loungeAccessType]
		const loungeAccess = getLoungeAccessInfos(t)[loungeAccessType]
		return {loungeAccess, loungeAccessTypeNameForTracking}
	})
)(LoungeDetail)
