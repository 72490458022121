import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {color} from './Global/Colors'

const Wrap = styled.div`
	width: 100%;
	height: 4px;
	position: relative;
	margin-top: -8px;
	background-color: ${color.lightGrey};
`
const Bar = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: ${p => (p.width ? p.width : 0)}%;
	height: 4px;
	background-color: ${color.green};
	transition: width 0.2s ease-in;
`

const ProgressBar = ({percentage}) => (
	<Wrap>
		<Bar width={percentage} />
	</Wrap>
)

ProgressBar.propTypes = {
	percentage: PropTypes.number,
}

export default ProgressBar
