/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
import {queryValueRegex, RouteRegex} from '../util/constants'
import {getLBGlobalConstants, getQueryParameterByName, getScreenInfo} from './utils'
import {waitFor} from '../util/universal'
import Config from '../common/publishableConfig'
const config = Config()

const EVENT_CATEGORY = {
	buyLoungeAccess: 'Buy lounge access',
}

const EVENT = {
	enterSearchTerm: 'enter search term',
	pageView: 'page view',
	explorePhotos: 'explore photos',
	exploreAmenities: 'explore amenities',
	exploreMap: 'explore map',
	exploreReviews: 'explore more reviews',
	openPackages: 'open packages',
	openPackageDetails: 'open package details',
	selectPackage: 'select package',
	openPriceBreakdownOnMobile: 'open price breakdown on mobile',
	returnFromCartToLounge: 'return from cart to lounge',
	confirmLoungeBooking: 'confirm lounge booking',
	returnFromCheckoutToCart: 'return from checkout to cart',
	returnFromLoungeToAirport: 'return from lounge to airport',
	returnFromLoungeToAirportTerminal: 'return from lounge to airport terminal',
	returnFromLoungeToHome: 'return from lounge to home',
	changeCurrency: 'change currency',
	changeLanguage: 'change language',
	leaveAMessage: 'leave a message',
	startLiveChat: 'start a live chat',
	openFaqs: 'open faqs',
	manageBooking: 'manage booking',
	retrieveBooking: 'retrieve booking',
	openAppStore: 'open app store',
	capacityIndicatorFeedback: 'capacity indicator feedback',
	stripeError: 'stripe error',
	finishBooking: 'finish lounge booking',
	applyPromo: 'apply promo code',
	cancelBooking: 'finish cancel booking',
	selectSearchTerm: 'select search term',
	clickSearchButton: 'click search button',
	expandOpeningHours: 'expand opening hours',
	expandLoungeDesc: 'expand lounge description',
	seeCookieNotice: 'see cookie notice',
	acceptCookieNotice: 'accept cookie notice',
	clickPrint: 'click print',
	clickSendAgain: 'click send again',
	clickViewLoungeDetails: 'click view lounge details',
	share: 'share',
	addToCalendar: 'add to calendar',
	editBooking: 'edit booking',
	bookAgain: 'book again',
}

const PAGE_TYPE = {
	brand: 'brand',
	airport: 'airport',
	loungeDetail: 'loungeDetail',
	blogOverview: 'blogOverview',
	blogArticle: 'blogArticle',
	checkout: 'checkout',
	bookingReview: 'bookingReview',
	home: 'home',
	locations: 'locations',
	benefits: 'benefits',
	manageBooking: 'manageBooking',
	brandOverview: 'brandOverview',
	mobile: 'mobile',
	aboutUs: 'aboutUs',
	confirmation: 'confirmation',
}

const USER_PROPS = {
	session: 'Session count',
	bookingCount: 'Booking count',
	productsViewed: 'Products viewed',
	productsBooked: 'Products booked',
	airportsViewed: 'Airports viewed',
	airportsBooked: 'Airports booked',
	totalNumOfTravelers: 'Total num of Travelers',
	revenue: 'Revenue',
	referrer: 'Referrer',
	referrerSearchKeywords: 'Referrer search keywords',
}

const AMPLITUDE_ACTION = {
	add: 'add',
	append: 'append',
	set: 'set',
	revenue: 'revenue',
}

export const trackConstants = {
	EVENT_CATEGORY,
	EVENT,
	PAGE_TYPE,
	USER_PROPS,
	AMPLITUDE_ACTION,
}

// E.g. change `value`(amplitude) into `target-properties`(gtm)
const remapKeyForGTM = k =>
	({
		value: 'target-properties',
		url: 'content-name',
		category: 'target',
	}[k] || k)

function pushVariablesToDataLayer(params) {
	if (!window.google_tag_manager) return

	// Default properties
	const data = {
		eventTimeout: 2000, // in case there is a gtm error
	}

	// Data layer variables work on a per-page basis. This means the existing variables are not flushed out by pushing new data
	// Let's reset data layer variables which should work on a per-hit basis by setting "undefined" by force
	config.hitBasisDataLayerProperties.forEach(function(property) {
		data[property] = undefined
	})

	// Merge params into data
	for (let p in params) {
		if (params.hasOwnProperty(p)) {
			data[remapKeyForGTM(p)] = params[p]
		}
	}

	LBHuntsman.runIfAllowed(LBHuntsman.VENDORS.googleAnalytics, () => {
		// Push to the data layer
		const dataLayer = (window.dataLayer = window.dataLayer || [])
		dataLayer.push(data)
	})
	return true
}

function _getPageType(url) {
	const queryIndex = url.indexOf('?')
	const path = queryIndex > -1 ? url.substring(0, queryIndex) : url
	return RouteRegex.brandPage.test(path)
		? PAGE_TYPE.brand
		: RouteRegex.blogOverviewPage.test(path)
		? PAGE_TYPE.blogOverview
		: RouteRegex.blogPage.test(path)
		? PAGE_TYPE.blogArticle
		: RouteRegex.airportListing.test(path)
		? PAGE_TYPE.airport
		: RouteRegex.loungeDetailPage.test(path)
		? PAGE_TYPE.loungeDetail
		: path.includes('/checkout')
		? PAGE_TYPE.checkout
		: path.includes('/booking-review')
		? PAGE_TYPE.bookingReview
		: path === '/select/locations'
		? PAGE_TYPE.locations
		: path === '/airport-lounge-benefits'
		? PAGE_TYPE.benefits
		: path === '/find-reservation'
		? PAGE_TYPE.manageBooking
		: path === '/airline-lounges'
		? PAGE_TYPE.brandOverview
		: path === '/about'
		? PAGE_TYPE.aboutUs
		: path === '/mobile'
		? PAGE_TYPE.mobile
		: path.startsWith('/bookings/')
		? PAGE_TYPE.confirmation
		: path === '/'
		? PAGE_TYPE.home
		: null
}

function _removePIIValues(url) {
	return url.replace(queryValueRegex('email'), '')
}

// Amplitude is the main tracking
/*
	{
		name: 'eventName',
		value: 'value',
		url: 'url of event source'
	}
 */
export function trackEvent(
	name,
	{
		gtmParams = {}, // for gtm only
		...params
	} = {}
) {
	// always add these properties
	const newParams = {
		...params,
		url: _removePIIValues(params.url || (window.location ? window.location.pathname + window.location.search : '')),
		locale: params.locale || getLBGlobalConstants().locale,
	}

	newParams.deviceView = newParams.deviceView || getScreenInfo()
	newParams.pageType = newParams.pageType || _getPageType(newParams.url)

	if (newParams.sourceUrl) {
		newParams.sourcePageType = _getPageType(newParams.sourceUrl)
	}

	const mainEvent = new Promise(resolve => {
		let counter = 0
		let targetChecks = 0

		const incrAndResolve = () => {
			counter += 1
			if (counter === targetChecks) {
				resolve()
			}
		}

		// Track regular website events through "interaction" GTM event
		if (
			pushVariablesToDataLayer({
				...newParams,
				...gtmParams,
				event: 'interaction',
				action: name,
				eventCallback: incrAndResolve,
			})
		) {
			targetChecks += 1
		}

		// amplitude
		if (amplitudeLogEvent(name, newParams, incrAndResolve)) {
			targetChecks += 1
		}

		// no tracking enabled
		if (targetChecks === 0) {
			resolve()
		}
	})

	return Promise.race([mainEvent, waitFor(1000)])
}

function amplitudeLogEvent(name, params, cb) {
	if (!window.amplitude) return null

	// fire individual page view event instead
	if (name === EVENT.pageView) {
		if (params.pageType) {
			if (params.pageType === PAGE_TYPE.locations) {
				params.sourceLink = getQueryParameterByName(params.url, 'source') || 'footer'
			}

			name = 'view ' + params.pageType

			// additional user property
			if (params.pageType === PAGE_TYPE.loungeDetail) {
				AmplitudeRun(AMPLITUDE_ACTION.append, USER_PROPS.productsViewed, params.productName)
			}
		} else {
			// no page type, don't need to track
			return cb()
		}
	}

	window.amplitude.getInstance().logEvent(name, params, cb)
	return true
}

// for GA
export const trackEcommerceEvent = (measurement, item, currencyCode = 'USD', additionals = {}) => {
	const measuremntToEventActionMap = {
		impressions: 'impressions',
		click: 'click',
		detail: 'detail',
		add: 'addToCart',
		checkout: 'checkout',
		purchase: 'purchase',
		refund: 'refund',
	}
	const data = {
		...additionals,
		event: 'ecommerce',
		action: measuremntToEventActionMap[measurement],
		'content-name': location.pathname + location.search,
		ecommerce: {currencyCode, [measurement]: item},
	}
	pushVariablesToDataLayer(data)

	// amplitude user revenue tracking
	// const item = {
	// 	actionField: {id: 'DmgFbS5BuL', revenue: 22},
	// 	products: [
	// 		{
	// 			name: 'SFO - International Terminal A - British Airways First Lounge',
	// 			id: 'jHjYE4egnv',
	// 			price: 22,
	// 			variant: 'Lounge Access 3 hrs',
	// 			quantity: 1,
	// 		},
	// 	],
	// }
	if (measurement === 'purchase') {
		const product = item.products[0]
		AmplitudeRun(AMPLITUDE_ACTION.revenue, product.id, {
			price: product.usdPrice,
			quantity: product.quantity,
			currency: currencyCode,
		})

		AmplitudeRun(AMPLITUDE_ACTION.add, USER_PROPS.bookingCount, 1)
		AmplitudeRun(AMPLITUDE_ACTION.append, USER_PROPS.productsBooked, product.productName)
		AmplitudeRun(AMPLITUDE_ACTION.append, USER_PROPS.airportsBooked, product.airportIATACode)
		AmplitudeRun(AMPLITUDE_ACTION.add, USER_PROPS.totalNumOfTravelers, product.quantity)
	}
}

export const trackPageView = path => {
	pushVariablesToDataLayer({
		event: 'content-view',
		'content-name': path,
	})
}

export function AmplitudeRun(action, key, value) {
	if (!window.amplitude) return
	if (!(action in AMPLITUDE_ACTION)) {
		// eslint-disable-next-line no-console
		console.error('Wrong amplitude action:', action)
		return
	}

	if (action === AMPLITUDE_ACTION.revenue) {
		const revenue = new window.amplitude.Revenue()
			.setProductId(key)
			.setPrice(value.price)
			.setQuantity(value.quantity)
			.setEventProperties({
				currency: value.currency,
			})
		window.amplitude.getInstance().logRevenueV2(revenue)
	} else {
		const identify = new window.amplitude.Identify()[action](key, value)
		window.amplitude.getInstance().identify(identify)
	}
}
