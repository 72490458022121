import React from 'react'
import Helmet from 'react-helmet'
import {useTranslation} from 'react-i18next'
import usePageview from './hooks/usePageview'
import {Container, Page} from './component/Layout'
import {color} from './component/Global/Colors'
import styled from 'styled-components'
import {BodyText} from './component/Global/Typography'
import Button from './component/Button'

const Wrap = styled(Page)`
	h1 {
		margin-bottom: 8px;
	}
`
// this is the quickest and easiest solution for accessibility until we get this converted to nextJS
const BenefitText = styled.p`
	margin-top: 20px !important;
	font-size: 16px !important;
	margin-bottom: 8px !important;
	font-weight: 500 !important;
	letter-spacing: -0.2px !important;
	word-spacing: 0 !important;
	text-transform: none !important;
	line-height: 30px !important;
`

const AirportLoungeBenefits = () => {
	const {t} = useTranslation('translation')
	usePageview()
	return (
		<Wrap>
			<Helmet title={t('benefits.title')} />
			<div className="lb-page-benefits">
				<div className="lb-section lb-section--header">
					<div className="container">
						<div className="title-subtitle-holder">
							<h1>{t('benefits.why')}</h1>
							<BodyText color={color.blondeDark}>{t('benefits.relax')}</BodyText>
						</div>
					</div>
				</div>
				<div className="lb-page-benefits">
					<div className="lb-section lb-section--press">
						<div className="container">
							<ul>
								{t('index.PRList', {returnObjects: true}).map((item, i) => (
									<li key={i}>
										<a href={item.__href} rel="noreferrer noopener" target="_blank" className={'logo logo--' + (i + 1)}>
											<span>{item.title}</span>
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className="lb-section lb-section--features">
					<Container>
						<h2>{t('index.whenToUse')}</h2>
						<p>{t('index.loungeExperience')}</p>
						<ul className="features" id="features">
							{t('index.featureList', {returnObjects: true}).map((item, i) => (
								<li key={i}>
									<span
										className="icon"
										dangerouslySetInnerHTML={{
											__html: item.__icon,
										}}
									/>
									<h3>{item.heading}</h3>
									<div className="content">
										<p>{item.content}</p>
									</div>
								</li>
							))}
						</ul>
						<Button linkProps={{to: '/select/locations?source=benefits'}}>{t('benefits.bookAccess')}</Button>
					</Container>
				</div>
				<div className="lb-section lb-section--cascade">
					<Container>
						{t('benefits.mainFeatureList', {returnObjects: true}).map((item, i) => (
							<div key={i} className="lb-section--cascade__row">
								<div className={'col ' + item.__sideClass}>
									<div className="feature">
										<h3>{item.heading}</h3>
										<p>{item.description}</p>
									</div>
								</div>
								<div className="col col--img" style={{backgroundImage: 'url(' + item.__imgUrl + ')'}} />
							</div>
						))}
					</Container>
				</div>
				<div className="lb-section lb-section--aminities">
					<Container>
						<h2>{t('benefits.offer')}</h2>
						<p>{t('benefits.complimentaryAmenities')}</p>
						<ul className="features features--bullets">
							{t('benefits.amenities', {returnObjects: true}).map((item, i) => (
								<li key={i}>
									<span
										className="icon"
										dangerouslySetInnerHTML={{
											__html: item.__icon,
										}}
									/>
									<BenefitText>{item.heading}</BenefitText>
								</li>
							))}
						</ul>
						<Button linkProps={{to: '/select/locations?source=benefits'}}>{t('benefits.bookAccess')}</Button>
					</Container>
				</div>
			</div>
		</Wrap>
	)
}

export default AirportLoungeBenefits
