import React from 'react'
import IconBase from '../IconBase'
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const TagIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 16 16" {...props}>
			<defs>
				<path
					d="M4.44 2.94l3-3a3 3 0 014.242 0l3 3a3 3 0 01.879 2.12v5.758a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.061a3 3 0 01.878-2.122zm5.12.878a1 1 0 100-2 1 1 0 000 2z"
					id={id + 'a'}
				/>
			</defs>
			<use
				fill={props.color || 'currentColor'}
				transform="rotate(45 9.56 6.44)"
				xlinkHref={`#${id + 'a'}`}
				fillRule="evenodd"
			/>
		</IconBase>
	)
}

export default TagIcon
