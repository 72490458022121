export const FEATURE_TOGGLES = {
	kiosk: {
		/* 'Hong Kong Centurion Lounge -- Change Page Behavior to limit Kiosk to "Add Travelers" functionality only */
		id: 'kiosk',
	},
}

export const PROMOTION = {
	types: {
		// legacy
		discount: 'discount',
		credit: 'credit',
		// falcon
		percent: 'percent',
		dollar: 'dollar',
		unknown: 'unknown',
	},
	scopes: {
		booking: 'booking',
		person: 'person',
	},
}

export const USD = 'USD'
export const EN = 'en'

export const EXTERNAL_DATE_FORMAT = 'MM-dd-yyyy'
export const EXTERNAL_TIME_FORMAT = 'HH:mm'
export const DB_TIME_FORMAT = 'HHmm'
export const USER_FRIENDLY_DATE_FORMAT = 'PP' //localized
export const USER_FRIENDLY_TIME_FORMAT = 'p' //localized
export const CALENDAR_FORMAT = "yyyyMMdd'T'HHmmss'Z'"
export const ISO_TZ_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"
export const ISO_NO_MILLIS = "yyyy-MM-dd'T'HH:mm:ssxxx" // moment().format() default

export const termPagePathKeys = {
	'/privacy-policy': 'privacy',
	'/cookie-policy': 'cookiePolicy',
	'/loungebuddy-inc-promotional-code-policy': 'giveaway',
	'/terms-of-use-gift': 'termsOfUseGiftCard',
	'/terms-of-use': 'termsOfUse',
}

export const CLOUDINARY_URL_PREFIX = 'https://res.cloudinary.com/loungebuddy/image/upload/'

export const BookingStatus = {
	RESERVED: 'RESERVED',
	CREATE_CHARGE: 'CREATE_CHARGE',
	CONFIRMING: 'CONFIRMING',
	CAPTURE_CHARGE: 'CAPTURE_CHARGE',
	CONFIRMED: 'CONFIRMED',
	ERROR: 'ERROR',
}

export const BookingStatusPercent = {
	[BookingStatus.RESERVED]: 20,
	[BookingStatus.CREATE_CHARGE]: 30,
	[BookingStatus.CONFIRMING]: 50,
	[BookingStatus.CAPTURE_CHARGE]: 70,
	[BookingStatus.CONFIRMED]: 100,
	[BookingStatus.ERROR]: 100,
}

export const RouteRegex = {
	airportListing: /^\/([A-Z]{3})(?:\/|$)$/,
	airportListingLowercase: /^\/([a-z]{3})(?:\/|$)$/, //redirect to uppercase
	loungeDetailPage: /^\/([A-Z]{3})\/([\w-]+)$/,
	blogPage: /\/blog(\/[^/]*)*$/,
	blogOverviewPage: /\/blog(\/)*$/,
	brandPage: /^\/([A-Z,a-z,0-9]{2})(\/|(-(.*))*)$/,
}

export const queryValueRegex = key => new RegExp(`${key}=([^&]*)`)

// Add Google TLDs to the CSP as directed to support with Google Analytics
// https://developers.google.com/tag-platform/tag-manager/web/csp#google_analytics_4_google_analytics
const GoogleTLDs = [
	// Limited to the domains that LoungeBuddy supports because using ALL domains that Google Supports breaks Heroku 🤦‍♂️
	// https://devcenter.heroku.com/articles/limits#http-request-buffering
	'https://*.google.com',
	'https://*.google.com.au',
	'https://*.google.co.uk',
	'https://*.google.fr',
	'https://*.google.de',
	'https://*.google.com.mx',
]
const GA_V4_CSP_Domains = [
	'https://*.googletagmanager.com',
	'https://*.google-analytics.com',
	'https://*.analytics.google.com',
	'https://*.g.doubleclick.net',
	...GoogleTLDs,
]

const AmExDomains = [
	'*.americanexpress.com', // OneXP Tracking Beacon -- Ex. "https://iwmap-qa.americanexpress.com/beacon"
	'*.aexp-static.com',
]

const GoogleMapDomains = ['maps.googleapis.com']

export const ScriptSources = [
	'*.apple-mapkit.com',
	'stripe.com',
	'*.stripe.com',
	'*.evidon.com',
	'cdn.amplitude.com',
	'cdn.kustomerapp.com',
	'cdn.polyfill.io',
	'*.datadoghq.com',
	'www.datadoghq-browser-agent.com',
	...AmExDomains,
	GoogleMapDomains[0],
	GA_V4_CSP_Domains[0],
].join(' ')

export const ConnectSources = [
	'api.amplitude.com',
	'stripe.com', // stripe.com (w/ no sub-domain) for "https://stripe.com/cookie-settings/enforcement-mode"
	'*.stripe.com', // r.stripe.com, m.stripe.com, api.stripe.com, merchant-ui-api.stripe.com
	'*.evidon.com',
	'loungebuddy.api.kustomerapp.com',
	'rum-http-intake.logs.datadoghq.com',
	'*.pndsn.com', // used by kustomer
	GoogleMapDomains[0],
	...AmExDomains,
	...GA_V4_CSP_Domains,
].join(' ')

export const ImgSources = [
	'blob:',
	'data:',
	'res.cloudinary.com',
	'*.loungebuddy.com',
	'*.evidon.com',
	'*.apple-mapkit.com',
	'stripe.com',
	'*.stripe.com',

	// used by kustomer
	'*.kustomerhostedcontent.com',
	'*.kustomerapp.com',
	'www.gravatar.com',

	GoogleMapDomains[0],
	...AmExDomains,
	...GA_V4_CSP_Domains,
].join(' ')

export const LBDomains = [
	'*.loungebuddy.com',
	'*.loungebuddy.com.au',
	'*.loungebuddy.co.uk',
	'*.loungebuddy.de',
	'*.loungebuddy.fr',
	'*.loungebuddy.mx',
].join(' ')

const inlineScriptVerificationStrings = [
	"'sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta'", // Amplitude SDK SRI Hash -- from their docs.
].join(' ')

/* iwmap.americanexpress.com */
const DevCSP = `frame-src *.stripe.com ${LBDomains} localhost:* *.herokuapp.com;`

const ProdCSP = `frame-src *.stripe.com ${LBDomains};`

export function getCSP(isDev, nonce) {
	return `
	default-src 'self';
	font-src 'self' fonts.gstatic.com cdn.kustomerapp.com;
	frame-ancestors 'none';
	upgrade-insecure-requests;
	style-src 'self' 'unsafe-inline' fonts.googleapis.com;
	img-src 'self' ${ImgSources};
	object-src 'self' data: blob:;
	connect-src 'self' ${ConnectSources} ${ScriptSources};
	worker-src blob:;
	script-src 'self' ${inlineScriptVerificationStrings} ${ScriptSources};
	script-src-elem 'self' ${ScriptSources} ${inlineScriptVerificationStrings} 'nonce-${nonce}';
	${isDev ? DevCSP : ProdCSP}
`.replace(/[\n|\t]/g, '')
}

// list of countries affected by GDPR
export const GDPRSet = new Set([
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'ES',
	'SE',
	'GB',
	'GF',
	'GP',
	'MQ',
	'ME',
	'YT',
	'RE',
	'MF',
	'GI',
	'AX',
	'PM',
	'GL',
	'BL',
	'SX',
	'AW',
	'CW',
	'WF',
	'PF',
	'NC',
	'TF',
	'AI',
	'BM',
	'IO',
	'VG',
	'KY',
	'FK',
	'MS',
	'PN',
	'SH',
	'GS',
	'TC',
	'AD',
	'LI',
	'MC',
	'SM',
	'VA',
	'JE',
	'GG',
])
