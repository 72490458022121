/* eslint-disable max-lines */
import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import classNames from 'classnames'
import Cookies from 'universal-cookie'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'
import loadData from '../component/loadData'
import {tPluralContext, LBPropTypes} from '../utils'
import {useTranslation} from 'react-i18next'
import {utcToZonedTime} from 'date-fns-tz'
import {format, differenceInDays, differenceInMinutes} from 'date-fns'
import CreditCardForm from './CreditCardForm'
import {getPromoAppliedDesc} from './utils'
import {calculateTotal, fetchJson, waitFor, formatPrice, encodeStr} from '../../util/universal'
import queryString from 'query-string'
import {getInventoryTypes} from '../../shared/inventoryHelper'
import {addLBPersistentQueryString, getInventory} from '../utils'
import CustomerInfoForm from './CustomerInfoForm'
import {errorTypes, PPL_ID_PREFIX} from './constants'
import {ccBrandNameTypes, maxWidthMobile} from '../constants'
import {BookingStatus, BookingStatusPercent, EXTERNAL_DATE_FORMAT, EXTERNAL_TIME_FORMAT} from '../../util/constants'
import * as uuid from 'uuid'
import Footer from '../BookingReview/Footer'
import {Layout, Content, BackIcon, Actions} from '../BookingReview/Layout'
import {Title1, BodyText, MinorText, MinorTextStyles, ColoredLinks, LinkWrap} from '../component/Global/Typography'
import PriceBreakdown, {completeBookingEvent} from '../BookingReview/PriceBreakdown'
import PriceBreakdownWrap from '../BookingReview/PriceBreakdownWrap'
import MediaQuery from 'react-responsive'
import PromoCode from './PromoCode'
import LockIcon from '../component/LBIcons/icons/Lock'
import {color} from '../component/Global/Colors'
import {MobilePriceBreakdown} from '../BookingReview/MobilePriceBreakdown'
import {NumberOfTravelers} from '../BookingReview/NumberOfTravelers'
import MobilePriceSummary from './MobilePriceSummary'
import ProcessingModal from './ProcessingModal'
import WithIcon from '../component/WithIcon'
import PropTypes from 'prop-types'
import PaymentRequestBlock from './PaymentRequestBlock'
import LBLink from '../component/LBLink'
import useStripe from '../hooks/useStripe'
import {useForm} from 'react-hook-form'
import {trackConstants, trackEcommerceEvent, trackEvent} from '../trackHelper'
import locales from '../../locales'
import DetailSection from '../component/Section'
import {media} from '../component/Global/Breakpoints'
import Button from '../component/Button'
import {FEATURE_TOGGLES} from '../../util/constants'
import mapProps from 'recompose/mapProps'
import usePageview from '../hooks/usePageview'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require('../../common/publishableConfig')()

const ActionsSection = styled.div`
	padding: 24px 0;

	${media.desktop`
		padding-bottom: 48px;
		border-top: 1px solid ${color.lightGreyLighter};
	`}
`
const StyledActions = styled(Actions)`
	margin: 16px 0 0 0;

	&.kiosk {
		${media.mobile`
			> button {
				flex: 1; /* make the "complete booking" button extend to the max available width */
			}
		`}

		${media.min_tablet`
			justify-content: flex-end !important; /* Right-align the "Complete Booking" button on Tablet & Desktop */
		`}
	}
`
const ErrorWrap = styled.div`
	margin-bottom: 16px;
	${MinorTextStyles};
	color: ${color.red};
	${ColoredLinks};
`

const Checkout = ({
	bookingInfo,
	lounge,
	images,
	location,
	hostUrl,
	source,
	history,
	clientReserveTimeout,
	initialLanguage,
	error: errorProp,
	sourceSpecificBodyClassName,
	stripe,
}) => {
	const {t, i18n} = useTranslation('checkout')

	const {inventoryId, availabilities, currencyCode, timeSelected} = bookingInfo

	const bookingDate = new Date(bookingInfo.bookingDate)
	const bookingDateTz = utcToZonedTime(bookingInfo.bookingDate, lounge.airport.timeZone)

	const {
		bookingLoungeId,
		passenger_name = '',
		passenger_email = '',
		firstName,
		lastName,
		email,
		lbfid,
		booking_time,
		...restQS
	} = queryString.parse(location.search)

	const bookingForNow = booking_time === 'now'

	// for names like: Robert De Diego Arozamena
	const spacePos = passenger_name.indexOf(' ')
	const passengerFirstName = passenger_name.substring(0, spacePos)
	const passengerLastName = passenger_name.substring(spacePos + 1)

	// for gallery on price breakdown, create the reference once to avoid
	// re-rendering when props change
	const galleryImages = images.slice(0, 5)

	const [howManyComing, setHowManyComing] = useState(bookingInfo.howManyComing ?? 1)
	const [promotion, _setPromotion] = useState(bookingInfo.promotion)
	const [processingPercentage, setProcessingPercentage] = useState(0) // start at 0%
	const [isCreditCardCheckout, setIsCreditCardCheckout] = useState(config.disablePaymentRequest)
	const [isProcessingOrder, setIsProcessingOrder] = useState(false)

	// auto refresh page if booking_time is set to now, e.g. HKG ipad kiosk
	React.useEffect(() => {
		if (!bookingForNow) return
		function refresh() {
			window.location.reload()
		}

		// add an extra minute so the backend can generate the next booking time correctly
		setTimeout(refresh, bookingDate - Date.now() + 60000)

		return () => {
			clearTimeout(refresh)
		}
	}, [bookingDate, bookingForNow])

	// this is error on the checkout form, different than the error in props
	const [error, _setError] = useState(null)

	const {handleSubmit, getValues, ...formProps} = useForm({
		mode: 'onBlur',
		defaultValues: {
			firstName: firstName || passengerFirstName,
			lastName: lastName || passengerLastName,
			email: email || passenger_email,
		},
	})

	const chosenAvailability = availabilities && getInventory(availabilities, inventoryId)

	const trackAddProduct = () => {
		const products = [
			{
				name: lounge.productName,
				id: lounge.id,
			},
		]

		// TODO: Add property “origin” (where did the customer come from, e.g. Lounge Page, open the link directly)
		const dimensions = {
			productName: lounge.name,
			airportIATACode: lounge.displayIATA,
			airportName: lounge.airport.name,
			terminalName: lounge.location.terminal,
			bookingDate: format(bookingDateTz, EXTERNAL_DATE_FORMAT),
			bookingTime: timeSelected ? format(bookingDateTz, EXTERNAL_TIME_FORMAT) : '',
			packageName: chosenAvailability ? chosenAvailability.lengthDesc : '',
			packageValue: chosenAvailability ? `${chosenAvailability.price} ${currencyCode}` : '',
		}

		// eCommerce tracking - measuring add and checkout
		trackEcommerceEvent('add', {products})
		trackEcommerceEvent('checkout', {actionField: {step: 1}, products}, undefined, dimensions)
	}

	useEffect(() => {
		trackAddProduct()
	}, [])

	const defaultTrackingData = {
		gtmParams: {
			category: 'Checkout',
		},
		productName: lounge.name,
		airportIATACode: lounge.displayIATA,
		airportName: lounge.airport.name,
		terminalName: lounge.location.terminal,
	}

	const onClickReturnToReviewLink = useCallback((e, trackCB) => {
		trackCB(trackConstants.EVENT.returnFromCheckoutToCart, defaultTrackingData)
	}, [])

	if (errorProp) {
		return <div>Error: {errorProp}</div>
	}

	if (!inventoryId || !availabilities) {
		return (
			<div>
				Invalid checkout: No inventory, please go back to <a href="/">home</a>.
			</div>
		)
	}

	const getFormattedPrice = price => formatPrice(price, i18n.language, currencyCode)

	const priceSummary = calculateTotal(lounge.id, promotion, currencyCode, howManyComing, chosenAvailability)

	const onTrackEvent = (name, values = {}) => {
		trackEvent(name, {
			...defaultTrackingData,
			...values,
		})
	}

	// Measure how many of the orders submitted went through successfully
	// rest for the error messages
	const trackOrderSubmitted = async ({success, ...rest}) => {
		const totalPriceConverted =
			currencyCode === 'USD'
				? {USD: priceSummary.totalPrice}
				: await fetchJson(`/api/currency/${currencyCode}/USD/${priceSummary.totalPrice}`)

		const now = new Date()
		trackEvent(trackConstants.EVENT.finishBooking, {
			...defaultTrackingData,
			gtmParams: {
				category: 'Order',
			},
			success,
			bookingDate: format(bookingDateTz, EXTERNAL_DATE_FORMAT),
			bookingTime: format(bookingDateTz, EXTERNAL_TIME_FORMAT),
			packageName: chosenAvailability.lengthDesc,
			orderSize: totalPriceConverted.USD,
			numberOfInventoryItems: priceSummary.howManyComing,
			daysInAdvance: differenceInDays(bookingDate, now),
			timeInAdvance: differenceInMinutes(bookingDate, now),
			currency: currencyCode,
			promoApplied: priceSummary.promotion.applied,
			...rest,
		})
	}

	const setError = error => {
		if (error) {
			trackOrderSubmitted({
				success: false,
				errorMessage: error.message,
				errorType: error.type,
			})
		}
		_setError(error)
	}

	const setPromotion = val => {
		_setPromotion(val)
		setError(null)
	}

	const setPayWithCreditCard = () => {
		setIsCreditCardCheckout(true)
	}

	// e.g. key: 'firstName' (note: we don't track the value to avoid PII issues)
	const trackedFormValue = {} // for only track form data once
	const onCustomerInfoTrack = ({key}) => {
		if (!trackedFormValue[key]) {
			// onTrackEvent('Provide ' + key) //todo: comment this tracking out for now

			//retain previous tracked value so we don't send track events multiple times
			trackedFormValue[key] = true
		}
	}

	const goToPaymentSection = () => {
		document.querySelector('#payment').scrollIntoView()
	}

	const submitForm = ({
		firstName,
		lastName,
		email: rawEmail,
		token,
		payerName,
		payerEmail, // set by stripePaymentButton
	} = {}) => {
		const email = rawEmail ? rawEmail.toLowerCase() : ''

		if (!firstName || !lastName || !email || !chosenAvailability) return

		const processPaymentError = error => {
			setError({type: errorTypes.PAYMENT, message: error})
			setIsProcessingOrder(false)
			goToPaymentSection()
		}

		const processPayment = async (error, token) => {
			if (error) {
				return processPaymentError(error)
			}

			if (token && !(token.card && token.card.brand === ccBrandNameTypes.AMEX)) {
				return processPaymentError(t('cardAcceptanceNote'))
			}

			const parsedSearch = queryString.parse(location.search)
			const {accessItem} = parsedSearch
			const requestId = uuid.v4()

			let data
			const reserveApiRetryExpiration = Date.now() + clientReserveTimeout
			while (!data) {
				try {
					// fallback, in case the page doesn't refresh
					const _bookingDateTz =
						bookingForNow && bookingDate < new Date()
							? utcToZonedTime(new Date(), lounge.airport.timeZone)
							: bookingDateTz

					data = await fetchJson('api/payment', {
						body: {
							loungeId: lounge.id,
							bookingLoungeId,
							currency: currencyCode,
							token,
							firstName,
							lastName,
							email,
							payerName,
							payerEmail,
							promotion,
							howManyComing,
							chosenAvailability,
							bookingDate: format(_bookingDateTz, 'yyyy-MM-dd'),
							bookingTime: format(_bookingDateTz, EXTERNAL_TIME_FORMAT),
							source,
							accessItem,
							timeZone: lounge.airport.timeZone,
							requestId,
							timeout: reserveApiRetryExpiration - Date.now(),
						},
					})

					if (Date.now() > reserveApiRetryExpiration) {
						data = {error: ''}
						break
					}
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error('reserve api error', err)
					await waitFor(1000)
				}

				if (data && data.error === 'TIMEDOUT') {
					data = null
					await waitFor(1000)
				}
			}

			if (data.error) {
				setIsProcessingOrder(false)
				setProcessingPercentage(0)
				setError({type: errorTypes.OTHER, message: data.error})
				return
			}

			// TODO: fetchJson should support catch block to handle all kind of http request errors such as network error, timeout error
			// and parsing error at the consumer side instead of always returning a resolved promise
			if (!data || !data.reserve) {
				setIsProcessingOrder(false)
				setProcessingPercentage(0)
				setError({type: errorTypes.OTHER})
				return
			}

			const {reserve} = data

			if (data.error) {
				processPaymentError(data.error)
			}

			const {bookingId} = reserve

			const checkStatus = async () => {
				const {booking = {}} = await fetchJson(`/api/payment/${bookingId}`)

				setProcessingPercentage(BookingStatusPercent[booking.status] || processingPercentage)

				if (booking.status === BookingStatus.CONFIRMED) {
					const priceToUSD =
						currencyCode !== 'USD'
							? await fetchJson(`api/currency/${currencyCode}/USD/${chosenAvailability.price}`)
							: {USD: chosenAvailability.price}

					trackEcommerceEvent(
						'purchase',
						{
							actionField: {
								id: bookingId, // Transaction ID. Required for purchases and refunds.
								revenue: priceSummary.totalPrice, // Total transaction value (incl. tax and shipping)
								coupon: promotion && promotion.code,
							},
							products: [
								{
									name: lounge.productName,
									id: lounge.id,
									price: chosenAvailability.price,
									usdPrice: priceToUSD.USD,
									variant: chosenAvailability.lengthDesc,
									quantity: howManyComing,
									productName: lounge.name,
									airportIATACode: lounge.displayIATA,
								},
							],
						},
						priceSummary.currency,
						{email}
					)

					trackOrderSubmitted({success: true})

					setIsProcessingOrder(false)
					const bookingUrl = addLBPersistentQueryString(
						location,
						`/bookings/${booking.message}?email=${encodeURIComponent(email)}`,
						config.persistentParamsForTracking
					)
					history.push(bookingUrl, {confirm: 1})
					fetchJson(`/api/payment/${bookingId}`, {method: 'DELETE'})
				} else if (booking.status === BookingStatus.ERROR) {
					processPaymentError(booking.message)
					fetchJson(`/api/payment/${bookingId}`, {method: 'DELETE'})

					// TODO: Need to track errorType
					trackOrderSubmitted({success: false})
				} else {
					setTimeout(checkStatus, 1000)
				}
			}

			setTimeout(checkStatus, 1000)
		}

		setProcessingPercentage(0)
		setIsProcessingOrder(true)
		setError(null)
		if (priceSummary.totalPrice === 0) {
			processPayment()
		} else if (token) {
			//e.g. apple pay
			processPayment(null, token)
		} else {
			//pay by credit card
			stripe
				.createToken({name: firstName + ' ' + lastName, currency: currencyCode})
				.then(({token, error}) => {
					if (error) {
						return processPayment(t('creditCardError') + ': ' + error.message)
					} else if (!token || token.object !== 'token' || !token.id) {
						processPayment(t('couldNotValidateCreditCardAtThisTime'))
					} else {
						processPayment(null, token)
					}
				})
				.catch(error => {
					if (error) {
						// eslint-disable-next-line no-console
						console.error('credit card error', error.message)
						processPayment(t('creditCardError') + ': ' + error.message)
					}
				})
		}
	}

	const goToFirstError = () => {
		document.querySelector('.field--error').scrollIntoView()
	}

	const renderErrorMessage = error => <ErrorWrap>{error}</ErrorWrap>

	const renderError = error => {
		// TODO:
		// 1: Support different user-friendly error messages per error type such as network error, timeout error, etc
		//    ref: https://github.com/LoungeBuddy/LBHuntsman/blob/193282cdb4c772885ad913a45db2ca3656e65dcf/public/spa/components/checkout/checkout.html#L471-L505
		switch (error.type) {
			case errorTypes.INVALID_FORM:
				return renderErrorMessage(
					<span>
						{t('toContinue')}&nbsp;
						<a onClick={goToFirstError}>{t('jumpToFirst')}</a>.
					</span>
				)
			case errorTypes.PAYMENT:
				// We show this error in the Payment section
				return null
			default:
				return renderErrorMessage(
					error.message || (
						<span
							dangerouslySetInnerHTML={{
								__html: t(
									lounge.bookingLoungeId.includes(PPL_ID_PREFIX)
										? 'somethingWentWrongTryAgainPPL'
										: 'somethingWentWrongTryAgain'
								),
							}}
						/>
					)
				)
		}
	}

	const acceptTerms = (
		<span
			dangerouslySetInnerHTML={{
				__html: t('acceptTerms', {
					termsOfUseUrl: addLBPersistentQueryString(location, '/terms-of-use'),
					privacyPolicyUrl: addLBPersistentQueryString(location, '/privacy-policy'),
				}),
			}}
		/>
	)

	const promoApplied = typeof promotion === 'object'
	const paymentRequired = priceSummary.totalPrice > 0

	const inventoryTypes = getInventoryTypes(availabilities)
	const bookType = 'room' in inventoryTypes ? 'Room' : 'Lounge'
	const bookingSummaryPopup = {
		type: chosenAvailability.lengthDesc,
		date: format(bookingDateTz, 'PPPPp', {locale: locales[i18n.language].dateFormat}),
		numOfTravelers: t('entry' + bookType, {...tPluralContext(howManyComing)}),
	}

	const priceBreakdown = (contentStyles = []) => (
		<PriceBreakdown
			t={t}
			images={galleryImages}
			lounge={lounge}
			getFormattedPrice={getFormattedPrice}
			priceSummary={priceSummary}
			howManyComing={howManyComing}
			chosenAvailability={chosenAvailability}
			bookingSummary={bookingSummaryPopup}
			isCheckout
			contentStyles={contentStyles}
		/>
	)

	const paymentSectionTitle = (
		<WithIcon icon={<LockIcon size={16} color={color.espressoDark} />}>{t('paymentTitle')}</WithIcon>
	)
	const completeBookingLabel = (
		<WithIcon icon={<LockIcon size={16} color={color.blondeDark} />}>{t('completeBooking')}</WithIcon>
	)

	const promotionAppliedDesc = getPromoAppliedDesc(promotion, currencyCode, initialLanguage, t)

	const showPaymentSection = paymentRequired || promoApplied

	const isKioskMode = lbfid === FEATURE_TOGGLES.kiosk.id

	return (
		<div>
			<Helmet
				title={t('pageTitle', {
					iata: lounge.displayIATA,
					loungeName: lounge.name,
				})}
				link={[
					{
						rel: 'canonical',
						href: `${hostUrl}${encodeStr(location.pathname)}?loungeId=${lounge.id}`,
					},
				]}
				bodyAttributes={{
					class: `page-spa page-spa--review ${sourceSpecificBodyClassName}`,
				}}
				style={[
					{
						cssText: `
						#launcher {
							z-index: 4 !important;
						}
					`,
					},
				]}
			/>

			<div className="lounge-detail--container">
				<Layout>
					<Content>
						<form
							onSubmit={handleSubmit(submitForm)}
							onBlur={() => {
								const params = getValues()

								// need to get promotion different because it has it's own useForm
								// store as "promotion" because server side is using the same
								params.promotion = document.getElementById('promo-code')?.value

								history.replace({
									search: location.search,
									hash: 'self', // add a hash value to stop ScrollMemory from scrolling to top
									state: {params},
								})
							}}>
							<Title1>{t('title')}</Title1>

							<MobilePriceBreakdown
								howManyComing={howManyComing}
								priceSummary={priceSummary}
								priceBreakdown={priceBreakdown()}
								defaultTrackingData={defaultTrackingData}
							/>

							{isKioskMode && (
								<DetailSection inCheckout title={'Number of Travelers'} noSeparator>
									<BodyText>Select up to five travelers to enter the lounge with you.</BodyText>
									<NumberOfTravelers
										t={t}
										maxAdditonalGuest={5}
										howManyComing={howManyComing}
										setHowManyComing={setHowManyComing}
									/>
								</DetailSection>
							)}

							<DetailSection inCheckout title={t('customerFormTitle')} noSeparator={!isKioskMode}>
								<BodyText>{t('customerFormDescription')}</BodyText>
								<CustomerInfoForm onTracking={onCustomerInfoTrack} lounge={lounge} formProps={formProps} />
							</DetailSection>

							{showPaymentSection && (
								<DetailSection inCheckout title={paymentSectionTitle}>
									{!paymentRequired ? (
										<div style={{marginBottom: 24}}>{t('noPaymentRequired')}</div>
									) : isCreditCardCheckout ? (
										<div>
											<BodyText
												dangerouslySetInnerHTML={{
													__html: t('paymentDescription', {
														privacyStatementUrl: '/privacy-policy',
													}),
												}}
											/>
											<CreditCardForm onTracking={onCustomerInfoTrack} />
										</div>
									) : (
										<div>
											{error &&
												error.type === errorTypes.PAYMENT_REQUEST &&
												renderErrorMessage(error.message, 'isFirstElement')}
											<PaymentRequestBlock
												priceSummary={priceSummary}
												promotionAppliedDesc={promotionAppliedDesc}
												onPayWithCardClick={setPayWithCreditCard}
												onPaymentAPIUnavailable={setPayWithCreditCard}
												onPaymentRequestTokenized={submitForm}
												onPaymentRequestButtonClick={
													// eslint-disable-next-line @typescript-eslint/no-empty-function
													() => {} //todo: rethink after we start accepting apple pay
												}
											/>
										</div>
									)}

									<PromoCode
										promotionAppliedDesc={promotionAppliedDesc}
										promotion={promotion}
										setPromotion={setPromotion}
										currencyCode={currencyCode}
										loungeId={lounge.id}
										onTrackEvent={onTrackEvent}
										promoCode={restQS.promotion}
										totalPrice={priceSummary.totalPrice}
										getFormattedPrice={getFormattedPrice}
									/>
								</DetailSection>
							)}
							<MobilePriceSummary priceBreakdown={priceBreakdown} />

							<ActionsSection>
								{error && renderError(error)}
								{error && error.type === errorTypes.PAYMENT && renderErrorMessage(error.message)}
								<MinorText>{acceptTerms}</MinorText>
								<StyledActions className={classNames({kiosk: isKioskMode})}>
									{!isKioskMode && (
										<LinkWrap>
											<LBLink
												id="bottomReturnLink"
												to={'/booking-review' + location.search}
												trackCallback={onClickReturnToReviewLink}>
												<BackIcon size={16} />
												<MediaQuery maxWidth={maxWidthMobile}>
													{matches => t(matches ? 'back' : 'backToReview')}
												</MediaQuery>
											</LBLink>
										</LinkWrap>
									)}
									<Button
										type="submit"
										id="completeBooking"
										disabled={isProcessingOrder}
										onClick={() => {
											window.dispatchEvent(new CustomEvent(completeBookingEvent))
										}}>
										{completeBookingLabel}
									</Button>
								</StyledActions>
							</ActionsSection>

							<ProcessingModal
								visible={isProcessingOrder}
								time={clientReserveTimeout}
								percentage={processingPercentage}
							/>
						</form>
					</Content>
					<PriceBreakdownWrap>
						<div>{priceBreakdown()}</div>
					</PriceBreakdownWrap>
				</Layout>
			</div>
			<Footer hideChat={isKioskMode} />
		</div>
	)
}

Checkout.propTypes = {
	lounge: LBPropTypes.lounge,
	bookingInfo: LBPropTypes.bookingInfo,
	location: LBPropTypes.location,
	t: PropTypes.func,
	source: PropTypes.string,
	history: PropTypes.shape({
		push: PropTypes.func,
		replace: PropTypes.func,
	}),
	stripe: PropTypes.shape({
		createToken: PropTypes.func,
	}),
	i18n: PropTypes.shape({
		language: PropTypes.string,
	}),
	hostUrl: PropTypes.string,
	sourceSpecificBodyClassName: PropTypes.string,
	initialLanguage: PropTypes.string,
	error: PropTypes.string,
	clientReserveTimeout: PropTypes.number,
	images: PropTypes.array,
}

const StripedForm = props => useStripe(Checkout)(props)

function mapStateToProps(state) {
	const {lounge, bookingInfo, images, hostUrl, initialI18nStore, initialLanguage, error, clientReserveTimeout} = state
	return {
		lounge,
		bookingInfo,
		images,
		hostUrl,
		initialI18nStore,
		initialLanguage,
		error,
		clientReserveTimeout,
	}
}

export default compose(
	connect(mapStateToProps),
	loadData({
		dataLoaded: ({lounge, bookingInfo, error}) => (lounge && bookingInfo && bookingInfo.inventoryId) || error,
		updateData: location => {
			const cookies = new Cookies()
			const currency = cookies.get('currencyCode') || 'USD'
			const otherParams = location.search ? '&' + location.search.substring(1) : ''
			return fetchJson(`/api/checkout?currency=${currency}${otherParams}`)
		},
	}),
	mapProps(props => {
		usePageview(props)
		return props
	})
)(StripedForm)
