import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M13.963 0c3.304 0 4.64.371 5.944 1.069a7.27 7.27 0 013.024 3.024C23.63 5.396 24 6.733 24 10.037v3.926c0 3.304-.371 4.64-1.069 5.944a7.27 7.27 0 01-3.024 3.024C18.604 23.63 17.267 24 13.963 24h-3.926c-3.304 0-4.64-.371-5.944-1.069a7.27 7.27 0 01-3.024-3.024C.37 18.604 0 17.267 0 13.963v-3.926c0-3.304.371-4.64 1.069-5.944a7.27 7.27 0 013.024-3.024C5.396.37 6.733 0 10.037 0h3.926zm-.549 15.657a2 2 0 00-2.828 0L12 17.07zm2.829-2.829a6 6 0 00-8.486 0l1.415 1.415a4 4 0 015.656 0zM19.07 10C15.166 6.095 8.834 6.095 4.93 10l1.414 1.414a8 8 0 0111.314 0z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
