import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M18 22a1 1 0 010 2H6a1 1 0 010-2h12zM12 1c2.506 0 5.013.13 7.52.393A5 5 0 0124 6.366v9.268a5 5 0 01-4.48 4.973 72.344 72.344 0 01-15.04 0A5 5 0 010 15.634V6.366a5 5 0 014.48-4.973A72.344 72.344 0 0112 1zm-.562 7H7v1.477h1.382V14h1.674V9.477h1.382V8zm2.16 0h-1.953l1.598 6h2.195L17 8h-1.829l-.737 4.11h-.075L13.598 8z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
