import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M24 9v9a3 3 0 01-3 3H3a3 3 0 01-3-3V9h24zm-12.193 3.192c-.25 0-.484.037-.7.112-.216.075-.406.18-.568.316a1.462 1.462 0 00-.524 1.148c0 .33.082.597.248.8.132.162.293.288.484.379l.148.061v.016a1.55 1.55 0 00-.4.172 1.363 1.363 0 00-.576.7 1.41 1.41 0 00-.088.504c0 .283.052.532.156.748.104.216.245.399.424.548.178.15.388.261.628.336.24.075.496.112.768.112s.528-.037.768-.112c.24-.075.45-.187.628-.336.178-.15.32-.332.424-.548.104-.216.156-.465.156-.748a1.38 1.38 0 00-.084-.488 1.466 1.466 0 00-.228-.404 1.402 1.402 0 00-.606-.44l-.146-.044v-.016c.256-.085.466-.237.632-.456.165-.219.248-.493.248-.824 0-.24-.047-.455-.14-.644a1.444 1.444 0 00-.384-.484 1.704 1.704 0 00-.572-.304 2.29 2.29 0 00-.696-.104zm-4.067.144h-.872L5.1 13.76l.56.664 1.12-.92V18h.96v-5.664zm9.517 1.072h-.816v1.76h-1.76v.816h1.76v1.76h.816v-1.76h1.76v-.816h-1.76v-1.76zm-5.45 2.016c.133 0 .26.024.38.072a.924.924 0 01.52.508c.05.12.076.25.076.388 0 .299-.094.53-.28.696a1.012 1.012 0 01-.696.248c-.283 0-.518-.083-.704-.248-.187-.165-.28-.397-.28-.696 0-.144.025-.276.076-.396a.88.88 0 01.208-.304 1.035 1.035 0 01.7-.268zm0-2.44a.863.863 0 01.572.216.76.76 0 01.252.584.816.816 0 01-.504.76.816.816 0 01-.32.064c-.25 0-.45-.08-.596-.24a.833.833 0 01-.22-.584c0-.245.076-.44.228-.584a.82.82 0 01.588-.216zM0 6a3 3 0 013-3h18a3 3 0 013 3H0z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
