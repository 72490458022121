import React from 'react'
import './style.css'
import { getOffset } from "../../utils";

class FixedPanel extends React.Component {
	state = {
		style : {
			position: 'static',
		},
		fixedPanelHeight: 0,
	}

	componentDidMount() {
		const height = this.panel.clientHeight;
		this.setState({ fixedPanelHeight: height });

		this.initPosition()
		this.fixPanel()
		window.addEventListener('scroll', this.fixPanel)
		window.addEventListener('resize', this.resizePanel)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.fixPanel)
		window.removeEventListener('resize', this.resizePanel)
	}

	initPosition = () => {
		this.originalPosition = getOffset(this.panel)
	}

	resizePanel = () => {
		this.setState({
			style: {
				position: 'static'
			}
		}, () => {
			this.initPosition()
			this.fixPanel()
		})
	}

	fixPanel = (e) => {
		if (!this.panel) return;

		const { topMargin = 0, desktopOnly } = this.props
		const isMobile = window.innerWidth <= 700
		const isLandscape = window.innerWidth > window.innerHeight
		const isMobileLandscape = window.innerWidth <= 896 && window.innerHeight < 600 && isLandscape

		if (desktopOnly && (isMobile || isMobileLandscape)) {
			return;
		}

		const scroll = window.pageYOffset
		const { top } = this.originalPosition
		const position = Math.max(0, scroll - (top - topMargin))

		var fixed = position > 0
		this.setState({
			style: {
				position: fixed ? 'fixed' : 'static',
				left: this.originalPosition.left,
			}
		})
	}

	render() {
		const style = {
			...this.state.style,
			top: this.props.topMargin || 0,
			...this.props.style
		}
		return ([
			style.position === 'fixed' ? <div key={0} className="fixed-toggle-helper" style={{ height: this.state.fixedPanelHeight }} /> : null,
			<div
				key={1}
				className={this.props.className}
				ref={(p) => { this.panel = p }}
				style={style}>
				{this.props.children}
			</div>
		])
	}
}

export default FixedPanel
