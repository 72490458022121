import React from 'react'
import IconBase from '../IconBase'
import { color } from '../../Global/Colors';
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const CircleCheckmarkIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 16 16" {...props}>
			<g fill="none" fillRule="evenodd" id={id}>
				<rect fill={props.color || 'currentColor'} width={16} height={16} rx={8} />
				<path
					d="M5.53 8.47a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.116-.061l4-5a.75.75 0 0 0-1.172-.938L6.938 9.877 5.53 8.47z"
					fill="#FFF"
					fillRule="nonzero"
				/>
			</g>
		</IconBase>
	)
}

export default CircleCheckmarkIcon
