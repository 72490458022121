import BeerAndWine from './BeerAndWine'
import ChildrenArea from './ChildrenArea'
import ClothesPress from './ClothesPress'
import Computers from './Computers'
import ConferenceRooms from './ConferenceRooms'
import FlightMonitors from './FlightMonitors'
import Gym from './Gym'
import NewspapersAndMagazines from './NewspapersAndMagazines'
import NoSmoking from './NoSmoking'
import PremiumDrinks from './PremiumDrinks'
import PremiumFood from './PremiumFood'
import PrintersAndCopiers from './PrintersAndCopiers'
import PrivateRooms from './PrivateRooms'
import ShoeShine from './ShoeShine'
import Showers from './Showers'
import SmokingAreas from './SmokingAreas'
import Snacks from './Snacks'
import Spa from './Spa'
import Tablets from './Tablets'
import Telephones from './Telephones'
import Tv from './Tv'
import WiFi from './WiFi'
import CardHolders18 from './CardHolders18'
import CardHolders21 from './CardHolders21'

const mapping = {
	PREMIUM_FOOD_FREE: PremiumFood,
	PREMIUM_FOOD: PremiumFood,
	SNACKS_FREE: Snacks,
	SPIRITS_LIQUOR_FREE: PremiumDrinks,
	SPIRITS_LIQUOR: PremiumDrinks,
	BEER_WINE_FREE: BeerAndWine,
	BEER_WINE: BeerAndWine,
	WIFI_FREE: WiFi,
	WIFI: WiFi,
	SHOWERS_FREE: Showers,
	SHOWERS: Showers,
	SPA_FREE: Spa,
	SPA: Spa,
	FLIGHT_MONITORS: FlightMonitors,
	NEWSPAPERS: NewspapersAndMagazines,
	TV: Tv,
	PRIVATE_ROOMS_FREE: PrivateRooms,
	PRIVATE_ROOMS: PrivateRooms,
	CHILDREN_ROOMS: ChildrenArea,
	CONFERENCE_ROOMS_FREE: ConferenceRooms,
	CONFERENCE_ROOMS: ConferenceRooms,
	INTERNET_TERMINALS: Computers,
	PRINTERS_FREE: PrintersAndCopiers,
	PRINTERS: PrintersAndCopiers,
	TELEPHONES: Telephones,
	SMOKING_ROOMS: SmokingAreas,
	'NON-SMOKING': NoSmoking,
	SHOE_SHINE: ShoeShine,
	'18_CARDHOLDER': CardHolders18,
	'21_CARDHOLDER': CardHolders21,
	GYM: Gym,
	GYM_FREE: Gym,
	CLOTHES_PRESS_FREE: ClothesPress,
	LOUNGEBUDDY_CHECKIN: Tablets,
}

export default mapping
