import React from 'react'
import IconBase from '../IconBase'
import { iconIdPrefix, _ } from '../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const ArrowIcon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox='0 0 16 16' {...props}>
			<g
				stroke={props.color || 'currentColor'}
				strokeWidth={1.5}
				fill='none'
				fillRule='evenodd'
				strokeLinecap='round'
				strokeLinejoin='round'
				id={id}
			>
				<path d='M9 12l4-4-4-4M13 8H3' />
			</g>
		</IconBase>
	)
}

export default ArrowIcon
