import React from 'react'
import IconBase from '../IconBase'

// Converted by: https://svgr.now.sh/ + edit

const QuestionMarkIcon = props => (
	<IconBase viewBox="0 0 16 16" {...props}>
		<path
			d="M4 5.214h1.973c.067-.98.763-1.603 1.846-1.603 1.058 0 1.763.608 1.763 1.445 0 .781-.353 1.208-1.393 1.8-1.159.64-1.646 1.35-1.57 2.519l.008.56h1.948v-.458c0-.79.31-1.2 1.41-1.8 1.141-.64 1.78-1.484 1.78-2.692C11.764 3.248 10.227 2 7.927 2 5.435 2 4.068 3.358 4 5.214zM7.7 14c.72 0 1.289-.576 1.289-1.299 0-.722-.57-1.29-1.29-1.29-.72 0-1.298.568-1.298 1.29 0 .723.578 1.299 1.298 1.299z"
			fill={props.color || "#000"}
			fillRule="evenodd"
		/>
	</IconBase>
)

export default QuestionMarkIcon

