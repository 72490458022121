import React from 'react'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components';
import { BodyText } from '../Global/Typography';
import { color } from '../Global/Colors';

// Shapes and colors on Terminal Directions list
// are disabled for now, set true for visible
const coloredBulletsFeature = false

const Item = styled.li`
	position: relative;
	padding-left: 24px;
	margin-bottom: 8px;

	&:first-child {
		margin-top: 16px;
	}
	&:last-child {
		margin-bottom: 0;
	}
`;
const Icon = styled.i`
	display: inline-block;
	position: absolute;
	width: 8px;
	height: 8px;
	left: 4px;
	top: 10px;
	border-radius: 50%;
	background-color: ${color.espressoMedium};

	${p => p.closeDist && css`
		width: 8px;
		height: 8px;
		left: 4px;
		top: 10px;
		border-radius: 50%;
		background-color: ${color.green};
	`}

	${p => p.farDist && css`
		left: 6px;
		top: 12px;
		width:  0.28em;
		height: 0.28em;
		border-top-right-radius: 40%;
		background-color: ${color.mustard};
		text-align: left;
		transform: rotate(-60deg) skewX(-30deg) scale(1,.866);

		&:before,
		&:after {
			width:  0.28em;
			height: 0.28em;
			border-top-right-radius: 40%;
		}
		&:before,
		&:after {
			content: '';
			position: absolute;
			background-color: inherit;
		}
		&:before {
			transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
		}
		&:after {
			transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
		}
	`}
`;

const TerminalDirections = ({ t, loc, airsideDesc = '', allTerminals, id }) => (
	<ul id={id}>
		{allTerminals && <Item>
			<Icon closeDist={coloredBulletsFeature} />
			<BodyText dangerouslySetInnerHTML={{ __html: t('locatedInWithLocation', { location: `${loc.terminal} ${airsideDesc}` }) }} />
		</Item>}
		{loc.accessibleTerminals &&
			loc.accessibleTerminals
				.sort((a, b) => a.time - b.time)
				.map(terminal => (
					<Item key={terminal.name}>
						{coloredBulletsFeature ? (
							<Icon closeDist={terminal.time <= 15} farDist={terminal.time > 15} />
						):(
							<Icon  />
						)}
						<BodyText dangerouslySetInnerHTML={{ __html: t('minuteWithCountFromTerminal', { count: terminal.time, terminal: terminal.name }) }} />
					</Item>
				))
		}
	</ul>
)

export default withTranslation('terminalDetailToggle')(TerminalDirections)
