import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M24 9v9a3 3 0 01-3 3H3a3 3 0 01-3-3V9h24zM6.972 12.192c-.392 0-.392 0-.724.124-.332.124-.332.124-.58.34-.248.216-.248.216-.408.512-.12.222-.12.222-.172.476l-.028.172.936.144c.056-.416.056-.416.304-.692.248-.276.248-.276.656-.276.384 0 .384 0 .644.236s.26.236.26.636c0 .336 0 .336-.156.596-.117.195-.117.195-.294.388l-.126.128L5.1 17.12V18h3.768v-.88H6.316l1.632-1.56c.192-.176.192-.176.356-.364.164-.188.164-.188.288-.396.124-.208.124-.208.196-.444.072-.236.072-.236.072-.508 0-.408 0-.408-.156-.716s-.156-.308-.42-.52c-.264-.212-.264-.212-.604-.316-.34-.104-.34-.104-.708-.104zm5.587.144h-.872L9.919 13.76l.56.664 1.12-.92V18h.96v-5.664zm4.698 1.072h-.816v1.76h-1.76v.816h1.76v1.76h.816v-1.76h1.76v-.816h-1.76v-1.76zM0 6a3 3 0 013-3h18a3 3 0 013 3H0z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
