module.exports = {
	isProduction: process.env.NODE_ENV === 'production',
	isDev: process.env.NODE_ENV === 'development',
	sitemapOn: Boolean(process.env.SITEMAP_ON),
	noSSL: Boolean(process.env.NOSSL),
	enableHtmlMinify: Boolean(process.env.ENABLE_HTML_MINIFY),
	isSSR: process.env.SSR, // for dynamic import
	reserveTimeout: parseInt(process.env.RESERVE_TIMEOUT || 7000),
	reserveAttempts: parseInt(process.env.RESERVE_ATTEMPTS || 3),
	rabbitMQURL: process.env.RABBIT_URL || process.env.CLOUDAMQP_URL || 'amqp://localhost',
	cloudflareApiToken: process.env.CLOUDFLARE_API_TOKEN || '',
	cloudflareZoneIds: (() => {
		try {
			return JSON.parse(process.env.CLOUDFLARE_ZONE_IDS || '{}') //{"loungebuddy.com": 'someId'}
		} catch (err) {
			return {}
		}
	})(),
	clientReserveTimeout: parseInt(process.env.CLIENT_RESERVE_TIMEOUT || 2 * 60000),
	defaultPageCacheMin: parseInt(
		process.env.DEFAULT_PAGE_CACHE_MIN || 8 * 60 // default 8 hours
	),
	defaultApiCacheMin: parseInt(
		process.env.DEFAULT_API_CACHE_MIN || 60 // default 60 minutes
	),
	routesCacheMins: (() => {
		try {
			// format: {"<path> or <routeName>": cachingTimeInMinutes}
			return JSON.parse(process.env.ROUTES_CACHE_MINS || '{}')
		} catch (err) {
			return {}
		}
	})(),
	circleCI: process.env.CIRCLECI, // circle ci Environment
	dyno: process.env.DYNO, // heroku dyno
	webBaseUrl: process.env.WEB_BASE_URL,
	apiCacheValidTime: process.env.API_CACHE_VALID_TIME || 60 * 60000, // in milliseconds, applies to most api endpoints
	cookieSecret: process.env.COOKIE_SECRET || 'devTempValue',

	scriptSupplier: {
		path: process.env.SCRIPT_SUPPLIER_PATH || '',
		SRI_hash: process.env.SCRIPT_SUPPLIER_SRI_HASH || '',
	},

	oneXp: {
		jsPath: process.env.ONEXP_JS_PATH || 'https://qwww.aexp-static.com/cdaas/one/one-xp-sdk/1.0.4/one-xp-sdk.js',
		trackItPath: process.env.ONEXP_TRACKIT_PATH || 'https://qwww.aexp-static.com/cdaas/one/trackit/1.9.2/trackit.js',
		baseUrl: process.env.ONEXP_BASEURL || 'https://one-xp-dev.americanexpress.com',
		faasUrl: process.env.ONEXP_FAASURL || 'https://functions-dev.americanexpress.com',

		trackItBaseUrl: process.env.ONEXP_TRACKIT_BASEURL || 'https://iwmap-qa.americanexpress.com',
	},

	nextjsUrl: process.env.NEXTJS_URL || 'http://localhost:3000',
	axeOn: Boolean(process.env.AXE_ON) || false,
}
