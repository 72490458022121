import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Svg = styled.svg`
	vertical-align: middle;
	margin-top: -1px;
	${p => p.opacity || (p.opacity === 0 && css`opacity: ${p.opacity};`)}
	${p => p.color && css`color: ${p.color};`}
`

const IconBase = ({ color, size = '1em', width, height, opacity, ...rest }) => (
	<Svg
		{...rest}
		fill="currentColor"
		preserveAspectRatio="xMidYMid meet"
		height={height || size}
		width={width || size}
		color={color}
		opacity={opacity}
	/>
)

IconBase.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	style: PropTypes.object,
	opacity: PropTypes.number,
}

export default IconBase
