import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const icon = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M12 0a4 4 0 011.101.155l.266.086 5.342 1.579a5 5 0 013.286 4.474L22 6.52V24h-2V6.519a3 3 0 00-1.8-2.75l-.175-.07-5.342-1.579a2 2 0 00-2.542 1.14l-.015.044c.335.196.62.48.818.83l.669 1.18a6.005 6.005 0 013.345 4.687H3.042a6.005 6.005 0 013.346-4.687l.668-1.18c.237-.42.599-.743 1.024-.936A4.002 4.002 0 0112 0zM7 18.717c.667.965 1 1.632 1 2a1 1 0 11-2 0c0-.368.333-1.035 1-2zm4 0c.667.965 1 1.632 1 2a1 1 0 11-2 0c0-.368.333-1.035 1-2zm-6-4c.667.965 1 1.632 1 2a1 1 0 11-2 0c0-.368.333-1.035 1-2zm4 0c.667.965 1 1.632 1 2a1 1 0 11-2 0c0-.368.333-1.035 1-2zm4 0c.667.965 1 1.632 1 2a1 1 0 11-2 0c0-.368.333-1.035 1-2zM15 11a1 1 0 010 2H3a1 1 0 010-2h12z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default icon
