import LoungeDetail from '../LoungeDetail'
import Airports from '../Airports'
import LoungeListing from '../LoungeListing'
import Checkout from '../Checkout'
import Booking from '../Booking'
import FindReservation from '../FindReservation'
import About from '../About'
import Partners from '../Partners'
import Terms from '../TermsPage'
import Team from '../About/Team'
import AirportLoungeBenefits from '../AirportLoungeBenefits'
import Homepage from '../Homepage'
// import AirlineLounges from '../AirlineLounges';
import SelectLocations from '../Select/Locations'
// import Brand from '../Brand'
// import Blog from '../Blog'
import GetApp from '../GetApp'
import BookingReview from '../BookingReview'

import routes from './'

const mapping = {
	homepage: Homepage,
	booking: Booking,
	checkout: Checkout,
	'booking-review': BookingReview,
	detail: LoungeDetail,
	airports: Airports,
	'find-reservation': FindReservation,
	listing: LoungeListing,
	// 'brand': Brand,
	'about/team': Team,
	about: About,
	terms: Terms,
	partners: Partners,
	// 'airline-lounges': AirlineLounges,
	'airport-lounge-benefits': AirportLoungeBenefits,
	'select/locations': SelectLocations,
	mobile: GetApp,
	// 'blog': Blog,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
module.exports = routes.map(({component, key, ...rest}) => ({component: mapping[key], key, ...rest}))
