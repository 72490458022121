import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {media} from '../component/Global/Breakpoints'
import {MinorText} from '../component/Global/Typography'
import {color} from '../component/Global/Colors'
import LBLink from '../component/LBLink'
import {Container} from '../component/Layout'
import PropTypes from 'prop-types'
import {openChat} from '../utils'

const Wrap = styled.div`
	background: ${color.white};
	width: 100%;

	${media.tablet`
		box-shadow: inset 0 2px 0 0 ${color.lightGreyLighter};
	`}
`
const BgWrap = styled.div`
	padding: 40px 0;

	${media.tablet`
		padding: 24px 0 64px;
	`}
`
const Separator = styled.hr`
	margin: 16px 0;
	border-top: 1px solid ${color.lightGreyLighter};
`

const Footer = ({hideChat}) => {
	const {t} = useTranslation('checkout')
	return (
		<Wrap className="lounge-detail--container">
			<BgWrap>
				<Container>
					<MinorText color={color.espressoMedium}>
						{t('encryptionInformation')}
						<br />
						{!hideChat && (
							<span>
								{t('contactUs') + ' '}
								<a
									href="#"
									className="link-support"
									style={{cursor: 'pointer'}}
									onClick={() => {
										openChat()
									}}>
									{t('chatNow')}
								</a>
							</span>
						)}
					</MinorText>
					<Separator />
					<MinorText color={color.espressoMedium}>
						{t('copyright', {year: new Date().getFullYear()})}
						<br />
						<LBLink to="/privacy-policy">{t('privacy')}</LBLink> | <LBLink to="/terms-of-use">{t('terms')}</LBLink>
					</MinorText>
				</Container>
			</BgWrap>
		</Wrap>
	)
}

Footer.propTypes = {
	hideChat: PropTypes.bool,
}

export default memo(Footer)
