import React from 'react'
import IconBase from '../../IconBase'
import {iconIdPrefix, _} from '../../../../../util/universal'

// Converted by: https://svgr.now.sh/ + edit

const ChildrenArea = props => {
	const id = _.uniqueId(iconIdPrefix)
	return (
		<IconBase viewBox="0 0 24 24" {...props}>
			<defs>
				<path
					d="M10.963 3a1 1 0 010 2h-1v4h1V8a1 1 0 011-1h1a1 1 0 011 1v1h6V6.732a2 2 0 11.5-3.055 2 2 0 11.5 3.055V9h.806a2 2 0 011.87 1.29c.216.57.324 1.14.324 1.71 0 .57-.108 1.14-.325 1.71a2 2 0 01-.967 1.075A3.5 3.5 0 1117 18L12.352 18c-.215 0-.389-.224-.389-.5s.174-.5.39-.5h4.646a3.491 3.491 0 011.015-2h-3.102c.294.287.536.625.715 1h-3.164a1.5 1.5 0 000 3l3.163.001a3.5 3.5 0 01-6.627-1L4.463 18a.5.5 0 110-1H9a3.491 3.491 0 011.015-2H6.912c.294.287.536.625.715 1H4.463a1.5 1.5 0 000 3l3.163.001a3.5 3.5 0 11-4.978-4.494A1.995 1.995 0 011.963 13V5H.5a.5.5 0 01-.391-.811l.645-.812A1 1 0 011.537 3h9.426zm-3 3h-4v3h4V6zm11-2a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2z"
					id={id + 'a'}
				/>
			</defs>
			<use fill="#191F27" xlinkHref={`#${id + 'a'}`} fillRule="evenodd" />
		</IconBase>
	)
}

export default ChildrenArea
